@import "../../../assets/scss/variable.scss";

.d-flex {
  display: flex;
}

.text-align {
  align-items: center;
}

.content-space-betwen {
  justify-content: space-between;
}

.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-44 {
  margin-top: 44px;

  @media (max-width: 799px) {
    margin-top: 31px;
  }
}

.mt-18 {
  margin-top: 18px;
}

.no-data-wrap {
  .no-data {
    padding: 10px 0px 9px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: $regulerFont;
  }
}

// teamsport-data-header css

.teamsport-data-header {
  padding: 44px 18px 3px;

  @media (max-width: 799px) {
    padding: 22px 12px 6px;
  }

  .bredcrumn-deatils {
    overflow-x: scroll;

    .bredcrumn-wrap {
      margin-bottom: 3px;
      min-width: max-content;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .bredcrumn-deatils::-webkit-scrollbar {
    height: 0px;
  }

  .bredcrumn-deatils::-webkit-scrollbar-thumb {
    display: none;
  }

  h1 {
    font-size: 43.9px;
    line-height: 56px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;

    @media (max-width: 799px) {
      font-size: 31.36px;
      line-height: 40px;
    }
  }
}

// teamsport-data-accordian css

.teamsport-data-accordian {
  .teamsport-section-details {
    box-shadow: 0px 3px 9px 0px #0000000d;
    margin: 18px 0px;
    .MuiAccordion-root {
      margin-bottom: 10px;
      box-shadow: none;

      &::before {
        display: none;
      }

      .MuiAccordionSummary-root {
        padding: 0px 33px;
        background: #e7e9ec;
        min-height: 45px;

        @media (max-width: 799px) {
          padding: 0px 12px;
        }

        .MuiSvgIcon-root {
          fill: $color-Black;
        }
      }

      .MuiAccordionSummary-content {
        margin: 0px;
        column-gap: 11px;

        p {
          font-size: 22.4px;
          font-family: $primaryFont;
          color: $color-Black;
          line-height: 31.36px;
          @media (max-width: 799px) {
            font-size: 16px;
          }
        }
      }

      .MuiIconButton-root {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 9px 33px;
      width: auto;
      display: block;
      box-shadow: 0px 3px 9px 0px #0000000d;
      @media (max-width: 799px) {
        padding: 9px 12px;
      }
      .accordion-teamsport-details {
        .league-list {
          padding-bottom: 4px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid #e7e9ec;
          justify-content: space-between;
          padding: 18px 8px;
          cursor: pointer;
          .league-wrap {
            display: flex;
            align-items: center;
            column-gap: 5px;
            @media (max-width: 479px) {
              flex-direction: column;
              align-items: flex-start;
              row-gap: 5px;
            }
          }
          .league-name {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            color: $color-Black;

            @media (max-width: 799px) {
              font-size: 15px;
              line-height: 18px;
            }
          }
          .league-sub-name {
            color: #989898;
            margin-left: 4px;

            @media (max-width: 799px) {
              font-size: 12px;
              line-height: 12px;
            }
            @media (max-width: 479px) {
              margin-left: 0px;
            }
          }

          .add-to-blackbook {
            color: #9ca5e1;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: underline;
            svg {
              margin-right: 3px;
            }

            &:hover {
              color: #9ca5e1;
              text-decoration: underline;
            }
          }

          .view-Blackbook {
            color: $color-Accent-1;
            text-decoration: underline;
            &:hover {
              color: $color-Accent-1;
              text-decoration: underline;
            }
          }

          .arrow {
            svg {
              path {
                fill: #1a73e8;
              }
            }
          }
        }
      }

      .sub-accordion {
        .MuiAccordion-root {
          margin-bottom: 9px;
          box-shadow: 0px 3px 9px 0px #0000000d;

          &::before {
            display: none;
          }

          .MuiAccordionSummary-root {
            padding: 0px 24px;
            background: #e7e9ec;
            min-height: 45px;

            @media (max-width: 799px) {
              padding: 0px 9px;
            }

            .MuiSvgIcon-root {
              fill: #000000;
            }
          }

          .MuiAccordionSummary-content {
            margin: 0px;
            column-gap: 11px;

            p {
              font-size: 16px;
              font-family: $regulerFont !important;
              color: #000000;
              line-height: 19px;
              font-weight: 400;
            }
          }

          .MuiIconButton-root {
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }

        .MuiAccordionDetails-root {
          padding: 9px 24px 9px;
          width: auto;
          display: block;
          background: #e7e9ec;

          @media (max-width: 1160px) {
            padding: 9px;
          }

          .accordion-teamsport-details {
            padding: 0px;
          }
        }
      }
    }

    .sub-accordion-details {
      padding: 9px 9px 0px;
    }
  }
}

// tab css

.teamsport-data-tab {
  padding: 19px 18px 9px 18px;
  box-shadow: 0px 3px 9px 0px #0000000d;
  background: $color-White;
  margin-bottom: 18px;

  @media (max-width: 799px) {
    padding: 9px 12px 9px;
  }

  .teamsport-data-tab-details {
    min-height: 38px;
    border-bottom: 2px solid #4455c7;

    @media (max-width: 799px) {
      min-height: 32px;
    }

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .active {
      .MuiTab-wrapper {
        color: #4455c7;
      }
    }

    .MuiTabs-indicator {
      background: #003764;
    }

    .MuiTab-textColorInherit:first-child {
      margin-left: 0px;
    }

    .MuiTab-root {
      min-width: initial;
      width: 110px;
    }

    .MuiTab-wrapper {
      font-size: 16px;
      line-height: 20px;
      color: $color-Black;
      font-family: $primaryFont;
    }

    .Mui-disabled {
      opacity: 1;
    }

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        .MuiBox-root:last-child {
          button {
            margin: 0px;
          }
        }
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

// tab-details css

.tab-deatils {
  .tab-content {
    margin-top: 18px;
    margin-bottom: 37px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: $regulerFont;
    color: #8d8d8d;
    letter-spacing: 0px;
  }

  .tab-details-grid {
    display: flex;
    flex-wrap: wrap;
    column-gap: 45px;
    row-gap: 15px;
    margin-bottom: 9px;

    @media (max-width: 799px) {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 18px;
      margin-bottom: 15px;
    }

    .details-box {
      max-width: fit-content;
      width: 100%;

      @media (max-width: 799px) {
        max-width: fit-content;
      }
    }
  }

  .quick-link-wrap {
    span {
      color: #1a73e8;
      cursor: pointer;
    }
  }

  .details-text {
    font-size: 13px;
    line-height: 16px;
    font-family: $regulerFont;
    color: $color-Black;
    font-weight: 400;

    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .bold {
    font-weight: 600;
  }
}

// short name box css

.short-name-details {
  padding: 34px 64px 0px 33px;

  @media (max-width: 799px) {
    padding: 12px 12px 0px 12px;
  }

  .short-name {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    font-family: $regulerFont;
    color: $color-Black;
    letter-spacing: 0px;
    padding-bottom: 11px;
    border-bottom: 1px solid #e5e6e7;

    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 16px;
    }

    span {
      font-weight: 400;
    }
  }
}

// teamsport table and header
.teamsport-header-details {
  padding: 15px 64px 24px 28px;

  @media (max-width: 799px) {
    padding: 18px 12px 16px;
  }

  .heading-title {
    font-size: 22.4px;
    line-height: 28px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;
    border-bottom: 1px solid #4455c7;
  }
}

// table css

.table-overlay {
  position: relative;
}

.teamsport-data-table {
  .overlay {
    width: 60px;
    height: 100%;
    position: absolute;
    z-index: 99;
    right: 0px;
    background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 60%) 0%
      0% no-repeat padding-box;
  }

  .data-table {
    .table-sub-header {
      .table-sub-head-row {
        .MuiTableCell-head {
          padding: 0px 0px 12px;
          text-align: center;
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
        }

        .sticky-head {
          box-shadow: none;
        }
      }
    }

    .table-header {
      .table-head-row {
        .MuiTableCell-head {
          padding: 16px 11.5px 9px;
          text-align: center;
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Primary;
          border-bottom: 1px solid #d4d6d8;
        }

        .table-head-first {
          font-size: 16px;
          line-height: 19px;
          color: $color-Black;
          text-align: left;
          padding-left: 32px;

          @media (max-width: 799px) {
            font-size: 15px;
            line-height: 18px;
            padding-left: 12px;
          }
        }
      }
    }

    .table-body {
      .table-body-row {
        .MuiTableCell-body {
          padding: 15px 11.5px;
          text-align: center;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-bottom: 1px solid #d4d6d8;
        }

        .table-body-first {
          font-size: 16px;
          line-height: 19px;
          color: #1a73e8;
          text-align: left;
          padding-left: 32px;

          @media (max-width: 799px) {
            font-size: 15px;
            line-height: 18px;
            padding-left: 12px;
          }
        }
      }
    }

    .percentage-data {
      width: 45px;
      height: 15px;
      border: 0.3px solid $color-Black;
      position: relative;

      .line {
        width: 1px;
        height: 15px;
        position: absolute;
        background: #d4d6d8;
        left: 70%;
      }

      .att-data {
        background-color: #1a73e8;
        width: 30px;
        height: 15px;
      }

      .def-data {
        background-color: #e22c32;
        width: 35px;
        height: 15px;
      }
    }

    .border-right {
      border-right: 1px solid #d4d6d8;
    }
  }

  .no-data-td {
    text-align: center !important;
  }
}

.view-more {
  padding: 9px 20px;
}
