@import "../../../assets/scss/variable.scss";

.soc-signup-wrap {

    .odds-comp-img-wrap {
        max-width: 1060px;
        margin: 0px auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .odds-comp-signup-details-wrap {

        .smart-odds-comparison {
            padding: 0px 45px;

            @media (max-width:799px) {
                padding: 0px;
            }

            .fs-37 {
                font-size: 37px;
                font-family: $peckhamPressTrial !important;
                font-weight: 400;
                letter-spacing: 0px;

                @media (max-width:799px) {
                    font-size: 26px;
                }
            }

            .smart-text {
                line-height: 33px;
                color: $color-Secondary;

                @media (max-width:799px) {
                    line-height: 26px;
                }
            }

            .odds-comp-text {
                line-height: 49px;
                color: $color-Primary;

                @media (max-width:799px) {
                    line-height: 26px;
                }
            }

            .master-the-odds-wrap {
                max-width: 551px;
                width: 100%;
                margin-top: 10px;

                .master-the-odds-text {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    letter-spacing: 0px;
                    color: $color-Primary;
                    text-align: center;
                    padding: 7px 0px 6px;

                    @media (max-width:799px) {
                        font-size: 12px;
                        line-height: 15px;
                        margin-top: 5px;
                    }
                }
            }

            .odds-text-details {
                font-size: 16px;
                line-height: 19px;
                font-family: $regulerFont;
                font-weight: 400;
                letter-spacing: 0px;
                color: $color-Black;
                margin-top: 18px;

                @media (max-width:799px) {
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 12px;
                }
            }
        }

        .subscribe-odds-btn {
            max-width: 587px;
            width: 100%;
            justify-content: flex-start;
            padding: 12px 24px 11px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0px;
            font-family: $peckhamPressTrial !important;
            color: $color-White;
            border-radius: 8px;
            background: rgb(0, 53, 97);
            background: linear-gradient(180deg, rgba(0, 53, 97, 1) 0%, rgba(2, 43, 76, 1) 25%, rgba(5, 30, 50, 1) 50%, rgba(8, 18, 27, 1) 75%, rgba(9, 11, 13, 1) 100%);
            text-transform: capitalize;
            margin-top: 28px;

            @media (max-width:799px) {
                font-size: 10px;
                line-height: 14px;
                margin-top: 15px;
                width: auto;
            }

            .MuiButton-label {
                font-family: $peckhamPressTrial !important;
            }

            .MuiTouchRipple-root {
                display: none;
            }
        }

        .free-trail-now-wrap {
            text-align: center;
            margin-top: 46px;

            @media (max-width:799px) {
                margin-top: 19px;
            }
        }

        .free-trail-now {
            border: 1px solid #D6D9F3;
            max-width: 692px;
            margin: 0px auto;
        }

        .odds-plan-details-wrap {
            margin-top: 45px;
            background: rgb(0, 53, 97);
            background: linear-gradient(180deg, rgba(0, 53, 97, 1) 0%, rgba(2, 43, 76, 1) 25%, rgba(5, 30, 50, 1) 50%, rgba(8, 18, 27, 1) 75%, rgba(9, 11, 13, 1) 100%);
            padding: 45px;

            @media (max-width:799px) {
                padding: 12px;
                margin: 17px -12px 0px;
            }

            .plan-section {
                display: grid;
                grid-template-columns: auto auto;
                column-gap: 43px;
                row-gap: 31px;

                @media (max-width:799px) {
                    grid-template-columns: auto;
                    row-gap: 13px;
                }

                .plan-grid {
                    display: flex;
                    column-gap: 12px;
                    align-items: center;

                    @media (max-width:799px) {
                        column-gap: 8.5px;
                    }

                    .grid-icon {
                        width: 35px;
                        height: 35px;
                    }

                    .plan-name {

                        .plan-text {
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 400;
                            font-family: $regulerFont;
                            letter-spacing: 0px;
                            color: $color-White;

                            @media (max-width:799px) {
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}