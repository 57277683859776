@import "../../../../assets/scss/variable.scss";

.my-subscription-wrap {

    .my-subscription-header {
        padding: 44px 18px 0px;

        @media (max-width: 799px) {
            padding: 22px 12px 6px;
        }

        .bredcrumn-details {
            overflow-x: scroll;

            .bredcrumn-wrap {
                margin-bottom: 3px;
                min-width: max-content;

                li.MuiBreadcrumbs-separator {
                    color: $color-Black;
                }

                li {
                    font-size: 11.42px;
                    line-height: 14px;

                    p {
                        font-size: 11.42px;
                        line-height: 14px;
                        color: $color-Accent-1;
                        text-transform: uppercase;
                    }

                    a {
                        color: $color-Black;
                        font-size: 11.42px;
                        line-height: 14px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .bredcrumn-details::-webkit-scrollbar {
            height: 0px;
        }

        .bredcrumn-details::-webkit-scrollbar-thumb {
            display: none;
        }

        h1 {
            font-size: 43.9px;
            line-height: 56px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-Black;

            @media (max-width: 799px) {
                font-size: 31.36px;
                line-height: 40px;
            }
        }
    }

    .my-subscription-details {
        padding: 18px 18px 33px;
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000001c;

        .my-subscription-plan {
            border: 1px solid #E7E9EC;
            border-radius: 8px;
            overflow: hidden;

            .my-plan {
                padding: 13px 18px;
                background-color: #E8EAEC;


                p {
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    color: $color-Primary;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                }
            }

            .plan-details-wrap {
                padding: 16px 18px 0px;

                @media (max-width:599px) {
                    padding: 10px 12px 0px;
                }

                .plan-border-bottom {
                    border-bottom: 1px solid #E7E9EC;
                }

                .plan-details {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    align-items: baseline;
                    padding-bottom: 12px;

                    .plan-type {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 600;
                        font-family: $regulerFont;
                        color: $color-Black;
                        letter-spacing: 0px;

                        @media (max-width:599px) {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    .plan-information {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        color: $color-Black;
                        letter-spacing: 0px;
                        display: flex;
                        align-items: center;

                        @media (max-width:599px) {
                            font-size: 14px;
                            line-height: 16px;
                            align-items: flex-start;
                        }

                        .plan-name {
                            padding: 3px;
                            background-color: $color-Accent-1;
                            color: $color-White;
                            border-radius: 3px;
                            font-size: 12px;
                            line-height: 15px;
                            font-family: $regulerFont;
                            letter-spacing: 0px;
                            font-weight: 600;
                        }

                        .card-img {
                            width: 30px;
                            height: 20px;
                            display: block;
                            margin-right: 12px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .card-name {
                            text-transform: lowercase;
                        }
                    }

                    .mobile-plan-information {
                        @media (max-width:599px) {
                            flex-direction: column;
                            align-items: flex-end;
                            row-gap: 6px;
                        }
                    }

                    .dots {
                        width: 9px;
                        height: 9px;
                        display: block;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    .plan-active {
                        color: $color-Affirmative;
                        font-weight: 600;

                        .dots {
                            background-color: $color-Affirmative;
                        }
                    }

                    .plan-deactivate {
                        color: $color-Negative;
                        font-weight: 600;

                        .dots {
                            background-color: $color-Negative;
                        }
                    }

                    .edit-payment {
                        font-size: 16px;
                        line-height: 19px;
                        font-family: $regulerFont;
                        font-weight: 400;
                        color: $color-Accent-1;
                        letter-spacing: 0px;
                        text-decoration: underline;
                        cursor: pointer;
                        margin-top: 13px;
                        margin-left: 42px;

                        @media (max-width:599px) {
                            font-size: 14px;
                            line-height: 16px;
                            margin-top: 8px;
                            margin-left: 36px;
                        }
                    }
                }


            }
        }

        .plan-update-btn {
            padding: 34.1px 0px 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .update-plan-btn {
                padding: 13px 24px 12px;
                background-color: $color-Accent-1;
                color: $color-White;
                border-radius: 8px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                letter-spacing: 0px;
                text-transform: capitalize;
                margin-bottom: 12.9px;

                .MuiTouchRipple-root {
                    display: none;
                }

                @media (max-width:599px) {
                    margin-bottom: 10px;
                }
            }

            .cancel-subscription {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                letter-spacing: 0px;
                color: $color-Accent-1;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.my-subscription-modal {

    .delete-subscription-dialogbox {

        .delete-subscription-title {
            font-size: 22.4px;
            line-height: 27px;
            font-weight: 500;
            letter-spacing: 0px;
            font-family: $regulerFont;
            color: $color-Black;
            margin-bottom: 33px;

            @media (max-width:799px) {
                font-size: 16px;
                line-height: 22.4px;
                margin-bottom: 19px;
            }
        }

        .delete-subscription-btn {

            .btn-cancel {
                width: 100%;
                padding: 13px 0px 12px;
                background-color: $color-Accent-1;
                color: $color-White;
                border-radius: 8px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                margin-bottom: 18px;
                text-transform: capitalize;

                @media (max-width:799px) {
                    font-size: 15px;
                    line-height: 18px;
                    margin-bottom: 15px;
                }
            }

            .btn-not-now {
                width: 100%;
                padding: 13px 0px 12px;
                border: 1px solid $color-Accent-1;
                border-radius: 8px;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                text-transform: capitalize;
                color: $color-Accent-1;

                @media (max-width:799px) {
                    font-size: 15px;
                    line-height: 18px;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}