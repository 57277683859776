@import "../../../assets/scss/variable.scss";

.header-bar {
  background-color: $color-Primary;

  @media screen and (max-width: 599px) {
    // display: none;
  }
}

.top-slider-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiChip-root.sqare-chip {
  border-radius: 3px;
  background: #4455c7;
  color: #fff;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.sqare-chip.bg-orange {
  background: #fc4714;
}

// .rec-slider div {
//   outline: none;
// }

.top-slider-wrap {
  .rec-slider-container {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      background: #fff;
      top: 0;
      bottom: 0;
      width: 1px;
    }
  }

  .rec.rec-slider {
    min-height: auto;

    .MuiTypography-h4 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 1279px) and (min-width: 960px) {
        // flex-direction: column;
      }
    }
  }

  .rec-item-wrapper {
    padding-left: 15px !important;
    padding-right: 15px !important;
    position: relative;
    width: auto !important;

    &:focus {
      outline: none;
    }

    &:after {
      content: "";
      position: absolute;
      right: 0;
      background: #fff;
      top: 0;
      bottom: 0;
      width: 1px;
    }
  }

  svg {
    font-size: 13px;
  }

  .slide-title {
    padding-left: 5px;
    padding-right: 5px;
    // width: 88px;
    // text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    text-align: left;
    overflow: hidden;
  }

  button {
    min-width: auto;
    min-height: 30px;

    @media screen and (max-width: 767px) {
      min-height: auto;
    }
  }
}

.top-bar-mobile .search-btn {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;

  .MuiListItemIcon-root {
    color: #fff;
    margin-left: 10px;
  }
}

.top-bar {
  min-height: 130px;
  color: #fff;
  // position: absolute;
  margin-top: 0px;
  width: 100%;

  // V3 sticky header
  // max-width: 1350px;
  // z-index: 33333;
  z-index: 1200;
  max-width: 1920px;
  position: sticky;
  top: 0;
  @media (max-width: 799px) {
    min-height: 100px;
  }

  .MuiListItem-root {
    padding: 0 10px;
    width: auto;

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: 1280px) {
      padding: 0 6px;
    }
  }

  & > .MuiTypography-root,
  .MuiListItem-root,
  a {
    font-size: 10px;
    line-height: 20px;
    font-weight: 500;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  .MuiListItemIcon-root {
    color: #fff;
    font-size: 8px;
    width: auto;
    min-width: auto;
    margin: 0 4px 0 5px;
  }
}

@media (max-width: 1479px) {
  .loggedin .top-bar-links {
    margin-left: 30px;
  }
}

@media (max-width: 1279px) {
  .loggedin .top-bar-links {
    margin-left: 15px;
  }
}

@media (max-width: 959px) {
  .loggedin .top-bar .top-bar-links {
    margin-left: 10px;
  }
}

// @media (max-width: 800px) {
//   .top-bar {
//     position: inherit;
//   }
// }
@media (max-width: 799px) {
  .header-mobile {
    min-height: 46px;
  }
}

@media (max-width: 599px) {
  .top-bar .top-bar-links {
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      font-size: 13px;
    }
  }

  .loggedin .top-bar .top-bar-links {
    margin-left: 10px;
  }

  .right-menu {
    .MuiList-padding {
      padding-top: 0;
    }
  }

  .top-slider-wrap .rec-item-wrapper {
    border-right: 0;
  }

  .search-btn {
    font-size: 18px !important;

    svg {
      height: 15px !important;
      width: 30px !important;
    }
  }

  .MuiGrid-container.header-mobile {
    // padding-top: 10px;
    // padding-bottom: 10px;
    min-height: 46px;
  }
}

@media (max-width: 479px) {
  .loggedin .top-bar .top-bar-links {
    margin-left: 25px;
  }

  .search-btn {
    font-size: 12px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.height {
  height: 30px;
}

.NextoJump-wrap {
  display: flex;
  height: 130px;

  // @media (max-width: 799px) {
  //   height: 100px;
  // }

  .nexttoJump-Item-v2 {
    -moz-user-select: none;
    -webkit-user-select: none;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    user-select: none;
    flex-direction: column;
    padding: 0px 30px;
    border-right: 1px solid #fff;

    @media (max-width: 799px) {
      padding: 0px 14px;
    }

    .NextoJump-Title {
      font-size: 12px;
      font-weight: 600;
      padding: 0px 7px 0px 11.5px !important;
      position: relative;
    }

    // .NextoJump-Title::after {
    //     display: block;
    //     content: "";
    //     background: #fff;
    //     height: 25px;
    //     width: 1px;
    //     position: absolute;
    //     right: -1px;
    // }

    &.NextoJump-RaceSlider {
      width: auto;
      display: block;
      flex: 1;
      // height: 25px;
    }

    &.NextoJump-Nav {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      border: none;

      button {
        background: transparent;
        border: none;
        height: 100%;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        // V3 header
        // padding: 2px 10px;
        padding: 2px 5px;
        border-right: 1px solid #fff;
        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: #003764;
        }
      }

      // @media screen and (max-width: 599px) {
      //   border-left: 1px solid rgba(255, 255, 255, 0.3) !important;
      // }
    }

    &:last-child {
      padding-right: 0;
    }

    // &.right-menu,
    // &:first-child {
    //     padding-left: 0;
    //     border-left: none;
    // }

    .dashboard-btn {
      background-color: #ffa400;
      color: #fff;
      font-size: 11px;
      padding: 5px;
      border-radius: 5px;
      text-transform: none;
    }
  }

  .innerTitle {
    display: flex;
    position: relative;
    padding: 9px;
  }
}

.NextoJump-RaceSlider {
  .rec-slider > div > .rec {
    // border-right: 1px solid #fff;
    // margin-left: -1px;

    @media screen and (max-width: 479px) {
      // border-right: 0;
    }
  }

  .rec-carousel-item {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: $color-White;
      width: 1px;
      height: 100%;
    }
  }

  .slide-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 11px 15px;
    border: 0;
  }

  button {
    .MuiButton-label {
      text-transform: none;
      justify-content: flex-start;
      display: block;

      .slider-list {
        .list-header {
          display: flex;
          align-items: center;

          .sport-name {
            font-size: 12px;
            line-height: 15px;
            color: #fff;
            margin-left: 4px;
            font-weight: 600;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-align: left;
          }
        }

        .event-date-wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          .date-time {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            color: $color-White;
            font-family: $regulerFont;
          }
        }

        .live-wrap {
          .Live {
            padding: 1px 4px;
            background-color: #d84727;
            border-radius: 3px;
            text-transform: uppercase;
            color: $color-White;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 600;
            font-family: $regulerFont;
          }
        }

        .event-countDown {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 26px;

          @media (max-width: 799px) {
            margin-top: 17px;
          }

          .race-result {
            background-color: #d6d9f3;
            color: #191919;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            padding: 3px 7px;
            border-radius: 3px;
          }

          .slide-title {
            align-items: center;
            display: flex;
            flex: 1 1 auto;
            width: 6.375rem;
            font-size: 12px;
            line-height: 15px;
            color: #fff;
            font-weight: 500;

            span {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            @media (max-width: 599px) {
              width: 2.375rem;
            }
          }
        }

        .d-flex {
          display: flex;
        }

        .text-align {
          align-items: center;
        }

        .w-80 {
          width: 84px;
        }

        .content-space-betwen {
          justify-content: space-between;
        }

        .sport-event {
          margin-top: 9px;

          .team-deatils {
            margin-bottom: 9px;
          }

          .light {
            opacity: 0.8;
          }

          .team-logo {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            object-fit: contain;
            min-width: 25px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .team-name {
            margin-left: 5.4px;
            font-size: 11.42px;
            line-height: 14px;
            color: $color-White;
            font-weight: 500;
            text-transform: uppercase;
            font-family: $regulerFont;
            text-align: left;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .team-time-result {
            display: flex;
            align-items: center;
            column-gap: 4px;

            .result-details {
              font-size: 11.42px;
              line-height: 14px;
              color: $color-White;
              font-weight: 500;
              font-family: $regulerFont;
              width: max-content;

              .teamscore-value {
                padding: 0px 2.5px;
                word-break: break-all;
              }

              .noscore {
                padding: 0px 10px;
              }
            }

            .win-icon {
              width: 7px;
              height: 11px;
              display: flex;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .MuiChip-label {
      font-size: 12px;
      padding: 3px;
    }
  }
}

.loggedin {
  .NextoJump-wrap {
    padding-left: 55px;

    @media (max-width: 1279px) {
      padding-left: 15px;
    }
  }
}

@media (max-width: 479px) {
  .NextoJump-RaceSlider .MuiButton-root {
    min-width: auto;
  }
}

.NextoJump-Searchmain {
  display: inline-flex;

  input {
    width: 60%;
  }
}

.NextoJump-Search {
  justify-content: flex-start !important;
  width: 10% !important;
}

.NextoJump-Search .MuiSvgIcon-root {
  font-size: 16px;
}

.NexttoJump-Searchinput {
  background-color: inherit;
  color: #fff;
  border: none;
  width: 40%;
  font-size: 16px;

  &::placeholder {
    color: #fff;
  }

  @media (max-width: 1279px) {
    width: 55%;
  }

  @media (max-width: 1023px) {
    width: 60%;
  }

  @media (max-width: 959px) {
    width: 70%;
  }

  @media (max-width: 799px) {
    font-size: 20px;
    font-weight: 600;
  }

  @media (max-width: 374px) {
    font-size: 14px;
    font-weight: 500;
  }
}

.nextoJump-race-list {
  border-bottom: 1px solid #fff;
}

.select-clear-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px 15px;

  .nextoJump-All-label {
    font-size: 11.42px;
    line-height: 14px;
    font-family: $regulerFont;
    color: #0095ff;
    border-bottom: 1px solid #0095ff;
  }
}
.nexttojump-live-wrap {
  display: flex;
  border-bottom: 1px solid $color-White;
}

.nextoJump-list-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NextoJump-Filteritem {
  display: inline-block;
  position: relative;
  padding: 0px;
  // border-right: 1px solid #fff;
  cursor: pointer;
  margin-top: 15px;
  max-width: 323px;
  background-color: $color-Light-grey;
  border-radius: 9px;

  p {
    font-size: 12px;
    font-weight: 600;
    color: $color-Black;
    margin-block-start: 0;
    margin-block-end: 0;
    display: flex;
    align-items: center;

    svg {
      margin-left: 38px;
    }
  }

  ul {
    list-style-type: none;
    padding: 5px 0px;
    display: inline-block;
    vertical-align: top;
    margin: 0px;
    width: 42%;
    // float: left;
  }

  .ul-sport {
    width: 100%;

    .sport-label {
      display: flex;
      align-items: center;

      .label-name {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  }

  li {
    text-align: initial;
    font-size: 12px;
    font-weight: normal;

    .MuiFormControl-root {
      label {
        cursor: pointer;
      }
    }
  }
}

.NextoJump-Filteritemlist {
  position: absolute;
  background-color: #003764;
  z-index: 99;
  min-width: 183px;
  top: 45px;
  left: 0;
  border: 1px solid;
  border-radius: 5px;
  padding: 16px 0px 0px;

  .nextoJump-filter-Name {
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 500;
    color: $color-White;
    font-family: $regulerFont;
    padding: 0px 12px;
    margin-top: 10px;
  }
  .live-name {
    padding: 0px;
    margin-top: 0px;
  }

  .sport-name {
    margin-top: 10px;
  }
}

.NextoJump-Filteritemlist-ul {
  // border-right: 1px solid #fff;
  padding-right: 15px !important;

  span {
    vertical-align: middle;
    display: inline-flex;
  }
}

.NextoJump-Filteritem .MuiCheckbox-colorPrimary.Mui-checked {
  color: #00833e;
}

.NextoJump-Filteritemlist .MuiCheckbox-root {
  color: transparent;
}

.NextoJump-Filteritemlist .MuiButtonBase-root {
  height: 16px;
  width: 16px;
  vertical-align: sub;
}

.NextoJump-Filteritemlist .MuiIconButton-colorSecondary:hover {
  background: none;
}

.NextoJump-Filteritemlist .MuiCheckbox-colorSecondary.Mui-checked:hover {
  background: none;
}

.NextoJump-Filteritemlist .MuiCheckbox-colorSecondary.Mui-checked {
  color: transparent;
}

.NextoJump-Filteritemlist .MuiCheckbox-colorSecondary circle {
  fill: #003764;
}

.NextoJump-Filteritemlist .MuiCheckbox-colorSecondary.Mui-checked circle {
  fill: #0095ff;
}

.NextoJump-RaceSlider .rec-slider-container {
  margin: 0;
}

.NextoJump-RaceSlider {
  padding: 0 !important;
}

@media (max-width: 799px) {
  .main-menu-wrap .mobileMenu-button {
    right: 0;
    left: auto;
    top: -6px;
  }
}

.NextoJump-RaceSlider .topbar-slider {
  padding: 9px 9px;
  height: 130px;
  align-items: baseline;

  svg {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}

.NextoJump-Item .account-btn span {
  background: #ff6b00;
  padding: 4px 11px;
  border-radius: 5px;
  font-size: 10px;
  line-height: 20px;
  font-weight: 500;
}

@media (min-width: 640px) and (max-width: 767px) {
  .NextoJump-Search .NexttoJump-Searchinput {
    margin-left: -6px;
    width: 100%;
  }

  .NextoJump-Search .MuiSvgIcon-root {
    margin-right: -6px;
  }
}

.BetsSidebarPopup-wrap {
  display: none;

  @media (max-width: 1439px) and (min-width: 800px) {
    display: block;
  }
}

.no-match-available {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;

  .no-data {
    @media (max-width: 799px) {
      font-size: 14px;
      line-height: 18px;
      padding: 3px;
    }
  }
}

.topbar-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  //   width: 40px;
  width: 100%;

  .MuiCircularProgress-svg {
    color: $color-White;
  }
}

// @media (-webkit-device-pixel-ratio: 1.25) {
//   * {
//     zoom: 0.8;
//   }
// }
