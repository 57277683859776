@import "src/assets/scss/variable.scss";
.auth-close-wrap {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.auth-sec {
  display: flex;
  position: relative;
  max-height: 865px;
  height: 100%;
  min-width: 1247px;
  max-width: 1247px;
  border: 3px solid #e26623;
  border-radius: 16px;
  // box-sizing: border-box;

  .auth-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
  }

  .textfield-sec {
    text-align: left;
    padding-top: 18px;

    .text-field {
      width: 100%;

      .MuiInputBase-input {
        padding: 10px 10px;
        max-width: 514px;
        width: 100%;
        min-height: 25px;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
      }

      .MuiInputLabel-outlined {
        transform: translate(14px, 14px) scale(1);
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
        // color: $color-Accent-1;
      }
      .MuiInputBase-root {
        background-color: $color-White !important;
        border-radius: 8px;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        display: none;
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #4455c7;
          border-width: 1;
          color: $color-Accent-1;
        }
      }

      .MuiOutlinedInput-adornedEnd {
        justify-content: space-between;
      }
    }

    .email-text-field {
      .MuiInputBase-input {
        max-width: none;
      }
    }

    .textfield-text {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-Black;
      text-align: left;
      font-weight: 600;
    }

    svg {
      cursor: pointer;
    }
  }

  .MuiGrid-spacing-xs-3 {
    margin: 0px -10px;

    .MuiGrid-item {
      padding: 0px 9px !important;
    }
  }

  .left-sec {
    width: 40%;
    border-radius: 13px 0px 0px 13px;

    .image-sec {
      width: 100%;
      height: 100%;
      border-radius: 16px 0px 0px 16px;
    }
  }

  .right-sec {
    // width: 65%;
    // padding: 54px 36px;
    width: 60%;
    padding: 45px 60px 27px;
    box-sizing: border-box;
    text-align: center;
    background: $pop-up-background;
    position: relative;
    border-radius: 0px 13px 13px 0px;
    overflow: hidden;
    .dialog-close {
      position: absolute;
      right: 0;
      top: 0;

      &:hover {
        background-color: transparent;
      }
    }

    .login-title {
      font-family: $primaryFont;
      font-size: 30.36px;
      line-height: 40px;
      font-weight: 400;
      color: $color-Primary;
      padding: 20.6px 0px 9px 0px;

      @media (max-width: 599px) {
        font-size: 22.4px;
        line-height: 28px;
        padding: 0px !important;
        padding-bottom: 8px !important;
      }
    }

    .login-heading {
      font-size: 22.4px;
      font-weight: 700;
      padding: 26.9px 0px 0px 0px;
    }

    .otp-content {
      font-family: $regulerFont;
      font-size: 18px;
      line-height: 31.36px;
      font-weight: 500;
      color: $color-Black;
      padding: 26.9px 0px 18px 0px;

      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .forgot-password-link {
      padding-top: 15px;
      text-align: center;
      color: $color-Accent-1;
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 21px;
      text-decoration: underline;
      cursor: pointer;
    }

    .prev-next-btn {
      .submit-btn:first-child {
        background-color: $color-White;
        color: $color-Accent-1;
      }
    }

    .sign-in-account-sec {
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      @media (max-width: 599px) {
        padding-bottom: 15px;
      }

      .account-text {
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
        color: $color-grey-Accent-1;
        text-decoration: none;
        @media (max-width: 599px) {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Black;
        }
      }

      .redirect-link {
        padding-left: 9px;
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: $color-Accent-1;
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 599px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }
    }
    .resend-otp-msg-wrap {
      padding-top: 15px;
      @media (max-width: 599px) {
        padding-top: 9px;
      }
    }

    .submit-btn {
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      border: 1px solid $color-Accent-1;
      border-radius: 8px;
      letter-spacing: 0px;
      background: $color-Accent-1;
      color: $color-White;
      width: 100%;
      margin-top: 27px;
      padding: 15px 0;
      text-transform: none;
      @media (max-width: 599px) {
        padding: 11px 0;
      }
    }

    .stepper-label-wrap {
      background: transparent;

      // svg {
      //   color: $color-Accent-1;
      // }
      .MuiStepIcon-completed {
        color: $color-Accent-1;
      }

      .MuiStepIcon-active {
        color: $color-Accent-1;
      }

      MuiStepConnector-active {
        .MuiStepConnector-line {
          border-color: $color-Accent-1;
        }
      }
    }

    .stepper-main-wrap {
      padding: 0px;

      .signup-header {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        text-align: left;
        @media (max-width: 599px) {
          font-size: 14px;
          line-height: 17px;
        }
        .text-logo {
          padding: 0px 5px;
        }
      }

      .textfield-text {
        text-align: left;
        line-height: 22.4px;
        color: $color-Black;
        font-size: 16px;
        font-weight: 500;
      }

      .signup-que {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $color-Black;
        margin-top: 30px;
        margin-bottom: 15px;
        text-align: left;
        @media (max-width: 1023px) {
          margin-top: 16px;
          margin-bottom: 9px;
        }
      }

      .step1-container {
        .signup-header {
          text-align: left;
        }
        .MuiInputBase-input {
          max-width: none;
          // width: 100%;
        }

        .MuiInput-underline:before {
          border-bottom: none;
        }

        .MuiOutlinedInput-root {
          border-radius: 8px;
          max-width: 100%;
          width: 100%;
          min-height: 45px;
          background-color: #ffff !important;
        }
        .MuiOutlinedInput-adornedEnd {
          padding-right: 14px;
        }

        .select__control {
          box-shadow: none;
          // border: 1px solid red;
          padding: 0px;
          margin: 0px;
        }

        .select__control,
        .react-select__control {
          // &.select__control--is-disabled {
          //   // border-color: $border-color;
          //   // .select__indicator-separator {
          //   //   //   background-color: $border-color;
          //   // }
          // }

          &.select__control--is-focused,
          &.react-select__control--is-focused {
            border-color: $color-Accent-1;
          }

          &.select__control--menu-is-open {
            box-shadow: none;
          }

          .select__indicator svg {
            cursor: pointer;
          }

          .select__indicator-separator {
            display: none;
          }

          .select__single-value {
            border: none;
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            font-family: $regulerFont;
            outline: none;
            cursor: pointer;
            margin: 0px;
          }

          .select__value-container {
            border: none;
            padding: 0px;
            margin: 0px;

            .select__input-container {
              padding-left: 10px;
              font-size: 16px;
              line-height: 20px;
              font-family: $regulerFont;
            }
          }

          .select__placeholder {
            font-size: 16px;
            line-height: 20px;
            padding-left: 10px;
            cursor: pointer;
            font-family: $regulerFont;
          }
        }

        .select__menu {
          margin: 0px;
          border-radius: 0px;
          padding: 0px;
          z-index: 999;
        }

        .select__menu-list {
          padding: 0px;
        }

        // Select Menu
        .select__menu,
        .react-select__menu {
          .select__menu-list,
          .react-select__menu-list {
            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 16px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 19px;
              padding: 11px;

              &.select__option--is-focused {
                background-color: #d4d6d8;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: #d4d6d8;
                color: $color-Black;
              }
            }
          }
        }

        .select {
          .select__control {
            min-height: 45px;
            border-radius: 8px;
            max-width: 100%;
            width: 100%;
          }
        }

        .select-title {
          max-width: 134px;

          .select__control {
            max-width: 134px;
            width: 100%;
          }
        }
        .mobile-number-wrap {
          display: flex;
          align-items: flex-start;
          column-gap: 2px;
        }
        .phone-code {
          width: 110px;
          // .select__control {
          //   max-width: 105px;
          //   width: 105px;
          // }
          .select__indicator {
            padding: 6px 3px;
          }
          .select__menu {
            width: 307px;
          }
        }
        .mobile-textfield {
          width: calc(100% - 110px);
          min-width: auto;
        }
        .text-error {
          .select__control {
            border: 1px solid red;
          }
        }

        .checkBox-wrap {
          margin-top: 27px;
          text-align: left;
          .documentsRead-check {
            align-items: center;

            .MuiButtonBase-root {
              margin-right: 6px;
              padding: 0px 0px 0px 10px;

              &:hover {
                background-color: transparent;
              }

              .MuiSvgIcon-root {
                path {
                  fill: #d4d6d8;
                }
              }

              .MuiTouchRipple-root {
                display: none;
              }
            }

            .Mui-checked {
              .MuiSvgIcon-root {
                path {
                  fill: $color-Primary;
                }
              }
            }

            .MuiTypography-root {
              font-size: 12px;
              line-height: 17px;
              font-weight: 400;
              font-family: $regulerFont;
              letter-spacing: 0px;

              span {
                color: $color-Accent-1;
                text-decoration: underline;
                cursor: pointer;
              }
              a {
                color: $color-Accent-1;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }

        .MuiInput-underline:after {
          border-bottom: none;
        }

        .MuiFormControl-marginDense {
          margin-top: 0px;
        }

        .step1-btn {
          margin-bottom: 171px;
        }
        .stepper-next {
          margin-bottom: 15px;
        }
        .prev-next-btn {
          .submit-btn:first-child {
            background-color: $color-White;
            color: $color-Accent-1;
          }
        }
        .finish-btn {
          a {
            text-decoration: underline;
            color: $color-Accent-1;
            cursor: pointer;
            @media (max-width: 599px) {
              font-size: 14px;
              line-height: 17px;
              margin-top: 5px;
            }
          }
        }

        .details-search-picker {
          .MuiOutlinedInput-adornedEnd {
            padding-right: 0px;
          }
          .MuiInputBase-input {
            font-size: 16px;
            line-height: 20px;
            font-family: $regulerFont;
          }

          // .MuiFormHelperText-root.Mui-error {
          //   display: none;
          // }
        }

        .hear-about-list {
          .checkbox-list-1 {
            display: grid;
            grid-template-columns: auto auto auto;
            row-gap: 5px;
            margin-top: 9px;

            .MuiTouchRipple-root {
              display: none;
            }
          }
          .other-filed {
            display: grid;
            grid-template-columns: 33.33% 29.33% 36.33%;
          }
        }

        .profile-image-name-wrap {
          display: flex;
          align-items: center;
          column-gap: 9px;
          margin: 30px 0px 28px;
          cursor: pointer;
          @media (max-width: 599px) {
            margin: 15px 0px 21px;
          }
          .profile-image-wrap {
            height: 70px;
            width: 70px;
            border-radius: 50%;
            position: relative;
            .profile-image {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 50%;
            }
            .add-button {
              position: absolute;
              bottom: 0px;
              right: 0px;
            }
          }
          .profile-image-text-wrap {
            margin-top: 28px;
            .image-text {
              font-size: 16px;
              line-height: 20px;
              color: $color-Accent-1;
              font-weight: 600;
              text-decoration: underline;
            }
          }
        }
      }

      .step2-container {
        .prev-next-btn {
          display: flex;
          justify-content: space-between;

          .submit-btn {
            width: 49%;
          }

          .submit-btn:first-child {
            background-color: $color-White;
            color: $color-Accent-1;
          }
        }
      }

      .step3-container {
        .bookmakers-text {
          text-align: left;
          margin-top: 18px;

          .bookmakers-label {
            font-size: 16px;
            font-weight: 400;
            color: $color-Black;
          }

          .bookmakers-radioGroup {
            flex-direction: row;

            label:nth-child() {
              margin: 0px;
              margin-right: 18px;
            }

            .Mui-checked {
              color: #003764;
            }
          }
        }

        .bookmakers-list {
          text-align: left;

          .bookmakers-list-label {
            font-size: 16px;
            font-weight: 400;
            color: $color-Black;
            padding: 18px 0px;
          }

          .checkbox-list {
            display: flex;

            .checkbox-list-1 {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 38px;
              row-gap: 15px;
              height: 250px;
              overflow-y: scroll;
              overflow-x: hidden;
              padding-left: 4px;
              @media (max-width: 799px) {
                grid-template-columns: 50% 50%;
                column-gap: 5px;
                row-gap: 9px;
                padding-left: 0px;
              }
              @media (max-width: 599px) {
                grid-template-columns: auto;
              }
              .bookmakers-check {
                margin: 0px;
                .Mui-checked {
                  color: #003764;
                }

                .bookmakers-checkbox {
                  padding: 6px;
                  .MuiSvgIcon-root {
                    width: 16px;
                    height: 16px;
                  }
                }
                .checkbox-label-wrap {
                  display: flex;
                  align-items: center;
                  column-gap: 6px;
                  .bookeeper-image-wrap {
                    width: 90px;
                    height: 27px;
                    border-radius: 6px;
                    display: block;
                    @media (max-width: 799px) {
                      width: 60px;
                      height: 25px;
                    }
                    .bookeeper-image {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 6px;
                    }
                  }
                  .bookeeper-name {
                    font-size: 16px;
                    line-height: 19px;
                    @media (max-width: 799px) {
                      font-size: 14px;
                      line-height: 17px;
                    }
                  }
                }
              }
            }

            .checkbox-list-1:not(:last-child) {
              margin-right: 144px;
            }
          }

          .other-filed {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 135px;

            .text-field {
              background-color: #ffffff;

              .MuiOutlinedInput-root {
                border-radius: 8px;
              }
            }
          }
        }
        .no-bookmaker {
          height: 300px;
        }

        .PrivateSwitchBase-root-16 {
          padding: 0px;
          padding: 9.5px;
        }

        .signup-btn-wrap {
          position: absolute;
          width: 70%;
          bottom: 50px;

          .prev-next-btn {
            display: flex;
            justify-content: space-between;

            .submit-btn {
              width: 49%;
            }

            .submit-btn:first-child {
              background-color: $color-White;
              color: $color-Accent-1;
            }
          }

          .finish-btn {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }
      }

      .step4-container {
        .sports-wrap {
          // display: flex;

          .checkbox-list-1 {
            text-align: left;
            display: flex;
            column-gap: 9px;
            row-gap: 15px;
            flex-wrap: wrap;
            cursor: pointer;

            .bookmakers-check {
              background-color: #4a65ad;
              color: #97abbf;
              padding: 8px 16px;
              border-radius: 18px;
            }
            .active-label {
              background-color: #1a73e8;
              color: $color-White;
            }

            .text-field {
              input {
                width: 150px;
                padding: 8px;
              }
            }
          }
        }
        .stepper-next {
          margin-top: 130px;
          margin-bottom: 15px;
          @media (max-width: 599px) {
            margin-top: 15px;
            margin-bottom: 9px;
          }
        }

        .finish-btn {
          a {
            text-decoration: underline;
            color: $color-Accent-1;
            cursor: pointer;
            @media (max-width: 599px) {
              font-size: 14px;
              line-height: 17px;
              margin-top: 5px;
            }
          }
        }

        .other-filed {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 135px;
          text-align: left;

          .text-field {
            background-color: #ffffff;

            .MuiOutlinedInput-root {
              border-radius: 8px;
            }
          }
        }

        .signup-btn-wrap {
          position: absolute;
          width: 70%;
          bottom: 10px;

          .prev-next-btn {
            display: flex;
            justify-content: space-between;

            .submit-btn {
              width: 49%;
            }

            .submit-btn:first-child {
              background-color: $color-White;
              color: $color-Accent-1;
            }
          }

          .finish-btn {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }
      }

      .step5-container {
        height: 485px;
        overflow-y: auto;
        padding-left: 2px;
        .checkbox-list {
          display: flex;

          .checkbox-list-1 {
            display: grid;
            grid-template-columns: auto;
            column-gap: 0px;

            .bookmakers-check {
              margin-left: 0px;
              margin-right: 0px;

              .Mui-checked {
                color: #003764;
              }

              .bookmakers-checkbox {
                .MuiSvgIcon-root {
                  width: 16px;
                  height: 16px;
                }
              }

              span {
                text-align: left;
              }
            }
          }
        }

        .other-filed {
          text-align: left;

          .text-field {
            background-color: #ffffff;

            .MuiOutlinedInput-root {
              border-radius: 8px;
            }
          }
        }

        .signup-btn-wrap {
          position: absolute;
          width: 70%;
          bottom: 50px;

          .prev-next-btn {
            display: flex;
            justify-content: space-between;

            .submit-btn {
              width: 49%;
            }

            .submit-btn:first-child {
              background-color: $color-White;
              color: $color-Accent-1;
            }
          }

          .finish-btn {
            font-weight: 600;
            color: $color-Accent-1;
            text-decoration: underline;
          }
        }

        .notification-wrap {
          display: grid;
          grid-template-columns: 33.33% 33.33% 33.33%;
          row-gap: 18px;
          justify-content: space-between;
          @media (max-width: 799px) {
            grid-template-columns: 50% 50%;
          }
          @media (max-width: 599px) {
            grid-template-columns: auto;
            row-gap: 12px;
          }
          .parent-checkbox {
            text-align: left;
            width: 100%;

            .p-25 {
              padding-left: 25px;
            }
            .sport-icon-wrap {
              display: flex;
              align-items: center;
              .sports-name {
                color: $color-Primary;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                @media (max-width: 479px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }
              .sport-icon {
                display: flex;
                padding-left: 3px;
                .storke-svg {
                  path {
                    stroke: $color-Primary;
                  }
                }
              }
            }
            .child-wrap {
              display: flex;
              flex-wrap: wrap;
              column-gap: 9px;
              row-gap: 6px;
            }
            .child-checkbox {
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              background-color: #4a65ad;
              color: #97abbf;
              padding: 8px 16px;
              border-radius: 18px;
              cursor: pointer;
              @media (max-width: 479px) {
                font-size: 11.42px;
                line-height: 14px;
              }
            }
            .active-label {
              background-color: #1a73e8;
              color: $color-White;
            }
          }
        }
      }
      .step5-signup-btn-wrap {
        @media (max-width: 1279px) {
          .prev-next-btn {
            display: flex;
            column-gap: 7px;
          }
        }
      }

      .signup-homepage-customisation {
        .customisation-note-container {
          .note-wrap {
            margin: 9px 0px 10px;
            padding-left: 30px;
            .note-item {
              text-align: left;
              list-style: square;
              margin-bottom: 3px;
              .note-label {
                font-weight: 600;
              }
              .note-des {
                font-weight: 400;
              }
            }
          }
        }
        .signup-layout-listing {
          padding-top: 15px;
          .customise-container {
            padding: 12px 18px;
            @media (max-width: 1023px) {
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              .customise-screen-button-container {
                justify-content: end;
                width: auto;
                .customise-button {
                  width: 100%;
                }
              }
            }
            @media (max-width: 599px) {
              padding: 9px 12px;
            }
          }
        }
      }

      .finish-btn {
        a {
          text-decoration: underline;
          text-transform: none;
          color: $color-Accent-1;
          cursor: pointer;
          @media (max-width: 599px) {
            font-size: 14px;
            line-height: 17px;
            margin-top: 5px;
          }
        }
      }
    }
    // .register-container {
    //   .signup-header {
    //     text-align: left;
    //   }
    //   .mobile-number-wrap {
    //     display: flex;
    //     align-items: baseline;
    //     column-gap: 2px;
    //   }
    //   .phone-code {
    //     .select__control {
    //       max-width: 105px;
    //       width: 105px;
    //     }
    //     .select__menu {
    //       width: 307px;
    //     }
    //   }
    // }
  }

  .finish-msg-wrap {
    padding-top: 180px;
    padding-bottom: 143px;

    .finish-done {
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      padding-top: 18px;
      padding-bottom: 12px;
    }

    .finish-msg {
      font-size: 16px;
      line-height: 22.4px;
    }

    .submit-btn {
      margin-top: 143px;
      cursor: pointer;
    }
  }

  .otp-msg-wrap-v2 {
    padding-bottom: 24px;
    @media (max-width: 599px) {
      padding-bottom: 0px;
    }
    .otp-content {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 500;
      color: $color-Black;
      padding: 30px 0px 60px 0px;
      text-align: left;
      @media (max-width: 799px) {
        padding: 15px 0px 18px 0px;
      }

      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        padding: 15px 0px 18px 0px;
      }
    }
    .submit-btn {
      margin-top: 27px;
      cursor: pointer;
    }
    .forgot-password-link {
      padding-top: 15px;
      cursor: pointer;
      text-decoration: none;
    }
  }
  .complete-profile {
    .otp-content {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 500;
      color: $color-Black;
      padding: 83px 0px 9px 0px;
      text-align: left;
      @media (max-width: 799px) {
        padding: 15px 0px 7px 0px;
      }

      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        // padding: 15px 0px 18px 0px;
      }
    }
    .white-submit-btn {
      background-color: $color-White;
      color: $color-Accent-1;
    }

    .text-semibold {
      @media (max-width: 799px) {
        font-weight: 600;
      }
    }
  }

  @media (max-width: 799px) {
    .right-sec {
      width: 100%;
      padding: 10px 24px;
    }
  }
}

.signup-sec {
  max-height: 873px;
  height: 100%;
  min-width: 1247px;
  max-width: 1247px;
  border: 3px solid #e26623;
  border-radius: 16px;

  .left-sec {
    width: 40%;
  }

  .right-sec {
    width: 60%;
    padding: 45px 60px 27px;
  }
}

.error-msg {
  display: none;
}

.text-danger {
  display: block;
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.ml-4 {
  margin-left: 4px !important;
  margin-top: 6px !important;
}

.text-danger-width {
  max-width: 514px;
}

.MuiPickersToolbar-toolbar {
  background-color: $color-Accent-1 !important;
}

// .signup-loader{
//   position: absolute;
//   left: 50%;
//   top: 50%
// }
@media (max-width: 1279px) {
  .signup-sec {
    display: block;
    min-width: auto;
    max-width: none;

    .left-sec {
      display: none;
    }

    .right-sec {
      padding: 54px 70px;
      margin: 0 auto;
      width: auto !important;
      border-radius: 13px;

      .stepper-main-wrap {
        .step1-container {
          .MuiOutlinedInput-root {
            max-width: none !important;
          }

          .details-search-picker {
            width: 100%;
          }

          .MuiOutlinedInput-adornedEnd {
            justify-content: space-between;
          }

          .select__control {
            max-width: none !important;
          }

          .step1-btn {
            margin-bottom: 4px !important;
          }
        }

        .step3-container {
          .signup-btn-wrap {
            position: initial;
            width: 100%;

            .submit-btn {
              width: 100%;
            }
          }

          .bookmakers-list {
            .checkbox-list {
              display: block;
            }

            .other-filed {
              .text-field {
                margin-left: 70px;
              }
            }
          }
        }

        .step4-container {
          .signup-btn-wrap {
            position: initial;
            width: 100%;

            .submit-btn {
              width: 100%;
            }
          }

          .checkbox-list {
            display: block;
          }
        }

        .step5-container {
          .signup-btn-wrap {
            position: initial;
            width: 100%;

            .submit-btn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .auth-sec {
    min-width: auto;
    max-width: none;
    display: block;

    .left-sec {
      display: none;
    }
    .right-sec {
      width: auto;
      border-radius: 13px;
    }
  }
}

@media (max-width: 1279px) {
  .signup-sec {
    .right-sec {
      padding: 54px 33px;
    }
  }
}

@media (min-width: 599px) {
  .step4-container {
    .sport-field {
      display: none;
    }
  }
}

@media (max-width: 599px) {
  .signup-sec {
    max-height: none;

    .right-sec {
      padding: 9px 12px 54px !important;

      .stepper-main-wrap {
        .textfield-text {
          font-size: 14px;
          line-height: 17px;
          color: #191919;
        }

        .step4-container {
          .checkbox-list {
            .checkbox-list-1 {
              grid-template-columns: auto auto;
            }
          }

          .other-filed {
            column-gap: 175px;

            .text-field {
              margin-right: 20px;
            }
          }
        }

        .prev-next-btn {
          flex-direction: column;

          .submit-btn {
            width: 100% !important;
          }
        }
      }
    }
  }

  .auth-sec {
    .textfield-sec {
      padding-top: 9px;
    }

    .right-sec {
      padding: 39px 12px 61px 12px;

      .submit-btn {
        margin-top: 20px;
      }
    }

    .finish-msg-wrap {
      padding-top: 75px;
      padding-bottom: 0px;

      .finish-msg {
        padding-bottom: 240px;
      }
    }
  }
}

@media (max-width: 479px) {
  .signup-sec {
    .right-sec {
      // padding: 180px 12px 54px !important;
      .MuiStepper-root {
        padding: 15px 0px 22px;
      }
    }
  }

  // .auth-sec {
  //   .right-sec {
  //     // padding: 180px 12px 61px 12px;
  //   }
  // }
}
