@import "../../../assets/scss/variable.scss";

.podcasts-wrap {
    // width: 894px;

    .podcasts-header {
        padding: 33px 18px 3px;

        @media (max-width: 799px) {
            padding: 18px 12px 3px;
        }

        .bredcrumn-wrap {
            margin-bottom: 3px;

            li.MuiBreadcrumbs-separator {
                color: $color-Black;
            }

            li {
                font-size: 11.42px;
                line-height: 14px;

                p {
                    font-size: 11.42px;
                    line-height: 14px;
                    color: $color-Accent-1;
                    text-transform: uppercase;
                }

                a {
                    color: $color-Black;
                    font-size: 11.42px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
            }
        }

        h1 {
            font-size: 43.9px;
            line-height: 56px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-Black;

            @media (max-width: 799px) {
                font-size: 31.36px;
                line-height: 40px;
            }
        }
    }

    .podcasts-details {
        // with search padding
        // padding: 18px 18px 0px;

        // with search padding
        padding: 0px 18px;
        background-color: #ffffff;
        box-shadow: 0px 3px 9px 0px #0000000d;
        margin-bottom: 26px;

        @media (max-width: 799px) {
            padding: 9px 12px 0px;
        }

        .search_input {
            width: 100%;

            .MuiInputAdornment-root {
                cursor: pointer;
            }

            .MuiInputBase-root {
                min-height: 45px;
                border-radius: 4px;

                svg {
                    height: 18px;
                    width: 18px;
                }

                .MuiInputBase-input {
                    padding: 9px 9px;
                }
            }

            @media (max-width: 799px) {
                margin-left: 0px !important;
            }
        }

        .podcasts-tab-details {
            padding: 18px 0px;
            min-height: 38px;

            @media (max-width: 799px) {
                padding: 9px 0px;
                min-height: 32px;
            }

            // .MuiTabScrollButton-root {
            //   // border: 2px solid $color-Primary;
            // }

            .MuiTab-textColorInherit {
                color: $color-Black;
                border: 2px solid $color-Primary;
                margin: 0px 12px;
                border-radius: 8px;
                opacity: 1;
                min-height: 38px;
                padding: 9px 10px;

                @media (max-width: 799px) {
                    margin: 0 9px;
                    padding: 6px 12px;
                    min-height: 32px;
                }
            }

            .active {
                color: $color-White;
                background: $color-Primary;
            }

            .MuiTabs-indicator {
                background: none;
            }

            .MuiTab-textColorInherit:first-child {
                margin-left: 0px;
            }

            .MuiTab-root {
                min-width: initial;
                width: max-content;
            }

            .MuiTab-wrapper {
                font-size: 16px;
                line-height: 20px;
                font-family: $primaryFont;
            }

            .Mui-disabled {
                opacity: 1;
            }

            .MuiTabs-scroller {
                .MuiTabs-flexContainer {
                    .MuiBox-root:last-child {
                        button {
                            margin: 0px;
                        }
                    }
                }
            }

            .MuiTouchRipple-root {
                display: none;
            }
        }
    }

    .podcasts-deatils-wrap {
        padding: 33px 17px;
        box-shadow: 0px 3px 9px 0px #0000000d;
        background-color: #ffffff;
        margin-bottom: 18px;

        @media (max-width:799px) {
            padding: 18px 12px;
        }
    }

    .podcasts-on-text {
        font-size: 31.36px;
        line-height: 42px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media (max-width:799px) {
            font-size: 22.4px;
            line-height: 28px;
        }
    }

    .more-video-section {
        margin-top: 33px;

        .subscribe-text {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            font-family: $regulerFont;
            color: #989898;
        }

        .more-video {
            margin-top: 10px;
            display: grid;
            grid-template-columns: auto auto auto;
            column-gap: 38.8px;
            row-gap: 18px;

            @media (max-width:1360px) {
                column-gap: 20px;
            }

            @media (max-width:1280px) {
                grid-template-columns: auto auto;
            }

            @media (max-width:439px) {
                grid-template-columns: auto;
            }


            img {
                @media (max-width:559px) {
                    width: 80%;
                }

                @media (max-width:439px) {
                    width: 100%;
                }
            }

            .video-text {
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 400;
                font-family: $regulerFont;
            }

            iframe {
                @media (max-width:439px) {
                    height: auto !important;
                }
            }
        }
    }

    .our-podcasts-deatils {
        padding: 33px 17px;
        box-shadow: 0px 3px 9px 0px #0000000d;
        background-color: #ffffff;

        @media (max-width:799px) {
            padding: 18.5px 12px;
        }

        .podcasts-on {


            .podcasts-grid {
                display: grid;
                grid-template-columns: auto auto auto auto auto auto auto;
                column-gap: 30px;
                row-gap: 18px;
                // max-width: 601px;
                margin-top: 21px;
                margin-bottom: 45px;

                img {
                    width: 60px;
                    height: 60px;
                }

                @media (max-width:799px) {
                    column-gap: 12px;
                    row-gap: 9px;
                    // max-width: 310px;
                    margin-bottom: 21px;
                    margin-top: 9px;

                    img {
                        width: 34px;
                        height: 34px;
                    }
                }
            }

            .social-grid {
                // max-width: 420px;
                margin-bottom: 0px;

                // @media (max-width:799px) {
                //     max-width: 198px;
                // }
            }
        }
    }
}

.podcasts-video-modal {
    max-width: 480px;
    width: 100%;

    @media (max-width:539px) {
        max-width: 366px;
    }

    .MuiDialog-paper {
        padding: 0px;
        max-height: 600px;
        height: 100%;

        @media (max-width:539px) {
            max-height: 370px;
        }
    }

    .dialog-content-box {
        .dialog-close {
            padding: 0px;
            z-index: 1;
        }
    }
}

.More-video-modal {
    .MuiDialog-paper {
        padding: 0px;
        background-color: transparent;
        box-shadow: none;
    }

    .dialog-content-box {
        .dialog-close {
            padding: 0px;
            z-index: 1;
        }
    }
}