@import "../../../../../assets/scss/variable.scss";

select.select-races {
  border: none;
  font-size: 22.4px;
  line-height: 31.36px;
  color: $color-Accent-1;
  font-family: $primaryFont;
  outline: none;
  cursor: pointer;

  option {
    font-size: 16px;
    color: $color-Black;
    font-family: $regulerFont;
    line-height: 19px;
    padding: 11px;
  }

  option:hover {
    background: $color-grey !important;
  }
}

.odds-table-head {
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-end;

  .disclaimer {
    font-size: 8pt;
    font-style: italic;
    font-family: $regulerFont;
  }
}
.sign-up-dropdown-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  @media (max-width: 1159px) {
    flex-direction: column-reverse;
    align-items: baseline;
    row-gap: 12px;
  }
}
.dropdown-subscribe-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  @media (max-width: 899px) {
    flex-direction: column;
    align-items: baseline;
    row-gap: 12px;
  }
}
.select-wrap {
  max-width: 180px;
  width: 100%;
  @media (max-width: 599px) {
    max-width: none;
  }
  .series-select {
    margin-top: 0px;
  }

  .select__control {
    box-shadow: none;
    border: none !important;
    padding: 0px;
    margin: 0px;
  }

  .select__control,
  .react-select__control {
    &.select__control--is-focused,
    &.react-select__control--is-focused {
      box-shadow: none;
      border: none;
    }

    &.select__control--menu-is-open {
      box-shadow: none;
      border: none;
    }

    .select__indicator svg {
      cursor: pointer;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__single-value {
      border: none;
      font-size: 22.4px;
      line-height: 31.36px;
      color: $color-Accent-1;
      font-family: $primaryFont;
      outline: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }

    .select__value-container {
      border: none;
      padding: 0px;
      margin: 0px;
    }

    .select__placeholder {
      border: none;
      font-size: 22.4px;
      line-height: 31.36px;
      color: $color-Accent-1;
      font-family: $primaryFont;
      outline: none;
      cursor: pointer;
    }
  }

  .select__menu {
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    z-index: 999;
  }

  .select__menu-list {
    padding: 0px;
  }

  // Select Menu
  .select__menu,
  .react-select__menu {
    .select__menu-list,
    .react-select__menu-list {
      .select__option,
      .react-select__option {
        cursor: pointer;
        font-size: 16px;
        color: $color-Black;
        font-family: $regulerFont;
        line-height: 19px;
        padding: 11px;

        &.select__option--is-focused {
          background-color: #d4d6d8;
          color: #000;
        }

        &.select__option--is-selected {
          background-color: #d4d6d8;
          color: $color-Black;
        }
      }
    }

    .select__menu-list,
    .react-select__menu-list {
      .select__group {
        .select__group-heading {
          margin-bottom: 0.5rem;
          color: green;
          font-weight: bolder;
          font-size: inherit;
        }
      }
    }
  }

  // Multi Select
  .select__multi-value,
  .react-select__multi-value {
    color: $color-Black;

    margin: 0 0.7rem 0 0;

    .select__multi-value__label {
      color: #fff;
      font-size: 0.85rem;

      padding: 0.26rem 0.6rem;
    }

    .select__multi-value__remove {
      padding-left: 0;
      padding-right: 0.5rem;

      &:hover {
        background-color: inherit;
        color: inherit;
      }

      svg {
        height: 0.85rem;
        width: 0.85rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  // Select Borderless
  .select-borderless {
    .select__control {
      border: 0;

      .select__indicators {
        display: none;
      }
    }
  }
}

.sign-up-btn-details {
  display: flex;
  align-items: center;
  column-gap: 10px;
  @media (max-width: 1159px) {
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 699px) {
    flex-direction: column;
    align-items: baseline;
    row-gap: 12px;
  }
  .subs-des {
    font-size: 16px;
    line-height: 20px;
    color: $color-Black;
    font-family: $regulerFont;
    .color-primary {
      color: $color-Accent-1;
      font-weight: 600;
    }
  }
  .runnertable-btn {
    padding: 8px 18px;
    background-color: $color-Accent-1;
    color: $color-White;
    border: 1px solid $color-Accent-1;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    border-radius: 8px;
    text-transform: capitalize;
    min-width: 134px;
    cursor: pointer;
    @media (max-width: 1023px) {
      padding: 8px 12px;
    }
    &:hover {
      background-color: $color-Accent-1;
      text-decoration: none;
    }
  }
}
.subscription-btn-detail {
  @media (max-width: 599px) {
    width: 100%;
  }
  .runnertable-btn {
    padding: 8px 18px;
    background-color: $color-Accent-1;
    color: $color-White;
    border: 1px solid $color-Accent-1;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    border-radius: 8px;
    text-transform: capitalize;
    cursor: pointer;
    &:hover {
      background-color: $color-Accent-1;
      text-decoration: none;
    }
    @media (max-width: 599px) {
      width: 100%;
    }
  }
}

.racing-tab {
  display: flex;
  align-items: center;

  .racing-location ul {
    span {
      max-width: 201px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    img {
      margin-right: 9px;
      object-fit: contain;
    }

    margin: 0px;
    padding: 0px;
    display: flex;
    list-style-type: none;
    column-gap: 24px;
    justify-content: flex-end;
  }

  .country-name {
    margin-left: 10px;
  }

  li {
    position: relative;
    display: flex;
  }

  li:not(:last-child):after {
    content: "/";
    font-size: 16px;
    color: $color-Black;
    font-family: $regulerFont;
    position: absolute;
    right: -14px;
  }

  .racing-track {
    display: flex;
    align-items: center;
    column-gap: 9px;
    margin-left: 62px;

    .MuiRating-label {
      color: #4455c7;
    }

    .MuiRating-root {
      svg {
        fill: #4455c7;
      }
    }
  }

  .racing-Weather {
    display: flex;
    align-items: center;
    column-gap: 9px;
    margin-left: 63px;
    justify-content: flex-end;

    .weather {
      display: flex;
      align-items: center;
      column-gap: 5px;

      img {
        height: 18px;
      }
    }
  }
}

.race-track-blackbook {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-Accent-1;
  flex-wrap: wrap-reverse;
}

.race-track-list {
  padding: 12px 0px 14px 0px;
  // border-bottom: 1px solid #4455c7;
  min-height: auto;

  .MuiTabs-flexContainer {
    column-gap: 18px;
  }

  .race-track {
    min-height: auto;
  }

  .MuiTab-wrapper {
    font-weight: 600;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid;
    color: #003764;
    background: transparent;
    border-color: #003764;
    font-size: 16px;
  }

  .MuiTab-root {
    min-width: auto;
    padding: 0px;
    min-height: auto;
    opacity: 1;
  }

  .Mui-selected {
    span {
      color: #fff;
      background-color: #4455c7;
      border-color: #4455c7;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.blackbook-button-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 5px;
  // border-bottom: 1px solid $color-Accent-1;

  .blackbook-button {
    padding: 12px 24px;
    border: 1px solid $color-Accent-1;
    border-radius: 8px;

    @media (max-width: 799px) {
      padding: 11px 12px;
    }

    span {
      font-size: 16px;
      line-height: 20px;
      font-family: $regulerFont;
      color: $color-Accent-1;
      text-transform: initial;

      @media (max-width: 799px) {
        font-size: 11.42px;
        line-height: 14px;
      }
    }

    &:hover {
      background-color: $color-Accent-1;

      span {
        color: $color-White;

        svg {
          path {
            stroke: $color-White;
          }
        }
      }
    }
  }
}

.racing-detail-head {
  display: flex;
  column-gap: 45px;
  padding: 18px 0px;
  align-items: center;

  h6 {
    margin: 0px;
    font-size: 13px;
    line-height: 16px;
    color: #191919;
  }

  span {
    font-size: 13px;
    padding: 0px;
    font-weight: normal;
  }
}

.border-right {
  border-right: 0.6px solid #d4d6d8;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.max-w {
  max-width: 505px;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 9px;
  padding-bottom: 9px;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 660px) {
    padding: 15px 0px;
    max-width: 354px;
  }

  .odds-flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    column-gap: 29px;
    @media (max-width: 799px) {
      column-gap: 15px;
    }
    @media (max-width: 660px) {
      column-gap: 6px;
    }

    .odds-box-style {
      text-align: center;
      height: 64px;

      .odds-style {
        cursor: pointer;
        max-width: none;
        width: 60px;
        text-align: center;
        padding: 6.5px 0px;
        border-radius: 6px;
        margin: 0px auto;
        background-color: $color-Light-grey;
        box-shadow: 0px 2px 1px 0px #707070;

        @media (max-width: 660px) {
          padding: 6.5px 0px;
          width: 54px;
        }
        @media (max-width: 389px) {
          width: 48px;
        }

        .odds {
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          @media (max-width: 660px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }
      }
      .active-odd-style {
        background-color: $color-Primary;
        span,
        .odds {
          color: $color-White;
        }
      }
      .odd-img {
        width: 60px;
        height: 25px;
        margin: 0 auto;
        margin-top: 6px;
        @media (max-width: 660px) {
          // max-width: 54px;
          width: 54px;
        }
        @media (max-width: 389px) {
          width: 48px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 8px;
        }
      }
    }
  }
}

.border-left {
  border-left: 0.6px solid #d4d6d8;
}

.border-bottom {
  border-bottom: none !important;
}

.race-comment {
  padding: 19px 0px 10px 0px;

  h6 {
    margin: 0px 0px 9px 0px;
    font-size: 16px;
    line-height: 19px;
  }

  p {
    color: #8d8d8d;
    line-height: 16px;
  }
}

.race-player-detail-wrap {
  margin-top: 17px;
  background: #ffffff;
  box-shadow: 0 3px 9px 0 RGB(0 0 0 / 5%);

  .race-bookmakersodd {
    overflow-x: auto;
    // position: relative;
  }

  .see-filter {
    position: absolute;
    right: 0px;
    top: 15px;
    border: none;
    cursor: pointer;
    z-index: 9;
    font-size: 14px;
  }

  // .next5odds {
  //   position: relative;
  // }
  // .dummy-column {
  //   position: relative;
  // }

  .runner-detail-wrap {
    padding: 24px 0px 0px 0px;
    position: relative;
    margin-bottom: 12px;

    .see-best {
      top: 15px;
      z-index: 99;
      right: 0;
      font-weight: 600;
    }
  }

  // runner tab deatils

  .runner-tab {
    background-color: $color-White;
    margin: 18px 0px;
    padding: 0px 0px 18px;

    @media (max-width: 599px) {
      padding: 9px 0px 12px;
      margin-bottom: 18px;
    }

    .runner-tab-detail {
      border-bottom: 2px solid #4455c7;

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .tab-label {
        display: flex;

        .label-name {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: #191919;
          line-height: 21px;
          font-weight: 400;
        }

        .label-icon {
          margin-left: 3px;

          svg {
            path {
              stroke: $color-Black;
            }

            line {
              stroke: $color-Black;
            }

            circle {
              stroke: $color-Black;
            }

            #Rectangle_18598 {
              stroke: $color-Black;
            }
          }
        }
      }

      .active {
        .label-name {
          color: $color-Accent-1;
        }

        .label-icon {
          svg {
            path {
              stroke: $color-Accent-1;
            }

            line {
              stroke: $color-Accent-1;
            }

            circle {
              stroke: $color-Accent-1;
            }

            #Rectangle_18598 {
              stroke: $color-Accent-1;
            }
          }
        }
      }

      .MuiBox-root {
        min-width: max-content;
      }

      .MuiTab-root {
        min-width: max-content;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }
    }
  }
}

.ex-full {
  text-decoration: underline;
  color: #4455c7;
  font-size: 11.42px;
  cursor: pointer;
}

.bookmaker-thumb {
  max-width: 33px;
  border-radius: 6px;
  cursor: pointer;
  height: 33px;
  object-fit: contain;
}

.not-clickable {
  cursor: text !important;
}

.player-order {
  cursor: pointer;
  margin-left: 9px;
}

.racing-data {
  // overflow: hidden;

  .racing-data {
    cursor: pointer;
  }

  .odds-value-wrap {
    text-align: center;
    padding: 16px 0px 12px;
    // background-color: rgba(212, 214, 216, 0.13);
    // height: 62px;
    @media (max-width: 1280px) {
      padding: 0;
    }

    .tip-odds-detail {
      height: 19px;
    }

    .text-accent-1 {
      color: $color-Accent-1 !important;
      font-weight: 600;
    }

    .txt-white {
      color: $color-White !important;
    }

    .odds-value {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Accent-1;

      .no-odds {
        padding: 6px 12px;
        background-color: #989898;
        color: #ffffff;
        border-radius: 14px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .current-best-odds-value {
      background-color: $color-Lavender;
      padding: 9px 0px;
      width: 80px;
      margin: 0px auto;
      border-radius: 6px 6px 0px 0px;
    }

    .active-odd-style {
      background-color: $color-Primary !important;
    }
    .current-best-odds-icon {
      height: 25px;
      border-radius: 0px 0px 6px 6px;
      width: 80px;
      margin: 0px auto;
      .bookmaker-thumb {
        margin: 0px auto !important;
        // max-width: 33px;
        border-radius: 6px;
        cursor: pointer;
        height: 25px;
        object-fit: contain;
      }
    }

    .mb-10 {
      margin-bottom: 10px;

      @media (max-width: 799px) {
        margin-bottom: 5px;
      }
    }

    .bookmaker-thumb {
      // max-width: 33px;
      width: 80px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-radius: 6px;
      cursor: pointer;
      height: 25px;
      object-fit: contain;
    }
  }

  .align-right {
    margin-left: auto;
    background: none !important;
  }
  .table-runner-info {
    cursor: default;
    display: flex;
    .collapse-icon {
      margin-right: 6px;
      margin-top: 6px;
      cursor: pointer;
      @media only screen and (max-width: 799px) {
        margin-top: 18px;
      }
    }
  }

  .racer-detail-wrap {
    .scratched {
      color: #979797;

      .scratched-runner-name {
        text-decoration: line-through;
      }
    }

    .scratched-data {
      color: #979797;
      margin-top: 10px;
    }

    .player-wrap {
      display: flex;
      column-gap: 36px;
      .text-semibold {
        font-weight: 600;
      }
      span {
        font-size: 11.42px;
        line-height: 16px;
        color: #191919;
      }
    }

    .blackbook-icon {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .book-icon {
        margin-left: 15px;
      }
    }

    h6 {
      text-align: left;
      font-weight: 600;
      color: #000000;
      line-height: 19px;
      font-size: 16px;
      // margin-bottom: 10px;
    }
  }

  .MuiTableCell-head {
    padding: 5px 0px 4px 0px;
    font-size: 16px;
    color: #191919;
    line-height: 19px;
    font-weight: 600;
    border-bottom-color: #989898;
    font-family: $regulerFont;
  }

  .current-best {
    background-color: $color-Accent-1 !important;
    color: $color-White !important;
  }

  .sponsored-odds {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;

    td {
      border: none;
    }

    @media only screen and (max-width: 799px) {
      td {
        padding: 3px 0px 3px 0px !important;
      }
    }
  }

  .next5odds {
    padding: 14px 0px 18px 0px;
  }

  .table-head {
    background-color: #e2e4f1;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;

    // border-right: 3px solid $color-White;
    // border-left: 3px solid $color-White;
    .sponsored {
      // background: radial-gradient(
      //   circle,
      //   rgba(216, 71, 39, 1) 0%,
      //   rgba(0, 55, 100, 1) 100%
      // );
      color: #d6d9f3;
      font-weight: 800;
    }
  }

  .sponsor-odds-header {
    text-align: center;
    background-color: $color-Primary !important;
    padding: 6px 9px;
    color: $color-Lavender !important;

    .header-name {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;

      text-transform: uppercase;
      letter-spacing: 0px;
    }
  }

  // .table-head::after {
  //   content: "";
  //   background-color: $color-White;
  //   width: 3px;
  //   height: 32px;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }

  // .table-head:last-child::after {
  //   content: "";
  //   width: 0px;
  // }

  // .head-row {
  //   position: relative;
  // }

  .MuiTableBody-root {
    td:nth-child(-n + 3) {
      text-align: center;
    }

    td:nth-child(-n + 3) .bookmaker-thumb {
      margin: 10px auto 0px auto;
    }

    .bookmaker-thumb {
      border-radius: 6px;
      display: block;
      margin: 10px auto 0px auto;
      max-width: 80px;
      cursor: pointer;
      height: 25px;
    }

    .MuiTableCell-root {
      text-align: center;
      padding: 19px 0px 18px 0px;
      font-size: 16px;
      border-color: #e7e9ec;
    }

    // .racing-track-data-row:nth-last-child(2) {
    //   .MuiTableCell-root {
    //     border: none !important;
    //   }
    // }

    .no-odds {
      padding: 6px 12px;
      background-color: $color-grey-Accent-1;
      color: $color-White;
      border-radius: 14px;
      font-size: 12px;
      cursor: pointer;

      @media screen and (max-width: 799px) {
        padding: 3px 10px;
      }
    }
  }

  .sponsored-header {
    background-color: $color-Primary;
  }

  .odds-column {
    background-color: rgba(212, 214, 216, 0.13);
  }

  .current-best-odds-column {
    background-color: #eeefff;
  }

  // .see-filter {
  //   top: 83px;
  // }
}
.featured-table {
  .table-left-header {
    background-color: $color-grey;
    padding: 6px;
    border-bottom: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
  }
  .current-best-odds-wrap {
    padding: 0px 26px;
    .current-best-odds-value {
      background-color: $color-Lavender;
      padding: 9px 0px;
      width: 80px;
      margin: 0px auto;
      border-radius: 6px 6px 0px 0px;
    }
    .active-odd-style {
      background-color: $color-Primary;
      span,
      .odds {
        color: $color-White;
      }
    }
    .current-best-odds-icon {
      border-radius: 0px 0px 6px 6px;
      width: 80px;
      margin: 0px auto;
      .currentbest-bookmaker-thumb {
        margin: 0px auto;
        border-radius: 0px 0px 6px 6px;
        cursor: pointer;
        height: 25px;
        object-fit: cover;
        width: 100%;
        max-width: none;
      }
    }
  }
}

.text-accent-1 {
  color: $color-Accent-1 !important;
  font-weight: 600;
}

.txt-white {
  color: $color-White !important;
}
.runner-info-td {
  background: #003764 !important;
  padding: 0px !important;
  position: relative;
  min-width: 1086px !important;

  .MuiCollapse-wrapper {
    padding: 41px 39px 32px 40px;
    border-top: 5px solid #4455c7;
  }

  .collapse-icon {
    position: absolute;
    top: 4px;
    left: 40px;
  }

  .runner-info-wrap {
    h6 {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: #ffc69a;
    }

    .des {
      font-size: 12px !important;
    }

    p {
      text-align: left;
      color: #ffffff;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 460px) {
        font-size: 12px;
      }
    }
  }

  .runner-comment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 28.4px;
    position: relative;
  }

  .runner-comment::after {
    content: "";
    height: 2px;
    background: #989898;
    width: 100%;
    position: absolute;
    bottom: 0;
    max-width: 100%;
  }

  .comment-content {
    // width: 67%;
    width: 85%;
  }

  .win-graph {
    position: relative;
  }

  span.graph-label {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }

  span.graph-label.value {
    font-size: 22.4px;
    line-height: 24px;
    color: #fff;
    font-weight: bold;
  }

  span.graph-label.lb {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: bold;
  }

  span.graph-label.first {
    color: #e2662c;
    top: 45px;
  }

  span.graph-label.second {
    top: 67px;
  }

  span.graph-label.third {
    color: #1c9a6c;
    top: 88px;
  }

  span.graph-label.fourth {
    top: 109px;
  }

  path.recharts-sector {
    stroke: transparent;
  }

  .full-from-btn {
    @media (max-width: 799px) {
      border-bottom: 2px solid #989898;
    }

    button {
      font-size: 18px;
      color: #ffffff;
      line-height: 21px;
      text-transform: capitalize;
      padding: 11px 23px;
      letter-spacing: 0px;
      border: 1px solid #4455c7;
      border-radius: 8px;

      @media (max-width: 460px) {
        font-size: 16px;
      }
    }
  }

  .advance-race-detail-wrap {
    padding: 19px 0px 18px;
    border-bottom: 2px solid #989898;

    ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      max-width: 100%;

      li {
        width: max-content;
      }

      span:first-child {
        margin-bottom: 5px;
      }

      span.title {
        color: #ffffff;
        font-weight: 600;
      }

      span {
        display: block;
        text-align: left;
        color: #ffc69a;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;

        @media (max-width: 460px) {
          font-size: 12px;
        }
      }
    }
  }

  .runner-detail-tab-wrap {
    padding-top: 30px;

    .MuiBox-root {
      padding: 0px;
    }

    .MuiTabs-flexContainer {
      width: 100%;
      margin: 0 auto;
      // background: #303135;
      padding: 4px;
      border-radius: 5px;
      max-width: 260px;
      column-gap: 12px;
    }

    .MuiTabs-indicator {
      display: none;
    }

    button.Mui-selected {
      background: #4455c7;
      border-radius: 8px;
    }

    .MuiButtonBase-root {
      color: #fff;
      opacity: 1;
      width: 100%;
      min-width: auto;
      max-width: 130px;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 19px;
      border: 1px solid #4455c7;
      border-radius: 8px;
    }

    .short-form {
      padding: 33px 0px 0px;
    }
    .short-form ul {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      column-gap: 2px;
      row-gap: 2px;
      background: #e7e9ec;

      li {
        flex: 1 0 15%;
        display: flex;
        justify-content: space-between;
        // border-radius: 22px;
        padding: 12px 14px;
        // background-color: #595959;
        background-color: #d6d9f3;
      }

      li.active {
        background-color: #2d2424;

        span {
          color: #ffc69a;
        }
      }

      li {
        span {
          color: #4455c7 !important;
        }
      }
    }

    .runner-tab-table {
      max-width: 1007px;

      //  margin: 20px;
      // padding: 20px;
      // background-color: #f0f0f0;

      table {
        white-space: nowrap;
        // border-radius: 10px;
        margin-bottom: 5px;
        overflow: hidden;
      }

      // .table-header {
      //   background-color: #333;
      //   color: white;
      //   font-weight: bold;
      // }
      // .table-cell {
      //   background-color: #f9f9f9;
      //   padding: 10px;
      // }
    }

    // .table-container {
    //   margin: 20px;
    //   padding: 20px;
    //   background-color: #f0f0f0;
    // }

    // /* Style for the Table Header */

    // /* Style for the Table Cell in the Body */

    .runner-tab-table-head {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 0px;

      .MuiBox-root {
        padding: 0px;
      }

      h6 {
        font-size: 12px;
        font-weight: 600;
      }

      svg {
        cursor: pointer;
      }
    }

    .runner-tab-table {
      .table-header {
        background: #d6d9f3 !important;
        color: #4455c7 !important;
        // font-weight: bold;
        margin-left: 10px;
      }

      .table-cell {
        background: #f9f9f9 !important;
        padding: 10px;
      }

      .MuiTableHead-root {
        .MuiTableCell-root {
          font-size: 16px;
          font-weight: 600;
          padding: 12px 15px 12px 15px;
          white-space: nowrap;
          line-height: 13px;
          // border: 1px solid #707070;
          border: 1px solid rgba(7, 7, 7, 0.3);
          background-color: #47494d;
          color: #d5d5d5;

          @media (max-width: 460px) {
            font-size: 12px;
          }
        }

        .MuiTableCell-root:last-child {
          padding-right: 5px !important;
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          font-size: 12px;
          font-weight: 600;
          padding: 12px 15px 12px 15px;
          white-space: nowrap;
          line-height: 13px;
          background-color: #363535;
          border: 1px solid rgba(7, 7, 7, 0.3);
          // border: 1px solid #707070;
          color: #191919 !important;
          border-bottom: 0px;
          text-align: left;
        }

        .result {
          span {
            margin-right: 8px;
          }
        }

        span.disable {
          color: #a4a4a4;
        }

        span.highlight {
          background-color: #47494d;
          border-radius: 10px;
          color: #fc4714;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          padding: 3px 6px;
        }

        .MuiTableCell-root:last-child {
          padding-right: 15px;
        }
      }
    }
  }
}

.sticky-race-bookmakersodd {
  // overflow-y: hidden;
  // overflow-x: scroll;
  overflow-y: scroll;
  max-height: 600px;

  .overlay::after {
    content: "";
    position: absolute;
    width: 90px;
    height: calc(100% - 78px);
    z-index: 9;
    right: 0;
    background: transparent linear-gradient(90deg, #ffffff00 0%, #ffffff 60%) 0%
      0% no-repeat padding-box;
  }

  .arrow-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 65px;
    z-index: 99;

    .arrow {
      background-color: #707070;
      width: 32px;
      height: 26px;

      .MuiSvgIcon-root {
        width: 32px;
        height: 26px;
        color: #ffffff;
      }
    }

    .disable {
      background-color: #d8d8d8;
      width: 32px;
      height: 26px;

      .MuiSvgIcon-root {
        width: 32px;
        height: 26px;
        color: #ffffff;
      }
    }
  }
}

.sticky-racing-data {
  .racer-detail-wrap {
    .scratched {
      color: #979797;

      .scratched-runner-name {
        text-decoration: line-through;
      }
    }

    .scratched-data {
      color: #979797;
      margin-top: 10px;
    }
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      padding: 14px 0px 18px 0px;
      font-size: 16px;
      color: #191919;
      line-height: 19px;
      font-weight: 400;
      border-bottom-color: #989898;
      font-family: "Inter", sans-serif;
      text-align: center;
      min-width: 90px;
      background: #fff;
    }

    .MuiTableCell-root:first-child {
      // min-width: 429px;
      width: 429px;
    }

    .MuiTableCell-root {
      .MuiTableCell-root {
        padding: 0px;
        border: none;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      padding: 14px 0px 18px 0px;
      font-size: 16px;
      color: #191919;
      line-height: 19px;
      font-weight: 400;
      border-bottom-color: #e7e9ec;
      font-family: "Inter", sans-serif;
      min-width: 90px;
      background: #fff;

      .no-odds {
        padding: 6px 12px;
        background-color: $color-grey-Accent-1;
        color: $color-White;
        border-radius: 14px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .MuiTableCell-root:first-child {
      // min-width: 429px;
      width: 429px;
    }

    .MuiTableCell-root {
      .MuiTableCell-root {
        padding: 0px;
        border: none;
      }
    }
  }

  .racer-detail-wrap {
    .player-wrap {
      display: flex;
      column-gap: 36px;

      span {
        line-height: 24px;
        color: #191919;
      }
    }

    h6 {
      text-align: left;
      font-weight: 600;
      color: #000000;
      line-height: 19px;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .runner-tab-table {
    .MuiTableHead-root {
      .MuiTableCell-root {
        font-size: 12px;
        font-weight: 600;
        padding: 12px 0 12px 15px;
        white-space: nowrap;
        line-height: 13px;
        background-color: #47494d;
        color: #d5d5d5;
        min-width: auto;
        text-align: left;
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        font-size: 12px;
        font-weight: 600;
        padding: 12px 0 12px 15px;
        white-space: nowrap;
        line-height: 13px;
        background-color: #363535;
        border-top: 2px solid #252525;
        color: #fff;
        border-bottom: 0px;
        min-width: auto;
        text-align: left;
      }

      .result {
        span {
          margin-right: 8px;
        }
      }

      span.disable {
        color: #a4a4a4;
      }

      span.highlight {
        background-color: #47494d;
        border-radius: 10px;
        color: #fc4714;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        padding: 3px 6px;
      }

      .MuiTableCell-root:last-child {
        padding-right: 15px;
      }
    }
  }
}

.MuiTooltip-popper {
  top: 10px !important;
}

.MuiTooltip-tooltip {
  font-size: 11.42px !important;
  line-height: 14px;
}

@media only screen and (max-width: 1023px) {
  .racing-tab {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;

    .racing-track {
      margin-left: 0px;
    }
  }

  .race-track-list {
    .MuiTabs-flexContainer {
      column-gap: 11px;
    }
  }

  .track-table-th {
    width: 260px !important;
  }
  .result-track-table-th {
    min-width: 260px;
  }

  // .track-tabel-odds-th {
  //   width: 120px !important;
  // }

  .sponsor-odds-header {
    text-align: center;
    background-color: $color-Primary !important;
    padding: 6px 9px;
    color: $color-Lavender !important;

    .header-name {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;

      text-transform: uppercase;
      letter-spacing: 0px;
    }
  }

  .next5odds-icon {
    text-align: center !important;
    width: auto;

    .MuiBox-root {
      font-family: $regulerFont;
    }
  }

  .racing-detail-head {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;

    .race-track-info-right {
      text-align: end;
    }
  }
}

@media only screen and (max-width: 799px) {
  .racing-tab {
    display: block;

    .race-track-list {
      padding: 9px 0px 9px 0px;
      border-top: 1px solid #4455c7;
      border-bottom: none;
    }

    .racing-location ul {
      justify-content: start;
      padding-bottom: 9px;
    }

    .track-weather-wrap {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #4455c7;
      padding-bottom: 9px;

      .racing-Weather {
        margin-left: 0px;
      }
    }
  }

  .racing-detail-head {
    grid-column-gap: 0px;
    column-gap: 0px;
    padding: 9px 0px;
  }

  .race-comment {
    padding: 0px 0px 5px 0px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .race-bookmakersodd {
    .racing-data {
      .see-filter {
        position: relative;
        right: 5px;
        bottom: 0;
      }

      .runnertable-header {
        padding: 9px 0px 18px 0px;
      }

      .racer-detail-wrap {
        padding-top: 10px;
        padding-bottom: 9px;
        // border-bottom: 1px solid rgba(224, 224, 224, 1);

        h6 {
          margin-bottom: 5px;
        }

        .player-detail-container {
          padding-bottom: 9px;

          .player-wrap {
            display: flex;
            justify-content: start;
            column-gap: 9px;
          }
        }
      }

      .mobile-table-container {
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        .MuiTableContainer-root {
          border-radius: 8px;
        }
        .MuiTableCell-root {
          border-right: 3px solid white;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .mobile-data-head {
        th {
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          border-bottom: none;
          background-color: #d6d9f3;
          text-transform: uppercase;
          padding: 13px 0px 12px;
        }

        th:not(:last-child) {
          border-right: 3px solid white;
        }

        .sponsored-header {
          background-color: $color-Primary;
        }

        .sponsored {
          // background: radial-gradient(
          //   circle,
          //   rgba(216, 71, 39, 1) 0%,
          //   rgba(0, 55, 100, 1) 100%
          // );
          color: #d6d9f3;
          font-weight: 800;
        }
      }

      .mobile-table-data {
        text-align: center;
        // color: $color-Accent-1;

        .MuiBox-root {
          font-family: $regulerFont;
        }
      }

      .sponsor-odds-header {
        text-align: center;
        background-color: $color-Primary;
        padding: 6px 9px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .header-name {
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Lavender;
          text-transform: uppercase;
          letter-spacing: 0px;
        }
      }

      .MuiTableCell-head {
        color: $color-Accent-1;
      }

      .mobile-table-body {
        .MuiTableCell-root {
          padding: 6px 0px 9px 0px;
        }

        .bookmaker-thumb {
          margin: 4px auto 10px auto !important;
        }
      }

      .gray-bg {
        background-color: #f2f2f2 !important;
      }

      .mobile-best5odds-wrap {
        padding-bottom: 9px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);

        &:last-child {
          border-bottom: none;
        }

        .mobile-best5odds-select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid $color-attention;
          border-radius: 8px;
          color: $color-attention;
          padding: 8px;

          button {
            color: $color-attention;
          }
        }

        .next5odds-icon {
          border-bottom: none;
          padding: 21px 0px 0px 0px;
          min-width: 80px;
        }

        .bookmaker-thumb {
          margin: 4px auto 10px auto !important;
        }
      }

      .runner-info-td {
        min-width: auto !important;

        .runner-info-wrap {
          padding: 14px 12px 23px 12px;
          // border-top: 5px solid #707070;
          border-top: 5px solid #4455c7;

          .comment-content {
            width: 100%;

            h6 {
              margin-bottom: 5px;
            }
          }

          .runner-comment {
            display: block;
            padding-bottom: 0px;
          }

          .runner-comment::after {
            content: "";
            background-color: transparent;
          }

          .full-from-btn {
            padding: 20px 0px;
          }

          .advance-race-detail-wrap {
            padding: 7px 0px 28px 0px;

            ul {
              display: grid;
              grid-template-columns: auto auto;
              row-gap: 10px;
              grid-row-gap: 10px;
              justify-content: normal;

              li {
                width: 100%;
              }
            }

            ul:first-child {
              margin-bottom: 0px;
            }
          }

          .runner-detail-tab-wrap {
            padding-top: 10px;

            .MuiBox-root {
              padding: 0px;
            }

            .short-form {
              padding-top: 12px;

              ul {
                display: grid;
                grid-template-columns: auto auto;
              }
            }
          }
        }
      }
    }
  }

  .sticky-race-bookmakersodd {
    .sticky-racing-data {
      .MuiTableHead-root {
        .MuiTableCell-root:first-child {
          min-width: 280px;
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-root:first-child {
          min-width: 280px;
        }
      }

      .racer-detail-wrap {
        .player-wrap {
          display: flex;
          flex-direction: column;
        }
      }

      .MuiTableCell-alignRight {
        text-align: left;
      }

      .runner-info-td {
        min-width: auto !important;

        .MuiCollapse-wrapper {
          padding: 0px;
        }

        .runner-info-wrap {
          padding: 14px 12px 23px 12px;
          border-top: 5px solid #707070;

          .comment-content {
            width: 100%;

            h6 {
              margin-bottom: 5px;
            }
          }

          .runner-comment {
            display: block;
            padding-bottom: 0px;
          }

          .runner-comment::after {
            content: "";
            background-color: transparent;
          }

          .full-from-btn {
            padding: 20px 0px;
          }

          .advance-race-detail-wrap {
            padding: 7px 0px 28px 0px;

            ul {
              display: grid;
              grid-template-columns: auto auto;
              row-gap: 10px;
              grid-row-gap: 10px;
              justify-content: normal;

              li {
                width: 100%;
              }
            }

            ul:first-child {
              margin-bottom: 0px;
            }
          }

          .runner-detail-tab-wrap {
            padding-top: 10px;

            .MuiBox-root {
              padding: 0px;
            }

            .short-form {
              padding-top: 12px;

              ul {
                display: grid;
                grid-template-columns: auto auto;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .race-player-detail-wrap {
    margin-top: 0px;

    .runner-detail-wrap {
      padding: 11px 0px 0px 0px;
      margin-bottom: 0px;
    }
  }

  .race-bookmakersodd {
    .racing-data {
      .mobile-data-head {
        th {
          font-size: 11.42px;
          line-height: 14px;
        }
      }
    }
  }

  .racer-detail-wrap {
    .player-wrap {
      .player-detail {
        span {
          font-size: 12px;
          line-height: 16px;
        }
        .text-bold {
          font-weight: 600;
        }
      }
    }
  }

  .sticky-race-bookmakersodd {
    .sticky-racing-data {
      .MuiTableHead-root {
        .MuiTableCell-root:first-child {
          min-width: 200px;
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-root:first-child {
          min-width: 200px;
        }
      }
    }
  }
}

.race-comment-box {
  display: flex;
  align-items: center;
  cursor: pointer;

  h6 {
    margin-bottom: 0px;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.odds-link {
  color: $color-Accent-1;
  font-weight: 600;
  cursor: pointer;
}

.blur-comp {
  filter: blur(8px);
  -webkit-filter: blur(6px);
}

.betslip-added {
  font-weight: 700;
  color: $color-Accent-1;
}

.mobile-blackbook {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 9px;

  @media (max-width: 799px) {
    justify-content: start;
    column-gap: 9px;
  }
}

.custom-tooltip-runnertable {
  position: relative;
  display: flex;

  .tooltip {
    position: absolute;
    background-color: $color-White;
    width: 227px;
    height: auto;
    border: 1.5px solid #e7e9ec;
    padding: 4px 12px 6px;
    border-radius: 8px;
    z-index: 999;
    // top: calc(100% + 5px);
    left: 130px;
    transform: translateX(-36%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    text-align: left;
    top: -30px;
    box-shadow: 10px 10px 5px 0px #0000000f;
    -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
    -moz-box-shadow: 10px 10px 5px 0px #0000000f;

    @media (max-width: 799px) {
      // transform: translateX(-118%);
      // left: 35px;
      // top: -28px;
      transform: translateX(-60%);
      left: 35px;
      top: 30px;
    }

    .tooltip-arrow {
      position: absolute;
      top: 30px;
      left: -12px;
      transform: rotate(90deg);

      @media (max-width: 799px) {
        // left: initial;
        // right: -12px;
        // transform: rotate(270deg);
        top: -10px;
        left: 110px;
        transform: rotate(180deg);
      }
    }

    .add-blackbook {
      padding: 6px 10px 6px 8px;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 600;
      color: #003764;
      font-family: $regulerFont;
      border-bottom: 1px solid #e7e9ec;
      display: flex;
      align-items: center;
    }

    .black-details {
      padding-top: 3px;
      .tooltip-details {
        &:hover {
          background-color: #d4d6d8;
          width: 100%;
        }
        .details {
          padding: 6px 10px;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          color: $color-Black;
        }
      }
    }
  }
}

// .runner-table-tooltip {
//   @media (max-width: 799px) {
//     transform: translateX(-118%);
//     left: 35px;
//     top: -28px;
//   }
// }

.greyhound-tooltip {
  top: -105px;
}
