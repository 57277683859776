@import "../../../../../assets/scss/variable.scss";

.teamsport-team-data-wrap {
  .d-flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .content-space-between {
    justify-content: space-between;
  }

  .text-align-center {
    text-align: center;
  }

  .flex-direction {
    flex-direction: column;
  }

  .col-gap-5 {
    column-gap: 5px;
  }

  .col-gap-15 {
    column-gap: 15px;
  }

  .pt-15 {
    padding-top: 15px;
  }

  .pb-15 {
    padding-bottom: 15px;
  }

  .plr-5 {
    padding: 5px;
  }

  .no-match {
    padding: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-family: $regulerFont;
    color: $color-Black;
    font-weight: 400;
  }

  .no-match-boxshadow {
    padding: 18px 33px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
  }

  .team-info-header {
    padding: 46px 18px 3px;
    margin-top: -5px;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    column-gap: 10px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    .team-info-img-wrap {
      width: 48px;
      height: 48px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    .team-info-details {
      .team-info-name {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
      }

      .team-country-name {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
      }
    }
  }

  .team-sports-tab {
    padding: 19px 18px 13px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    margin: 10px 0px 18px;

    @media (max-width: 599px) {
      padding: 9px 12px 12px;
    }

    .teamsport-tab-detail {
      border-bottom: 2px solid #4455c7;

      .MuiTabs-scrollable {
        .MuiTabs-indicator {
          display: none;
        }

        .MuiButtonBase-root {
          border-bottom: 3px solid transparent;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .tab-label {
        display: flex;
        align-items: center;

        .label-name {
          font-size: 16px;
          font-family: $primaryFont;
          color: #191919;
          line-height: 21px;
          font-weight: 400;
        }
      }

      .active {
        .label-name {
          color: $color-Accent-1;
        }
      }

      .MuiTab-root {
        min-width: max-content;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .overview-title {
    font-size: 22.4px;
    line-height: 28px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;
    border-bottom: 1px solid $color-Accent-1;
    margin-bottom: 12px;
  }

  .select__control {
    background-color: $color-Light-grey;
    border-radius: 8px;
    max-height: 36px;
    max-width: 400px;

    @media (max-width: 479px) {
      max-width: none;
    }

    .select__single-value {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__placeholder {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__input-container {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__indicator-separator {
      width: 0px;
    }

    .select__dropdown-indicator {
      padding: 10px;
    }
  }

  .select__menu {
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    z-index: 999;
  }

  .select__menu-list {
    padding: 0px;
  }

  // Select Menu
  .select__menu,
  .react-select__menu {
    .select__menu-list,
    .react-select__menu-list {
      .select__option,
      .react-select__option {
        cursor: pointer;
        font-size: 16px;
        color: $color-Black;
        font-family: $regulerFont;
        line-height: 19px;
        padding: 11px;

        &.select__option--is-focused {
          background-color: #d4d6d8;
          color: #000;
        }

        &.select__option--is-selected {
          background-color: #d4d6d8;
          color: $color-Black;
        }
      }
    }

    .select__menu-list,
    .react-select__menu-list {
      .select__group {
        .select__group-heading {
          margin-bottom: 0.5rem;
          color: green;
          font-weight: bolder;
          font-size: inherit;
        }
      }
    }
  }

  .select__menu-portal {
    z-index: 99;
  }

  .card-wrap {
    .justify-space-around {
      justify-content: space-around;
    }

    .sport-team-name {
      row-gap: 10px;
    }

    .sport-score-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      row-gap: 10px;
      flex-direction: column;

      .fs-18 {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;
      }
    }
  }

  .team-data-details-wrap {
    padding: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 799px) {
      padding: 12px;
    }
  }

  // matches

  .soccer-details-tab {
    .MuiTabs-scrollable {
      .MuiTabs-indicator {
        display: none;
      }

      .active {
        background-color: $color-Primary;

        .match-tab {
          span {
            color: $color-White !important;
          }
        }
      }
    }

    @media (max-width: 799px) {
      margin-top: 6px;
    }

    .MuiTabs-flexContainer {
      column-gap: 12px;
    }

    .MuiBox-root {
      min-width: 105px;

      .MuiButtonBase-root {
        border: 2px solid $color-Primary;
        border-radius: 22px;
        min-height: 45px;

        @media (max-width: 799px) {
          min-height: 40px;
        }

        .match-tab {
          span {
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            color: $color-Black;
            width: 100%;
          }
        }
      }
    }

    .MuiTab-textColorInherit {
      opacity: 1;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  .team-tournament-wrap {
    max-width: 400px;
  }

  .tournament-name-wrap {
    column-gap: 10px;

    .tournament-img-wrap {
      width: 48px;
      height: 48px;
      border-radius: 50%;

      @media (max-width: 799px) {
        width: 33px;
        height: 33px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    .tournament-name-wrap {
      .tournament-name {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        color: $color-Black;
        font-family: $regulerFont;
      }

      .tournament-country {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $color-Black;
        font-family: $regulerFont;
      }
    }
  }

  // table

  .soccer-team-tab-table {
    .MuiBox-root {
      min-width: 80px;
    }
  }

  .short-name-details {
    padding: 10px 0px 0px 0px;
  }

  .light-blue {
    background-color: #0075ff;
  }

  .medium-orange {
    background-color: #ab5e03;
  }

  .dark-red {
    background-color: #961a3d;
  }

  .league-indicator {
    width: 50%;
    height: 10px;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    bottom: 0px;
  }

  .league-name-indicator {
    column-gap: 15px;
    padding: 15px 0px 0px;
    flex-wrap: wrap;
    row-gap: 12px;

    .league-name-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .league {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      color: $color-Black;
      font-family: $regulerFont;
    }
  }

  .info-table-cont-wrap {
    &:not(:first-child) {
      margin-top: 18px;
    }

    .data-table {
      box-shadow: inset 1px 2px 9px 0px #0000001f;
      background-color: $color-White;
      border: 1px solid #c9c9c9;

      .table-header {
        .MuiTableCell-head {
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
          font-family: $regulerFont;
          padding: 17px 24px 8px;
        }
      }

      .table-body {
        .MuiTableCell-body {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: $regulerFont;
          padding: 17px 24px 8px;
        }

        .table-body-column {
          // padding: 0px 24px 0px 0px;
          border-bottom: none;
          font-size: 16px;
          line-height: 19px;
        }

        .table-team-logo {
          .logo-wrap {
            width: 33px;
            height: 33px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: contain;
            }
          }

          .soccer-tema-name {
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            font-family: $regulerFont;
          }
        }

        .body-cell-indicator {
          position: relative;
        }
      }
    }
  }

  .mobile-info-table {
    padding: 12px;
    background-color: $color-White;
    border-radius: 8px;
    box-shadow: 0px 3px 9px 0px #0000000d;

    &:not(:first-child) {
      margin-top: 12px;
    }

    .mobile-table-heading {
      column-gap: 15px;
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 5px;

      .table-heading {
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        color: $color-Black;
        font-weight: $regulerFont;
        letter-spacing: 0px;
      }

      .heading-pos {
        width: 35px;
        text-align: center;
      }
    }

    .info-table-deatils {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px 9px;

      .info-table-mobile-header {
        column-gap: 15px;

        .details-name {
          font-size: 15px;
          line-height: 33px;
          color: $color-Black;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          width: 35px;
          position: relative;
          padding-bottom: 5px;
          text-align: center;

          .league-indicator {
            width: 100%;
          }
        }

        .table-body-column {
          .team-wrap-body {
            column-gap: 5px;

            .img-wrap {
              width: 33px;
              height: 33px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

            .team-name {
              font-size: 15px;
              line-height: 18px;
              font-weight: 400;
              font-family: $regulerFont;
              color: #1a73e8;
              letter-spacing: 0px;
            }
          }
        }
      }

      .arrow-details {
        width: 22px;
        height: 22px;

        .MuiButtonBase-root {
          width: 100%;
          height: 100%;

          svg {
            path {
              fill: #e2662c;
            }
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }

    .info-table-border-bottom {
      &:not(:last-child) {
        border-bottom: 1px solid #bdbdbf;
      }
    }

    .collapse-box {
      &:not(:last-child) {
        border-bottom: 1px solid #bdbdbf;
      }

      .collapse-deatils {
        box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.16);

        .collapse-table {
          .collapse-table-row {
            border-bottom: 1px solid rgba(189, 189, 191, 50%);

            .MuiTableCell-root {
              padding: 16px 31px 11px 27px;
              font-size: 16px;
              line-height: 16px;
              font-family: $regulerFont;
            }

            .td-left {
              color: #003764;
              font-weight: 600;
            }

            .td-right {
              color: #191919;
              font-weight: 500;
              text-align: end;
            }
          }
        }
      }

      .view-profile-box {
        text-align: end;

        .view-profile {
          font-size: 12px;
          line-height: 16px;
          color: #4455c7;
          font-weight: 400;
          font-family: $regulerFont;
          text-decoration: underline;
          padding: 9px 0px;
        }
      }
    }

    .collapse-box-bottom {
      padding-bottom: 9px;
    }

    .no-data-mobile {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: $color-Black;
      font-weight: 600;
      font-family: $regulerFont;
      padding: 10px 0px 9px;
    }
  }

  // news
  .all-news {
    h4 {
      font-size: 31.36px;
      line-height: 40px;
      color: $color-Primary;
      font-weight: 400;
      font-family: $primaryFont;
      margin-bottom: 9px;
      position: relative;
      margin-top: 7px;
      display: block;

      @media (max-width: 439px) {
        font-size: 22.4px;
        line-height: 28px;
        margin-bottom: 12px;
        // margin-top: 18px;
      }
    }
  }
  .individual-news-cont {
    margin-top: 7px;
    padding: 5px;

    @media (max-width: 799px) {
      margin-top: 0px;
    }

    .individual-single-news {
      margin-bottom: 21px;
      padding: 15px 12px;
      background-color: #ffffff;
      box-shadow: 0px 1px 9px 0px #0000001f;
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: 799px) {
        padding: 12px;
        margin-bottom: 12px;
      }

      // &:not(:last-child) {
      //   border-bottom: 1px solid #c9c9c9;
      // }

      .news-box {
        display: flex;
        column-gap: 12px;

        .news-img {
          position: relative;
          min-width: 312px;
          // min-height: 164px;
          max-width: 312px;
          height: 164px;
          // margin-bottom: 18px;
          cursor: pointer;

          @media (max-width: 1280px) {
            max-width: 200px;
            min-width: 200px;
            // margin-right: 12px;
            height: 140px;
          }

          @media (max-width: 1120px) {
            max-width: 180px;
            min-width: 180px;
            height: 120px;
          }

          @media (max-width: 439px) {
            max-width: 140px;
            min-width: 140px;
            height: 105px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media (max-width: 1280px) {
              width: 200px;
              height: 140px;
            }

            @media (max-width: 1120px) {
              width: 180px;
              height: 120px;
            }

            @media (max-width: 439px) {
              width: 140px;
              height: 105px;
            }
          }
        }

        .individual-news-details {
          width: 100%;
          position: relative;

          .exclusive {
            padding: 3px 11px;
            background-color: #d84727;
            color: #ffffff;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            max-width: fit-content;
            border-radius: 3px;
            margin-bottom: 9px;
          }

          .details {
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 600;
            font-family: $regulerFont;
            color: $color-Black;
            // margin-bottom: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            cursor: pointer;

            @media (max-width: 1440px) {
              -webkit-line-clamp: 2;
            }

            @media (max-width: 1024px) {
              margin-bottom: 4px;
              font-size: 14px;
              line-height: 18px;
            }

            // @media (max-width: 439px) {
            //   font-size: 14px;
            //   line-height: 18px;
            // }
          }

          .sub-details-wrap {
            .sub-details {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-gray-dark;
              margin-top: 4px;

              @media (max-width: 1024px) {
                font-size: 11.42px;
                line-height: 14px;
              }

              .read-more {
                color: $color-Accent-1;
              }
            }
          }

          .tag-time-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 13px;

            @media (max-width: 599px) {
              flex-direction: column;
              row-gap: 4px;
              margin-top: 6px;
            }

            .news-tags {
              display: flex;
              flex-wrap: wrap;
              row-gap: 5px;
              width: 70%;

              @media (max-width: 599px) {
                width: 100%;
              }
            }

            .individual-news-tag {
              padding: 3px 6px;
              background-color: #989898;
              color: #ffffff;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              font-family: $regulerFont;
              max-width: fit-content;
              border-radius: 23px;

              &:not(:last-child) {
                margin-right: 12px;

                @media (max-width: 1140px) {
                  margin-right: 6px;
                }
              }
            }

            .stories-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
              // width: 30%;
              // text-align: end;

              @media (max-width: 599px) {
                width: 100%;
              }

              .watch {
                margin-right: 4px;
                vertical-align: middle;
              }
            }
          }

          .news-time-comment-wrapper {
            position: absolute;
            left: 0px;
            bottom: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 24px);

            @media (max-width: 799px) {
              bottom: 0;
              left: 0;
              width: 100%;
            }

            .news-time {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
            }

            .bookmark-chat-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;

              .chat-details {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 6px;

                svg {
                  margin-right: 3px;
                }
              }

              .saved-bookmark-icon {
                path {
                  fill: $color-gray-dark;
                }
              }
            }
          }
        }
      }
    }
  }

  // player stats

  .soccer-player-stats-tab-table {
    padding: 15px 0px 0px;

    .MuiBox-root {
      min-width: max-content;

      .MuiButtonBase-root {
        min-width: max-content;
        padding: 6px 18px;
        min-height: 40px;
      }
    }
  }

  .player-stats-details-wrap {
    padding-top: 10px;

    .stats-details {
      border: 1px solid #d4d6d8;
      border-radius: 12px;
      margin-bottom: 18px;

      .stats-list {
        padding: 6px 12px;

        &:not(:last-child) {
          border-bottom: 1px solid #d4d6d8;
        }

        .tournament-img-wrap {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
