@import "src/assets/scss/variable.scss";

.wrapper {
  // height: 100vh;
  // V3 sticky header
  // background-image: url("./.././assets/images/banner/main-bg2.webp");
  // background-repeat: repeat;
  // background-position: top center;
  // background-size: auto;
  // background: #dbdbdb;
  // overflow: hidden;
  background: #f8f8f8;

  .top-header-banner-wrap {
    display: flex;
    justify-content: center;

    .top-header-banner {
      // height: 305px;
      // max-width: 1350px;
      width: 100%;
      // aspect-ratio: 1350/305;
      cursor: pointer;
    }
  }
}

.Main-Wrap {
  // background: #1c1745;
  // background-image: url("./.././assets/images/banner/main-bg.png");

  // .top-header-banner-wrap {
  //   position: relative;
  // }
  .side-bg-banner {
    position: absolute;
    // top: -305px;
    top: 0px;

    img {
      max-width: 218px;
      width: 100%;
      cursor: pointer;
    }
  }

  .left-bg-banner {
    left: -260px;
  }

  .right-bg-banner {
    right: -260px;
  }

  .external-wrapper {
    // overflow: scroll;
    // height: 100vh;
    // V3 sticky header
    // overflow-y: scroll;
  }

  .external-wrapper::-webkit-scrollbar {
    width: 0px;
  }

  .external-wrapper::-webkit-scrollbar-thumb {
    display: none;
  }

  .header-wrap {
    // justify-content: center;
    row-gap: 10px;
    justify-content: space-between;

    .MuiGrid-item {
      padding: 0px;
    }
  }

  .empty-space {
    height: 113px;
  }

  .layout-wrapper.full-layout {
    grid-template-columns: auto;
  }

  .layout-wrapper {
    grid-template-columns: auto 228px;
    display: grid;
    // position: relative;
    // V3 header
    max-width: 1374px;
    margin: 18px auto 0px;

    @media (max-width: 799px) {
      position: inherit;
    }

    // position: relative;
    // top: 113px;
    // padding-top: 110px;
    #left-sidebar {
      min-width: 0;
      width: auto;
      padding: 0;
      float: none;
      // height: calc(100vh - 305px);
      // height: 100vh;
      background: #003764;
    }

    #right-sidebar {
      min-width: 0;
      width: auto;
      padding: 0;
      float: none;
      overflow: visible;
      // height: calc(100vh - 305px);
      // height: 100vh;
      background: #e6eefa;
    }

    .left-sidebarcontent,
    .right-sidebarcontent {
      // padding: 113px 0px 0px 0px;
      margin-bottom: 0;
      float: none;
      position: relative;
      width: 100%;
      max-width: 228px;
      // height: calc(100vh - 305px);
      // height: 100vh;
      overflow-y: auto;
    }
  }

  .mobileMenu {
    display: none;

    @media (max-width: 799px) {
      display: block;
    }
  }

  .main-menu-wrap {
    // z-index: 1200;
    background-color: rgb(255, 255, 255);
    // padding: 9px 12px 10px 21px;
    // position: absolute;
    width: 100%;
    // V3 for sticky header
    // max-width: 1350px;
    // top: 46px;
    // padding: 9px 12px 4px 21px;
    // border-bottom: 1px solid #edebeb;
    padding: 10px 45px 10px 45px;
    max-width: 1920px;
    position: sticky;
    top: 130px;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);

    @media (max-width: 1600px) {
      // V3 header
      padding: 10px 25px 10px 25px;
    }

    @media (max-width: 1024px) {
      // V3 header
      padding: 6px 12px 5px 12px;
    }

    .search-wrap {
      max-width: 80.35% !important;
      display: flex;
      -webkit-justify-content: end;
      padding: 0px !important;
      display: block;
      margin-left: auto;
      text-align: right;

      .search_input {
        width: 100%;
        max-width: 478px;

        input {
          padding: 14px 5px;
          font-size: 16px;
          color: #d4d6d8;
          line-height: 19px;
          font-family: "Inter", sans-serif;
        }
      }

      .search-box {
        .search {
          position: relative;
          width: 20%;
          transition: all 0.5s linear;

          fieldset {
            border-color: transparent;
            transition: all 0.5s linear;
          }

          svg {
            position: absolute;
            right: 10px;
          }
        }
      }

      .search-box:hover {
        .search {
          width: 100%;

          fieldset {
            border-color: rgba(0, 0, 0, 0.23);
          }
        }
      }
    }

    .login-buttons {
      // padding: 0px !important;
      display: flex;
      justify-content: end;

      .signup-login-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 599px) {
          justify-content: space-between;
        }
      }

      .profile-wrap {
        position: relative;
      }

      .profile-logout-wrap {
        display: flex;
        justify-content: center;
        // justify-content: flex-end;
        align-items: center;
        flex-direction: column;

        .profile-img-wrap {
          width: 36px;
          height: 36px;
          border: 1px solid #fc4714;
          border-radius: 50%;
          cursor: pointer;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }

        .user-name {
          line-height: 14px;
          font-size: 11.42px;
          color: #4455c7;
          font-weight: 600;
          text-transform: capitalize;
          height: 14px;
        }
      }

      .auth-btn {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        border: 1px solid #4455c7;
        border-radius: 8px;
        letter-spacing: 0px;
        // V3 header
        text-transform: capitalize;

        @media (max-width: 1600px) {
          font-size: 14px;
          line-height: 16px;
        }

        @media (max-width: 1024px) {
          font-size: 11.42px;
          line-height: 14px;
          padding: 6px 12px !important;
        }

        @media (max-width: 799px) {
          font-size: 12px;
          padding: 12px 24px !important;
          line-height: 15px;
        }
      }

      .signup {
        background: #4455c7;
        color: #fff;
        margin-right: 9px;
        padding: 12px 21px 11px 21px;

        @media (max-width: 799px) {
          width: max-content;
          padding: 6px 12px;
        }
      }

      .signup:hover {
        background: #4455c7 !important;
      }

      .signin {
        background: #fff;
        color: #4455c7;
        padding: 12px 19.5px 11px 19.5px;

        @media (max-width: 799px) {
          width: max-content;
          padding: 6px 12px;
        }
      }

      .signin:hover {
        background: #fff !important;
      }

      .profile-icon {
        background: #4455c7 !important;
        // margin-left: 9px;
        border: 1px solid #fc4714;
        padding: 5px;
      }

      .logout {
        margin-right: 0px;
        margin-left: 9px;
      }

      .bet-slip-button {
        background-color: $color-Accent-1;
        display: flex;
        align-items: center;
        padding: 0px 12px;
        cursor: pointer;

        // V3 header
        // height: 68px;
        // margin: -9px -12px -10px 18px;
        margin: -10px -44px -10px 15px;
        height: auto;

        @media (max-width: 1600px) {
          // V3 header
          margin: -10px -24px -10px 15px;
        }

        @media (max-width: 1024px) {
          // V3 header
          margin: -6px -11px -5px 12px;
        }

        @media (max-width: 600px) {
          margin: -12px -12px -12px 5px;
          flex-direction: column;
          justify-content: center;
          padding: 0px 6px;
          min-width: fit-content;
          height: 73px;
        }

        .bet-slip {
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-White;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .bet-slip-count {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: $color-White;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Accent-1;
          text-align: center;
          margin-left: 11px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 799px) {
            width: 18px;
            height: 18px;
            font-size: 11.42px;
            line-height: 14px;
          }

          @media (max-width: 600px) {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .betslip-open {
    z-index: 1200;
  }

  .content::-webkit-scrollbar {
    display: none !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .content {
    overflow-y: auto;
    padding: 0px 12px;
    // height: 100vh;
    // height: calc(100vh - 305px);
    // height: calc(100vh - 112px);
    // scrollbar-color: transparent transparent;

    @media (max-width: 799px) {
      // height: 100vh;
    }

    .inner-content {
      // background: #fbfbfb;
      // padding: 0px 18px 0px 18px;
      // min-height: calc(100vh - 349px);
      // margin-top: 113px;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;

    .mobileMenu {
      margin-right: 15px;
    }

    .header-logo {
      max-width: 240.56px;

      @media (max-width: 1024px) {
        height: 26.32px;
      }
    }
  }

  // .v3-menu-header {
  //   display: grid;
  //   grid-template-columns: max-content auto max-content;
  //   align-items: center;
  //   // justify-content: space-between;
  //   gap: 130px;
  //   .logo-container {
  //     @media (max-width: 799px) {
  //       display: flex;
  //       align-items: center;
  //     }
  //     .MenuTopBar-button {
  //       margin-right: 15px;
  //     }
  //   }
  // }
}

.delete-dialogbox {
  .restriction-title {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 31.36px;
    font-family: $regulerFont;
    line-height: 43.9px;
    color: $color-Black;

    @media (max-width: 639px) {
      font-size: 22.4px;
      line-height: 27px;
      margin-bottom: 15px;
    }
  }

  .restriction-text {
    font-size: 22.4px;
    line-height: 31.36px;
    font-weight: 400;
    margin-bottom: 33px;

    @media (max-width: 639px) {
      font-size: 16px;
      line-height: 22.4px;
      margin-bottom: 30px;
    }
  }

  .login-button {
    max-width: 597px;
    width: 100%;
    background-color: #4455c7;
    color: #ffffff;
    border-radius: 8px;
    padding: 12px 0px;
    max-height: 45px;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 15px;

    &:hover {
      background-color: #4455c7;
    }

    @media (max-width: 639px) {
      font-size: 15px;
      line-height: 19px;
    }
  }

  .dont-account-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #989898;

    @media (max-width: 639px) {
      font-size: 11.42px;
      line-height: 20px;
    }
  }

  .signup-text {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $color-Accent-1;
    text-decoration: underline;

    @media (max-width: 639px) {
      font-size: 11.42px;
      line-height: 20px;
    }
  }
}

.auth-main-wrap {
  .top-bar {
    position: initial;
  }

  .main-menu-wrap {
    // padding: 9px 40px 10px 21px;
    padding: 0px !important;
    top: 0px;
    max-width: none;

    .header-wrap {
      padding: 9px 12px 9px 12px;
    }
  }
}

.mobileMenuDrawer {
  .MuiPaper-root {
    background: #003764;
    color: #fff;
    max-width: 390px;

    a {
      color: #fff;
    }
  }

  .mobileMenuDrawerClose {
    margin: 8px 10px;
    // V3 header menu drawer
    // text-align: end;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    row-gap: 15px;

    @media (max-width: 350px) {
      flex-direction: column;
    }

    .logo-container {
      display: flex;

      .header-logo {
        margin-left: 15px;
      }
    }

    .signup-login-wrap {
      margin-left: 15px;
      width: 100%;

      @media (max-width: 350px) {
        margin-left: 0px;
      }

      .auth-btn {
        padding: 12px 24px;
        font-size: 12px;
        text-transform: capitalize;
        color: $color-White;
        line-height: 15px;
        border-radius: 8px;

        @media (max-width: 350px) {
          width: 100%;
        }
      }

      .signup {
        background: $color-Accent-1;
        margin-right: 5px;

        @media (max-width: 350px) {
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }

      .signin {
        outline: 1px solid $color-White;
      }
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiCollapse-entered {
    padding-left: 8px;
  }

  .second-submenu-name {
    .MuiListItemText-primary {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .second-menu-collapse {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 991px) {
  body {
    .Main-Wrap {
      .layout-wrapper {
        grid-template-columns: 228px auto;

        #right-sidebar {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 799px) {
  body {
    .Main-Wrap {
      .layout-wrapper {
        grid-template-columns: auto;

        #left-sidebar {
          display: none;
        }
      }

      .layout-wrapper.full-layout {
        grid-template-columns: auto;
      }
    }
  }

  .MenuTopBar-button {
    display: block;
  }

  .profile-icon {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 799px) {
  .header-wrap {
    justify-content: space-around;
  }

  .search_input {
    margin-left: 30px !important;
  }
}

// @media (max-width: 800px) {
//   .Main-Wrap {
//     .main-menu-wrap {
//       position: inherit;
//     }
//   }
// }
@media (max-width: 599px) {
  .Main-Wrap {
    .main-menu-wrap {
      padding: 12px 12px;
      // top: 46px;
      box-shadow: none;
      border-bottom: none;

      .login-buttons {
        justify-content: flex-end;
        width: 100%;

        a {
          width: 48%;

          .auth-btn {
            font-size: 11.42px;
            line-height: 14px;
          }
        }
      }

      .search-wrap {
        .search_input {
          margin-left: 0px !important;
        }

        .hide-sx {
          display: none;
        }
      }
    }
  }

  .auth-main-wrap {
    height: auto;

    .main-menu-wrap {
      position: initial;
    }
  }
}

@media (max-width: 479px) {
  .Main-Wrap {
    .main-menu-wrap {
      .login-buttons {
        .auth-btn {
          font-size: 11.42px;
          line-height: 14px;
        }

        .signin,
        .signup {
          padding: 6px 12px;
        }
      }
    }
  }
}

.bet-slip-wrap {
  height: calc(100vh - 210px);
  position: relative;

  // @media (max-width: 1279px) {
  //   height: calc(100vh - 250px);
  // }
  @media (max-width: 799px) {
    height: 100vh;
    width: 228px;
    margin-left: auto;
    background-color: #e6eefa;
  }

  .bet-slip-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to top, #003764 0%, #090b0d 100%);
    padding: 11px 9px 10px;
    cursor: pointer;

    .bet-slip-text {
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-White;
      display: flex;

      svg {
        @media (max-width: 799px) {
          transform: rotate(270deg);
        }
      }
    }
  }

  .bet-slip-data {
    padding: 10px;
    height: calc(100vh - 415px);
    overflow-y: scroll;

    @media (max-width: 799px) {
      height: calc(100vh - 205px);
    }

    .bet-slip-single-data {
      background-color: $color-White;
      border-radius: 8px;
      padding: 9px 6px;
      box-shadow: 0px 1px 9px 0px #0000000d;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .bet-slip-header-details {
        display: flex;
        margin-bottom: 9px;
        justify-content: space-between;

        .bet-slip-details {
          display: flex;

          .sport-img {
            width: 22px;
            height: 22px;
            object-fit: contain;
            margin-right: 3px;

            svg {
              width: 100%;
              height: 100%;
            }

            // img {
            //   height: 100%;
            //   width: 100%;
            // }
          }

          .text-details {
            max-width: 134px;

            .bet-slip-text {
              font-size: 11.42px;
              line-height: 14px;
              font-family: $regulerFont;
              font-weight: 400;
              color: #5d5d5d;
            }

            .dark-text {
              color: $color-Black;
              font-weight: 600;
            }

            .mb-4 {
              margin-bottom: 4px;
            }
          }
        }

        .delete-box {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background-color: #ccd7e0;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .stake-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        .fixed-select {
          max-width: 97px;
          width: 100%;
        }

        .select__control {
          background-color: $color-Light-grey;
          border-radius: 8px;
          max-height: 36px;
          max-width: 400px;

          @media (max-width: 479px) {
            max-width: none;
          }

          .select__value-container {
            padding: 0px;
          }

          .select__single-value {
            font-family: $regulerFont !important;
            font-size: 11.42px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
          }

          .select__placeholder {
            font-family: $regulerFont !important;
            font-size: 11.42px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 6px;
          }

          .select__input-container {
            font-family: $regulerFont !important;
            font-size: 11.42px;
            line-height: 22.4px;
            font-weight: 600;
            color: $color-Black;
            padding: 0px 8px;
          }

          .select__indicator-separator {
            width: 0px;
          }

          .select__dropdown-indicator {
            padding: 6px;
          }
        }

        .select__menu {
          margin: 0px;
          border-radius: 0px;
          padding: 0px;
          z-index: 999;
        }

        .select__menu-list {
          padding: 0px;
        }

        // Select Menu
        .select__menu,
        .react-select__menu {
          .select__menu-list,
          .react-select__menu-list {
            .select__option,
            .react-select__option {
              cursor: pointer;
              font-size: 11.42px;
              color: $color-Black;
              font-family: $regulerFont;
              line-height: 14px;
              padding: 11px;

              &.select__option--is-focused {
                background-color: #d4d6d8;
                color: #000;
              }

              &.select__option--is-selected {
                background-color: #d4d6d8;
                color: $color-Black;
              }
            }
          }

          .select__menu-list,
          .react-select__menu-list {
            .select__group {
              .select__group-heading {
                margin-bottom: 0.5rem;
                color: green;
                font-weight: bolder;
                font-size: inherit;
              }
            }
          }
        }

        .select__menu-portal {
          z-index: 99;
        }

        .stake-field {
          display: flex;
          align-items: center;

          .stake {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 500;
            font-family: $regulerFont;
            color: $color-Black;
          }

          .stake-input-field {
            width: 60px;

            .MuiInput-root {
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 500;
              font-family: $regulerFont;
              color: $color-Black;

              .MuiInputBase-input {
                padding: 5px 0px;
              }
            }

            .MuiInputAdornment-root {
              p {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 500;
                font-family: $regulerFont;
                color: $color-Black;
              }
            }

            .MuiInput-underline:before {
              border-bottom: 1px solid #e8eaec;
            }

            .MuiInput-underline:after {
              border-bottom: 1px solid #e8eaec;
            }
          }
        }

        .teamsport-stake-field {
          .stake-input-field {
            width: 134px;
          }
        }
      }

      .teamsports-stake-details {
        justify-content: center;
      }

      .odds-returns-wrap {
        margin-bottom: 9px;

        .odds-returns-top {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .odds-returns-text {
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 500;
            font-family: $regulerFont;
            color: $color-Primary;
          }

          .odds-wrap {
            width: 99px;
            text-align: center;
          }

          .returns-wrap {
            width: 99px;
          }
        }

        .odds-returns-bottom {
          border-radius: 8px;
          border: 1px solid #d6d9f3;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-size: 11.42px;
            line-height: 16px;
            font-weight: 600;
            font-family: $regulerFont;
            color: #000000;
            word-wrap: break-word;
          }

          .betslip-odds {
            margin-left: 4px;
          }

          .left-wrap {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 90px;
            padding: 9px;
            border-right: 1px solid #d6d9f3;
          }

          .right-wrap {
            width: 90px;
            text-align: center;
            padding: 9px;
          }
        }
      }

      .bet-now-btn {
        text-align: end;

        .button-bet-now {
          padding: 10px 24px 9px;
          background-color: $color-Accent-1;
          color: $color-White;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          border-radius: 8px;
          text-transform: capitalize;

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }
  }

  .bet-slip-empty {
    background-color: #e6eefa;
    padding: 0px 18px 0px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .bet-slip-empty-header {
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Black;
    }

    .empty-text {
      color: #5d5d5d;
    }
  }

  .betslip-total {
    position: absolute;
    bottom: 0px;
    width: 100%;

    .share-betslip {
      .share-deatils {
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 500;
        font-family: $regulerFont;
        color: $color-Accent-1;
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 0px 9px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .betslip-total-deatils {
      padding: 12px 9px;
      background: linear-gradient(to top, #003764 0%, #090b0d 100%);
      margin-top: 9px;

      .betslip-details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .details-text {
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 500;
          font-family: $regulerFont;
          color: $color-White;
        }
      }

      .clear-btn {
        margin-top: 17px;

        .clear-bet-btn {
          padding: 10px 0px 9px;
          width: 100%;
          background-color: $color-Accent-1;
          color: $color-White;
          font-size: 11.42px;
          line-height: 14px;
          font-weight: 400;
          font-family: $regulerFont;
          border-radius: 8px;
          text-transform: capitalize;

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }

      .mb-4 {
        margin-bottom: 4px;
      }
    }
  }
}

// .bet-slip-wrap-home {
//   background-color: #dbdbdb;
//   padding-bottom: 18px;
//   opacity: 0;
//   transform: translateY(-100%);
//   transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;

//   .bet-slip-empty {
//     min-height: 260px;
//     height: auto;
//   }

//   .bet-slip-data {
//     background-color: #e6eefa;
//   }

// }

// .bet-slip-wrap-home.show {

//   opacity: 1;
//   transform: translateY(0);
//   // padding-bottom: 0px;

//   // .bet-slip-empty {
//   //   height: 100vh;
//   //   min-height: auto;
//   // }
// }

.betslip-collapse {
  position: absolute;
  top: 72px;
  right: 0px;
  // height: calc(100vh - 202px);
  background-color: #e6eefa;
  width: 228px;
  z-index: 99;

  @media (max-width: 1279px) {
    top: 116px;
  }

  @media (max-width: 799px) {
    background-color: #00000063;
    width: 100%;
    top: -130px;
  }
}

.auth-container {
  background-image: url("./.././assets/images/signup-img/auth-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.header-menu-container {
  @media (max-width: 1279px) {
    order: 3;
  }

  @media (max-width: 799px) {
    display: none;
  }
}
.profile-collapse-wrap {
  background: #ffffff;
  position: absolute;
  right: 0px;
  color: $color-Primary;
  width: 185px;
  height: 190px !important;
  border-radius: 8px;
  box-shadow: 1px 3px 9px 0px #00000033;
  margin: 0px !important;
  top: 15px !important;
  max-width: none !important;

  .profile-section-list-wrap {
    padding: 9px 9px;
    border-bottom: 1px solid #e7e9ec;
  }

  .profile-list-menu-list {
    padding: 9px;
    cursor: pointer;

    &:hover {
      background-color: #e5eaef;
      border-radius: 6px;
    }

    .profile-list-menu-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Primary;
      letter-spacing: 0px;
    }

    .mb-9 {
      margin-bottom: 9px;
    }
  }

  .p-w-100 {
    width: calc(100% - 18px);
  }

  .profile-logout-wrap {
    padding: 9px;
  }
}
