@import "../../../assets/scss/variable.scss";

.allsport-tips-wrap {
  .sport-racing-tips {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 539px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .racing-tab {
      .tips-mainheader {
        font-size: 22.4px;
        line-height: 28px;
        font-family: $primaryFont;
        margin-right: 18px;

        @media (max-width: 799px) {
          margin-right: 0px;
        }
      }
    }

    .race-track-blackbook {
      border-bottom: none;
    }

    .blackbook-button-wrap {
      margin-top: 0px;
    }
  }

  .tips-details-wrap {
    padding: 18px 33px;
    background-color: #fbfbfb;

    @media (max-width: 799px) {
      padding: 18px 12px;
    }

    .tips-heading {
      font-size: 22.4px;
      line-height: 22.4px;
      font-weight: 700;
      font-family: $regulerFont;
      margin-bottom: 9px;
      color: $color-Black;
      letter-spacing: 0px;

      @media (max-width: 799px) {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 7px;
      }
    }

    .align-right {
      margin-left: auto;
      background: none !important;
    }

    .view-more-tips-heading {
      border-bottom: 1px solid #d4d6d8;
      padding-bottom: 12px;
      margin-bottom: 0px;
    }

    .tips-para {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      margin-bottom: 12px;
      padding-bottom: 12px;
      color: $color-Accent-1;
      border-bottom: 1px solid #d4d6d8;
      letter-spacing: 0px;

      @media (max-width: 799px) {
        padding-bottom: 7px;
        margin-bottom: 10px;
      }
    }

    .tips-comments {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Black;
      letter-spacing: 0px;

      .bold {
        font-weight: 700;
      }
    }

    .mb-18 {
      margin-bottom: 18px;

      @media (max-width: 799px) {
        margin-bottom: 15px;
      }
    }

    .analyst-price-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d4d6d8;
      padding: 20px 0px 12px;

      @media (max-width: 799px) {
        padding: 11px 0px 9px;
      }

      .price-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;
      }

      .analyst-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Accent-1;
        letter-spacing: 0px;
        text-transform: capitalize;

        .analyst-bold {
          color: $color-Black;
        }
      }
    }

    .odds-value-wrap {
      text-align: center;
      padding: 16px 0px 12px;
      // background-color: rgba(212, 214, 216, 0.13);
      // height: 62px;
      @media (max-width: 1280px) {
        padding: 0;
      }

      .tip-odds-detail {
        height: 19px;
      }

      .text-accent-1 {
        color: $color-Accent-1 !important;
        font-weight: 600;
      }

      .txt-white {
        color: $color-White !important;
      }

      .odds-value {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Accent-1;

        .no-odds {
          padding: 6px 12px;
          background-color: #989898;
          color: #ffffff;
          border-radius: 14px;
          font-size: 12px;
          cursor: pointer;
        }
      }

      .current-best-odds-value {
        background-color: $color-Lavender;
        padding: 9px 0px;
        width: 80px;
        margin: 0px auto;
        border-radius: 6px 6px 0px 0px;
      }

      .active-odd-style {
        background-color: $color-Primary !important;
      }
      .current-best-odds-icon {
        height: 25px;
        border-radius: 0px 0px 6px 6px;
        // background-color: olivedrab;
        width: 80px;
        margin: 0px auto;
        .bookmaker-thumb {
          margin: 0px auto;
          // max-width: 33px;
          border-radius: 0px 0px 6px 6px;
          cursor: pointer;
          height: 25px;
          object-fit: cover;
          width: 100%;
          max-width: none;
        }
      }

      .mb-10 {
        margin-bottom: 10px;

        @media (max-width: 799px) {
          margin-bottom: 5px;
        }
      }

      .bookmaker-thumb {
        // max-width: 33px;
        // width: 80px !important;
        // border-bottom-left-radius: 8px;
        // border-bottom-right-radius: 8px;
        // border-radius: 6px;
        // cursor: pointer;
        // height: 25px;
        // object-fit: fill;
      }
    }

    .race-odds-value-wrap {
      display: flex;
      align-items: center;
      column-gap: 12px;
      padding: 0px;
    }

    .tips-runner-details-wrap {
      margin-top: 12px;
      display: flex;
      align-items: start;
      justify-content: space-between;

      @media (max-width: 1280px) {
        flex-direction: column;
        row-gap: 18px;
      }

      .left-runner-details {
        width: 50%;

        @media (max-width: 1280px) {
          width: 100%;
        }

        .runner-name-wrap {
          display: flex;
          align-items: center;
          margin-bottom: 27px;

          @media (max-width: 1280px) {
            margin-bottom: 7px;
          }

          .runner-name {
            font-size: 16px;
            line-height: 19px;
            font-family: $regulerFont;
            font-weight: 600;
            color: #003764;
            border-bottom: 1px solid #003764;
          }

          .blackbook-icon {
            margin-left: 11.6px;
            cursor: pointer;
          }

          .tooltip {
            @media (max-width: 799px) {
              left: 85px;
            }
          }

          .add-blackbook {
            padding: 6px 10px 6px 8px;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 600;
            color: #003764;
            font-family: $regulerFont;
            border-bottom: 1px solid #e7e9ec;
            display: flex;
            align-items: center;
          }

          .black-details {
            padding-top: 3px;

            .details {
              padding: 6px 10px;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              color: $color-Black;

              &:hover {
                background-color: #d4d6d8;
              }
            }
          }
        }

        .runner-info {
          display: flex;
          align-items: center;
          column-gap: 36px;

          // @media (max-width: 799px) {
          //   justify-content: space-between;
          // }

          .runner-info-details {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: $regulerFont;
            letter-spacing: 0px;
            @media (max-width: 500px) {
              font-size: 11.42px;
              line-height: 16px;
            }
            .participant-name {
              font-weight: 400;
            }
          }

          .light {
            color: #000000;
          }

          .dark {
            color: $color-Primary;
          }
        }
      }

      .right-expert-container {
        width: 50%;
        @media (max-width: 1280px) {
          width: 100%;
        }
        .right-odds-details {
          display: flex;
          justify-content: end;
          align-items: baseline;

          @media (max-width: 1280px) {
            width: 100%;
            flex-direction: column;
            row-gap: 5px;
          }

          .best-current-wrap {
            // display: flex;
            // align-items: center;
            height: 135px;
            border: 1px solid $color-Light-grey-4;

            @media (max-width: 1280px) {
              width: 100%;
            }
          }

          .odds-wrap {
            min-width: 140px;
            width: 100%;
            margin-right: 3px;

            @media (max-width: 1280px) {
              min-width: auto;
              width: 50%;

              &:not(:last-child) {
                margin-right: 3px;
              }
            }
          }

          .odds-fluctuation {
            min-width: 310px;
            width: 100%;
            margin-right: 0px;
            height: 135px;
            border: 1px solid $color-Light-grey-4;

            @media (max-width: 1280px) {
              min-width: auto;
              width: 100%;
            }

            .no-data-tipsGarph {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-Black;
              text-align: center;
              padding: 20px 0px 0px;
            }
          }

          .odds-header {
            text-align: center;
            background-color: #e2e4f1;
            padding: 6px 9px;

            .header-name {
              font-size: 16px;
              line-height: 19px;
              font-weight: 600;
              font-family: $regulerFont;
              color: $color-Accent-1;
              text-transform: uppercase;
              letter-spacing: 0px;
            }
          }

          .odds-header-dark {
            background-color: $color-Accent-1;

            .header-name {
              color: $color-White;
            }
          }

          .odds-value-wrap-dark {
            background-color: #eeefff;
          }
        }

        .sporsor-odds-box {
          margin-top: 6px;
          background: #f2f2f2;

          .sponsor-odds-header {
            text-align: center;
            background-color: $color-Primary;
            padding: 6px 9px;

            .header-name {
              font-size: 16px;
              line-height: 19px;
              font-weight: 600;
              font-family: $regulerFont;
              color: $color-Lavender;
              text-transform: uppercase;
              letter-spacing: 0px;
            }
          }

          .hide-scroll::-webkit-scrollbar {
            display: none;
          }
          .hide-scroll {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .max-w {
            max-width: 435px;
            width: 100%;
            margin: 0 auto;
            overflow-x: auto;
            overflow-y: hidden;
            padding-top: 9px;
            padding-bottom: 9px;
            overflow-x: auto;
            overflow-y: hidden;

            @media (max-width: 660px) {
              padding: 15px 0px;
              max-width: 354px;
            }

            .odds-flex {
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              column-gap: 15px;

              @media (max-width: 660px) {
                column-gap: 6px;
              }

              .odds-box-style {
                text-align: center;
                height: 64px;

                .odds-style {
                  // max-width: 60px;
                  cursor: pointer;
                  max-width: none;
                  width: 60px;
                  text-align: center;
                  padding: 6.5px 0px;
                  border-radius: 6px;
                  margin: 0px auto;
                  background-color: $color-Light-grey;
                  box-shadow: 0px 2px 1px 0px #707070;

                  @media (max-width: 660px) {
                    padding: 6.5px 0px;
                    // max-width: 54px;
                    width: 54px;
                  }
                  @media (max-width: 389px) {
                    width: 48px;
                  }

                  .odds {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    color: $color-Black;
                    letter-spacing: 0px;
                    @media (max-width: 660px) {
                      font-size: 11.42px;
                      line-height: 14px;
                    }
                  }
                }

                .odd-img {
                  // max-width: 60px;
                  width: 60px;
                  height: 25px;
                  margin: 0 auto;
                  margin-top: 6px;
                  @media (max-width: 660px) {
                    // max-width: 54px;
                    width: 54px;
                  }
                  @media (max-width: 389px) {
                    width: 48px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                    border-radius: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .tip-key-details-grid {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 22px;
      row-gap: 18px;

      @media (max-width: 999px) {
        grid-template-columns: auto;
      }

      .race-runner-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid #d4d6d8;

        &:not(:last-child) {
          margin-bottom: 12px;

          @media (max-width: 799px) {
            margin-bottom: 7px;
          }
        }

        @media (max-width: 999px) {
          padding-bottom: 7px;
        }

        .runner-name-wrap-grid {
          display: flex;
          align-items: center;
          column-gap: 11px;

          .runner-name {
            font-size: 16px;
            line-height: 19px;
            font-family: $regulerFont;
            font-weight: 600;
            color: #003764;
            border-bottom: 1px solid #003764;
          }

          .is-scratched {
            text-decoration: line-through;
            border-bottom: none;
            color: #979797;
          }

          .blackbook-icon {
            cursor: pointer;
          }

          .add-blackbook {
            padding: 6px 10px 6px 8px;
            font-size: 11.42px;
            line-height: 14px;
            font-weight: 600;
            color: #003764;
            font-family: $regulerFont;
            border-bottom: 1px solid #e7e9ec;
            display: flex;
            align-items: center;
          }

          .black-details {
            padding-top: 3px;

            .details {
              padding: 6px 10px;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;
              color: $color-Black;

              &:hover {
                background-color: #d4d6d8;
              }
            }
          }
        }

        .odds-value-wrap {
          background-color: transparent;
        }
      }
    }

    .tips-key-details-wrap {
      padding: 27px;
      border-radius: 8px;
      background-color: $color-White;
      border: 1px solid #e8eaec;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 799px) {
        padding: 12px;
      }
    }
    .odds-key-details {
      @media (max-width: 799px) {
        padding: 0px;

        .tips-heading {
          padding: 12px 12px 0px;
        }
        .tips-para {
          margin-left: 12px;
          margin-right: 12px;
        }
        .tips-comments {
          padding: 0px 12px;
        }
        .analyst-price-wrap {
          padding: 11px 12px 9px;
        }
        .tips-runner-details-wrap {
          .left-runner-details {
            padding: 0px 12px;
            justify-content: space-between;
            width: calc(100% - 24px);
          }
        }
      }
    }

    .view-more-tips-wrap {
      border-bottom: 1px solid #d4d6d8;
      padding: 12px 0px;

      .view-tips-para {
        margin-bottom: 13px;
        padding-bottom: 0px;
        border-bottom: none;
      }

      .view-tips-comments {
        @media (max-width: 799px) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
      }

      .view-tips-button {
        text-align: end;
        margin-top: 6px;

        .view-tips {
          font-size: 14px;
          line-height: 22.4px;
          font-family: $regulerFont;
          font-weight: 500;
          color: $color-Accent-1;
          border-bottom: 1px solid $color-Accent-1;
          display: inline;
          cursor: pointer;
        }
      }
    }
  }

  .get-tips-Pagination {
    display: flex;
    justify-content: center;
    padding: 50px 0px 0px;

    @media (max-width: 799px) {
      padding: 33px 0px 0px;
    }

    ul {
      li {
        margin-right: 4px;

        @media (max-width: 379px) {
          margin-right: 0px;
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          width: 36px;
          height: 36px;
          border-radius: 50%;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }

          svg {
            width: 36px;
            height: 36px;
            font-size: 18px;

            @media (max-width: 799px) {
              font-size: 14px;
              width: 24px;
              height: 24px;
            }

            @media (max-width: 379px) {
              font-size: 11px;
              width: 22px;
              height: 22px;
            }
          }
        }

        .Mui-selected {
          background-color: #d4d6d8;
          width: 36px;
          height: 36px;

          @media (max-width: 799px) {
            font-size: 14px;
            width: 24px;
            height: 24px;
          }

          @media (max-width: 379px) {
            font-size: 11px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}

.tooltip {
  .tooltip-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
