@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

// Fonts

$regulerFont: "Inter", sans-serif;
$primaryFont: "VeneerClean-Soft" !important;
$secondaryRegularFont: "SF-PRO-Regular";
$secondarySemiboldFont: "SF-PRO-Semibold";
$arialFont: "Arial";
$peckhamPressTrial: "PeckhamPress-Trial";
$theStamshonsDemo: "TheStamshons-Demo";

// Colors
$color-Primary: #003764;
$color-Secondary: #fc4714;
$color-Accent-1: #4455c7;
$color-Accent-2: #ffc69a;
$color-Affirmative: #1c9a6c;
$color-attention: #e2662c;
$color-Negative: #d84727;
$color-Black: #191919;
$color-Black-1: #000000;
$color-grey: #d4d6d8;
$color-Light-grey: #e7e9ec;
$color-Light-grey-1: #e8eaec;
$color-Light-grey-2: #d5d5d5;
$color-Light-grey-3: #a4a4a4;
$color-Light-grey-4: #e5eaef;
$color-White: #ffffff;
$color-off-White: #fbfbfb;
$color-gray-dark: #5d5d5d;
$color-gray-dark-1: #c9c9c9;
$color-gray-dark-2: #707070;
$color-gray-dark-3: #595959;
$color-gray-dark-4: #47494d;
$color-gray-dark-5: #dededf;
$pop-up-background: #f5f5f5;
$color-highlight: #eeefff;
$color-grey-Accent-1: #989898;
$color-dark-Blue: #01233f;
$color-Lavender: #d6d9f3;
$color-Lavender-1: #e6eefa;

// Border color
$field-border: #25282c;
$blockBorder: 1px solid #2e353a;

// Border Radius
$bRadius: 3px;

// scroll background color
$bg-scroll: #353535;

// linear-gradient
$bg-linear: linear-gradient(
  90deg,
  rgba(68, 85, 199, 1) 0%,
  rgba(0, 55, 100, 1) 68%
);
