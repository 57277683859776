@import "../../../../assets/scss/variable.scss";

.header-contain-wrap {

    .main-menu-wrap {
        background-color: rgb(255, 255, 255);
        padding: 0px 0px 0px 21px;
        border-bottom: 1px solid #edebeb;
        width: 100%;
        top: 46px;
        box-shadow: 0px 1px 5px 0px #00000029;


        .header-left-menu-wrap {
            display: flex;
            align-items: center;
            column-gap: 18px;

            .MenuTopBar-button {
                cursor: pointer;
            }
        }

        .login-buttons {
            padding: 0px !important;
            display: flex;
            justify-content: end;

            .signup-login-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                @media (max-width: 599px) {
                    justify-content: space-between;
                }
            }

            .profile-logout-wrap {
                display: flex;
                justify-content: center;
                // justify-content: flex-end;
                align-items: center;
                flex-direction: column;

                .profile-img-wrap {
                    width: 36px;
                    height: 36px;
                    border: 1px solid #fc4714;
                    border-radius: 50%;
                    cursor: pointer;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        border-radius: 50%;
                    }
                }

                .user-name {
                    line-height: 14px;
                    font-size: 11.42px;
                    // margin-left: 9px;
                    color: #4455c7;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }

            .auth-btn {
                font-size: 16px;
                font-weight: normal;
                line-height: 19px;
                border: 1px solid #4455c7;
                border-radius: 8px;
                letter-spacing: 0px;
            }

            .signup {
                background: #4455c7;
                color: #fff;
                margin-right: 9px;
                padding: 12px 21px 11px 21px;

                @media (max-width: 799px) {
                    width: max-content;
                    padding: 6px 12px;
                }
            }

            .signup:hover {
                background: #4455c7 !important;
            }

            .signin {
                background: #fff;
                color: #4455c7;
                padding: 12px 19.5px 11px 19.5px;

                @media (max-width: 799px) {
                    width: max-content;
                    padding: 6px 12px;
                }
            }

            .signin:hover {
                background: #fff !important;
            }

            .profile-icon {
                background: #4455c7 !important;
                // margin-left: 9px;
                border: 1px solid #fc4714;
                padding: 5px;
            }

            .logout {
                margin-right: 0px;
                margin-left: 9px;
            }

            .bet-slip-button {
                background-color: $color-Accent-1;
                height: 64px;
                display: flex;
                align-items: center;
                padding: 0px 12px;
                cursor: pointer;
                margin-left: 18px;

                @media (max-width: 600px) {
                    flex-direction: column;
                    justify-content: center;
                    padding: 0px 6px;
                    min-width: fit-content;
                    height: 65px;
                }

                .bet-slip {
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    color: $color-White;

                    @media (max-width: 799px) {
                        font-size: 11.42px;
                        line-height: 14px;
                    }
                }

                .bet-slip-count {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: $color-White;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 600;
                    font-family: $regulerFont;
                    color: $color-Accent-1;
                    text-align: center;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 799px) {
                        width: 18px;
                        height: 18px;
                        font-size: 11.42px;
                        line-height: 14px;
                    }

                    @media (max-width: 600px) {
                        margin-left: 0px;
                    }
                }
            }
        }
    }

    .Menu-collapse {
        width: 228px;
        position: absolute;
        z-index: 9999;
        left: 21px;
        background-color: $color-Primary;
        color: $color-White;
        top: 53px;

        .left-sidebarcontent {
            .menu-wrap {
                padding: 24px 19px 58px;
            }
        }
    }

    .betslip-collapse {
        top: 64px;

        @media (max-width:799px) {
            top: 0px;
        }
    }
}