@import "../../../assets/scss/variable.scss";

.Footer {
  position: relative;

  .MuiGrid-root {
    padding: 0px;
  }

  .footer-wrap {
    @media (max-width: 767px) {
      margin: 0px -12px;
    }
  }

  .footer-content {
    background-color: $color-Primary;
    padding: 30px 12px 21px 57px;
    border-bottom: 1px solid $color-White;
    @media (max-width: 767px) {
      padding: 23px 12px 15px;
    }
    .footer-links-wrap {
      padding-top: 27px;
      @media (max-width: 767px) {
        padding-top: 23px;
      }
    }
    // &:after {
    //   content: "";
    //   width: 100%;
    //   height: 1px;
    //   position: absolute;
    //   background: #ffffff;
    //   left: 0px;
    //   margin-top: 7px;
    // }

    h4 {
      text-align: left;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      // margin: 28px 0px 12px 0px;
      margin: 0px 0px 12px 0px;
      @media (max-width: 767px) {
        font-size: 11.42px;
        font-weight: 600;
        line-height: 14px;
        margin: 0px 0px 6px 0px;
      }
    }

    .app-logo {
      @media (max-width: 1279px) {
        display: flex;
        align-items: start;
        flex-direction: column;
      }
    }

    .link-wrap {
      a {
        display: block;
        color: #ffffff;
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        text-align: left;
        margin-bottom: 18px;
        font-weight: 500;
        @media (max-width: 767px) {
          font-size: 11.42px;
          font-weight: 600;
          line-height: 14px;
          margin-bottom: 9px;
        }
      }
      .active {
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .mobile-policy {
      padding: 23px 0px;
      @media (max-width: 479px) {
        padding: 15px 0px;
      }
    }

    .playstore-logo {
      @media (max-width: 1279px) {
        margin-right: 27.5px;
      }

      @media (max-width: 400px) {
        margin-right: 10px;
      }
    }

    .contact {
      display: flex;

      svg {
        width: 61px;
        max-width: 34px;
      }

      span {
        text-align: left;
        font-size: 16px;
        line-height: 22.4px;
        color: #ffffff;
        font-weight: normal;
        margin-bottom: 10px;
        padding-left: 9px;
      }

      a {
        color: #ffffff;
      }
    }

    .playstore-img {
      width: 145px;
      height: 43px;
    }
    .social-icon-wrap {
      width: 60%;
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 10px;
      row-gap: 12px;
      justify-content: space-around;
      // padding: 10px 0px;
      @media only screen and (max-width: 1279px) {
        width: 30%;
      }
      @media only screen and (max-width: 767px) {
        width: 60%;
      }

      svg {
        cursor: pointer;

        g {
          circle {
            fill: #ffffff;
          }

          path {
            fill: #003764;
          }
        }
      }

      .twitter {
        cursor: pointer;

        svg {
          g {
            g {
              path {
                width: 30px;
                fill: #ffffff;
              }
            }
          }
        }
      }

      .tiktok {
        width: 30px;
        height: 30px;
        background: #ffffff;
        border-radius: 50%;

        img {
          border-radius: 50%;
          width: 100%;
        }
      }

      .youtube {
        text-align: center;

        svg {
          width: 28px;
          height: 28px;
          margin-top: 0.7px;

          path {
            fill: #003764;
          }
        }
      }

      .snapChat {
        svg {
          border-radius: 50%;
        }
      }

      .linkedin {
        svg {
          width: 30px;
          height: 30px;
          margin-top: 0px;

          path {
            fill: #ffffff;
          }

          #linkdinId {
            fill: #003764;
          }
        }
      }

      .sidebar-icon {
        width: 30px;
        height: 30px;
      }
    }
    .social-media-wrap {
      padding-top: 32px;
      @media (max-width: 767px) {
        padding-top: 0px;
      }

      p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;

        @media only screen and (max-width: 799px) {
          padding: 8px 16px;
        }
      }
    }
  }

  p.copyright {
    background-color: $color-Primary;
    margin: 0px;
    text-align: center;
    font-size: 11.42px;
    color: #ffff;
    line-height: 14px;
    padding: 18px 0px 15px 0px;
  }
}

.upper-footer {
  background-color: $color-White;
  text-align: center;
  padding: 18px 0px;
  .responsible-gambling-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 5px;
    .tag-line {
      font-size: 31.36px;
      line-height: 33px;
      font-weight: 700;
      font-family: $arialFont;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .responsible-gambling-text {
      color: $color-Black;
      font-size: 18px;
      line-height: 33px;
      font-family: $arialFont;
      margin-left: 5px;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 18px;
      }

      .responsible-gambling-helpline-no {
        color: $color-Accent-1;
        text-decoration: underline;
      }
    }

    .responsible-gambling-logo {
      svg {
        max-width: 200px;
        max-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .Footer {
    .footer-content {
      .contact {
        svg {
          width: 14px;
          max-width: 14px;
        }

        span {
          font-size: 11.42px;
          line-height: 16px;
        }
      }

      .responsible-gambling-wrap {
        flex-direction: column;
        row-gap: 5px;
        padding-top: 5px;
      }
    }
  }
}
