@import "../../../../../assets/scss/variable.scss";

.race-result-wrap {
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 45px;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0px;
  }

  .MuiAccordionSummary-content {
    margin: 0px;
  }

  .MuiAccordionDetails-root {
    display: block;
    padding: 0px;
  }

  .MuiIconButton-label {
    svg {
      fill: $color-White;
    }
  }

  .race-card-wrap {
    margin-top: 18px;
  }

  .result-tab {
    background-color: $color-White;
    margin: 18px 0px;
    padding: 0px 18px 11px;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 599px) {
      padding: 9px 12px 12px;
      margin-bottom: 18px;
    }

    .result-tab-detail {
      border-bottom: 2px solid #4455c7;

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .active {
        .MuiTab-wrapper {
          color: $color-Accent-1;
        }
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTab-wrapper {
        font-size: 16px;
        font-family: VeneerClean-Soft !important;
        color: #191919;
        line-height: 21px;
      }

      .MuiTab-root {
        min-width: 120px;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: #003764;
      }
    }
  }

  .final-race-result {
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;
    padding: 0px 18px 18px;
    margin-bottom: 30px;

    @media screen and(max-width:479px) {
      padding: 0px 12px 0px;
    }

    h4 {
      font-size: 22.4px;
      line-height: 31.36px;
      font-family: VeneerClean-Soft !important;
      color: $color-Black;
      padding: 18px 0px 13px;

      @media screen and(max-width:799px) {
        padding: 4px 0px 0px;
        border-bottom: 1px solid $color-Accent-1;
      }
    }

    .final-result-time {
      display: flex;
      justify-content: space-between;
      column-gap: 18px;
      margin-top: 18px;
      margin-bottom: 18px;

      @media (max-width: 899px) {
        flex-direction: column;
        row-gap: 10px;
      }

      .series-select {
        margin-top: 0px;

        @media (max-width: 899px) {
          max-width: none;

          .select__control {
            max-width: 100%;
          }
        }
      }

      .state-tab-details {
        padding: 0px;
        min-height: 38px;

        @media (max-width: 799px) {
          padding: 9px 0px;
          min-height: 32px;
        }

        // .MuiTabScrollButton-root {
        //   // border: 2px solid $color-Primary;
        // }

        .MuiTab-textColorInherit {
          color: $color-Black;
          border: 2px solid $color-Primary;
          margin: 0px 12px;
          border-radius: 8px;
          opacity: 1;
          min-height: 38px;
          padding: 9px 10px;

          @media (max-width: 799px) {
            margin: 0 9px;
            padding: 6px 12px;
            min-height: 32px;
          }
        }

        .active {
          color: $color-White;
          background: $color-Primary;
        }

        .MuiTabs-indicator {
          background: none;
        }

        .MuiTab-textColorInherit:first-child {
          margin-left: 0px;
        }

        .MuiTab-root {
          min-width: initial;
          width: 106px;
        }

        .MuiTab-wrapper {
          font-size: 16px;
          line-height: 20px;
          font-family: $primaryFont;
        }

        .Mui-disabled {
          opacity: 1;
        }

        .MuiTabs-scroller {
          .MuiTabs-flexContainer {
            .MuiBox-root:last-child {
              button {
                margin: 0px;
              }
            }
          }
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }

    .race-result-time {
      color: $color-Black;
      font-weight: 400;
      padding: 9px 0px 18px;

      @media screen and(max-width:479px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .exotic-race-result-time {
      border-top: 1px solid $color-Accent-1;
    }

    .race-bookmakersodd {
      .racing-data {
        .semi-bold {
          font-weight: 600;
          font-family: $regulerFont;
        }

        .racing-track-table-head {
          .track-table-th.selectbox-wrap {
            font-weight: 400;
            padding: 0px;

            .select-wrap .select__control {
              min-height: initial;
              height: 32px;
            }
          }

          .track-tabel-odds-th {
            font-weight: 600;
            color: $color-Accent-1;
            padding: 0px;
            height: 32px;
          }

          .border-right-result {
            border-right: 2px solid $color-Light-grey;
          }

          .result-track-table-th {
            min-width: 380px;
          }
        }

        .MuiTableBody-root {
          // .racing-track-data-row {
          // }

          .racing-track-data-row {
            .result-racer-detail {
              .racer-detail-wrap {

                // display: flex;
                // column-gap: 18px;
                .rank-badge {
                  padding: 1px 7px;
                  font-size: 16px;
                  font-weight: 400;
                  border-radius: 7px;
                  color: $color-White;
                  display: inline-block;
                  margin-left: 12px;
                }

                .winner {
                  background-color: $color-Secondary;
                }

                .not-winner {
                  background-color: $color-gray-dark;
                }

                .rank-card {
                  width: 49px;
                  display: inline-block;
                  background-color: $color-White;
                  text-align: center;
                  line-height: 45px;
                  font-weight: 600;
                  filter: drop-shadow(-1px 3px 9px #00000029);
                  font-family: $regulerFont;
                }
              }

              .exotic-racer-detail-wrap {
                display: flex;
                column-gap: 18px;

                .rank-card-wrap {
                  display: flex;
                  flex-direction: column-reverse;
                  align-items: center;
                  row-gap: 6px;

                  .rank-badge {
                    margin-left: 0px;
                  }
                }
              }
            }

            .exotics-cell {
              text-align: left;
              padding-left: 18px;
            }

            &:nth-last-child(2) {
              .MuiTableCell-root {
                border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
              }
            }

            // &:last-child {
            //   .MuiTableCell-root {
            //     // border: none !important;
            //   }
            // }
          }

          .dividend-row {
            .odds-box-style {
              text-align: center;
              height: 64px;

              .odds-style {
                cursor: pointer;
                max-width: none;
                width: 60px;
                text-align: center;
                padding: 6.5px 0px;
                border-radius: 6px;
                margin: 0px auto;
                background-color: $color-Light-grey;
                box-shadow: 0px 2px 1px 0px #707070;

                @media (max-width: 660px) {
                  padding: 6.5px 0px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                .odds {
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;

                  @media (max-width: 660px) {
                    font-size: 11.42px;
                    line-height: 14px;
                  }
                }
              }

              .active-odd-style {
                background-color: $color-Primary;

                span,
                .odds {
                  color: $color-White;
                }
              }

              .odd-img {
                width: 60px;
                height: 25px;
                margin: 0 auto;
                margin-top: 6px;

                @media (max-width: 660px) {
                  // max-width: 54px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-race-result {
    .race-bookmakersodd {
      .racing-data {
        .border-right-result {
          border-right: 2px solid $color-Light-grey;
        }

        .racer-detail-wrap {
          display: flex;
        }

        .rank-badge {
          padding: 1px 7px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 7px;
          color: $color-White;
          display: inline-block;
          margin-left: 12px;
        }

        .winner {
          background-color: $color-Secondary;
        }

        .not-winner {
          background-color: $color-gray-dark;
        }

        .mobile-table-body {
          .MuiTableCell-root {
            padding: 9px 0px;
            border-right: 2px solid $color-Light-grey;
          }

          .MuiTableCell-root:last-child {
            border-right: none;
          }
        }

        .MuiBox-root:last-child {
          .mobile-table-body {
            .MuiTableCell-root {
              border-right: 2px solid $color-Light-grey;
            }
          }
        }

        .exotics-row {
          .rank-card-container {
            display: flex;
            align-items: center;
            padding: 10px 18px 18px;

            .rank-card-wrap {
              text-align: center;
              display: flex;
              flex-direction: column;
              row-gap: 6px;
              align-items: center;

              &:not(:last-child) {
                margin-right: 35px;
              }

              .rank-badge {
                margin-left: 0px;
              }

              .rank-card {
                width: 49px;
                background-color: $color-White;
                text-align: center;
                line-height: 45px;
                font-family: $regulerFont;
                font-weight: 600;
                filter: drop-shadow(-1px 3px 9px #00000029);
              }
            }
          }

          &:not(:first-child) {
            .rank-card-container {
              padding: 18px;
            }
          }

          .exotic-table-container {
            border-radius: 8px;
            border: 1px solid $color-Light-grey;
            margin-bottom: 15px;

            .mobile-min-width {
              min-width: 799px;
            }
          }
        }

        .race-result-table-container {
          @media only screen and (max-width: 799px) {
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(224, 224, 224, 1);
          }
        }
      }

      .exotic-racing-data {
        .mobile-data-head th {
          @media (max-width: 799px) {
            background-color: $color-Primary;
            color: $color-Lavender;
          }
        }

        .mobile-data-head th:not(:last-child) {
          border-right: 2px solid $color-Light-grey;
        }

        .mobile-data-head {
          .mobile-table-data {
            font-weight: 800;
          }
        }

        .mobile-table-body {
          .mobile-table-data {
            .odds-box-style {
              text-align: center;
              height: 64px;

              .odds-style {
                cursor: pointer;
                max-width: none;
                width: 60px;
                text-align: center;
                padding: 6.5px 0px;
                border-radius: 6px;
                margin: 0px auto;
                background-color: $color-Light-grey;
                box-shadow: 0px 2px 1px 0px #707070;

                @media (max-width: 660px) {
                  padding: 6.5px 0px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                .odds {
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;

                  @media (max-width: 660px) {
                    font-size: 11.42px;
                    line-height: 14px;
                  }
                }
              }

              .active-odd-style {
                background-color: $color-Primary;

                span,
                .odds {
                  color: $color-White;
                }
              }

              .odd-img {
                width: 60px;
                height: 25px;
                margin: 0 auto;
                margin-top: 6px;

                @media (max-width: 660px) {
                  // max-width: 54px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 8px;
                }
              }
            }
          }
        }
      }

      .racing-data>div:not(:last-child) {
        border-bottom: 1px solid $color-Light-grey;
      }
    }
  }

  // .exotics-race-result {
  //   padding: 0px 18px 18px 0px;
  // }

  .race-card-header {
    background: linear-gradient(90deg, $color-Accent-1 0%, $color-Primary 68%);

    h4 {
      font-size: 22.4px;
      line-height: 28px;
      font-family: VeneerClean-Soft !important;
      color: $color-White;
      padding: 9px 18px 8px;
    }
  }
}

// .custom-tooltip-class {
//   background-color: $color-White !important;
//   z-index: 9999;
//   border: 1px solid black;

//   .tooltip-blackBook {
//     z-index: 9999;
//     position: relative;

//     p {
//       color: #000;
//     }
//   }
// }
.custom-tooltip {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: $color-White;
  width: 227px;
  height: 116px;
  border: 1px solid #e7e9ec;
  padding: 4px 12px 6px;
  border-radius: 8px;
  z-index: 1;
  // top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}