@import "../../../assets/scss/variable.scss";

.d-flex {
  display: flex;
}

.text-align {
  align-items: center;
}

.content-space-betwen {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.mt-44 {
  margin-top: 44px;

  @media (max-width: 799px) {
    margin-top: 31px;
  }
}

.mobile-banner {
  display: block;
  //   display: none;

  //   @media (max-width: 799px) {
  //     display: block;
  //   }

  .banner {
    width: 100%;
  }
}

// subscriptionandpayment-data-header css

.subscriptionandpayment-data-header {
  padding: 44px 18px 0px;

  @media (max-width: 799px) {
    padding: 22px 12px 6px;
  }

  .bredcrumn-details {
    overflow-x: scroll;

    .bredcrumn-wrap {
      margin-bottom: 3px;
      min-width: max-content;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .bredcrumn-details::-webkit-scrollbar {
    height: 0px;
  }

  .bredcrumn-details::-webkit-scrollbar-thumb {
    display: none;
  }

  h1 {
    font-size: 43.9px;
    line-height: 56px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Black;

    @media (max-width: 799px) {
      font-size: 31.36px;
      line-height: 40px;
    }
  }
}

.sub-card-wrap {
  display: flex;
  justify-content: space-evenly;
  column-gap: 18px;
  row-gap: 34px;
  padding: 23.9px 18px 45px;
  flex-wrap: wrap;

  @media (max-width:799px) {
    padding: 0px;
  }

  .subscriptionandpayment-data {
    max-width: 534px;
    margin: 0px auto;

    .subscription-plan {
      position: relative;
      border-radius: 8px;
      box-shadow: 0px 1px 9px 0px #00000029;
      // min-height: 820px;
      // max-height: 820px;
      height: calc(100% + 35px);

      @media (max-width: 799px) {
        min-height: auto;
        max-height: none;
        height: 100%;
      }

      .plan-title-box {
        padding: 21px 18px 10px;
        background: rgb(0, 53, 97);
        background: linear-gradient(180deg,
            rgba(0, 53, 97, 1) 0%,
            rgba(2, 43, 76, 1) 25%,
            rgba(5, 30, 50, 1) 50%,
            rgba(8, 18, 27, 1) 75%,
            rgba(9, 11, 13, 1) 100%);
        border-radius: 8px 8px 0px 0px;

        @media (max-width: 799px) {
          flex-direction: column;
        }

        .plan-text-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .sign-up-indicator {
          font-size: 14px;
          line-height: 16px;
          font-family: $regulerFont;
          font-weight: 500;
          color: $color-White;
          letter-spacing: 0px;
          display: flex;
          align-items: center;
          column-gap: 5px;

          .dots {
            width: 9px;
            height: 9px;
            display: block;
            background-color: #fc4714;
            border-radius: 50%;
          }
        }

        .plan-title {
          color: $color-White;
          font-size: 22.4px;
          line-height: 31.36px;
          font-family: $primaryFont;
        }

        .current-plan {
          box-shadow: none;
          background: #4455c7;
          color: #ffffff;
          border-radius: 8px;
          padding: 8px;
          font-size: 12px;
          line-height: 14px;
          font-family: $regulerFont;

          .MuiTouchRipple-root {
            display: none;
          }

          :hover {
            background-color: transparent;
          }
        }

        .plan-text-details {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-White;
          letter-spacing: 0px;
        }
      }

      .plan-devider {
        height: 1px;
        background-color: $color-grey;
        width: 100%;
      }

      .plan-info {
        padding: 14px 18px 21px 18px;

        .price-box {
          .price {
            font-size: 31.36px;
            line-height: 43.9px;
            font-family: $regulerFont;
            font-weight: 600;
            color: $color-Primary;

            .suffix {
              font-size: 16px !important;
              line-height: 22.4px !important;
              font-family: $regulerFont;
              font-size: 0.8em;
              color: $color-gray-dark;
              text-transform: capitalize;
            }
          }
        }

        .des-wrap {
          padding: 27px 0px;

          .des-box {
            padding: 0px 0px 8.5px;
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
              border-bottom: 2px dashed #e8eaec;
              margin-bottom: 10.5px;
            }

            .tic-box {
              margin-right: 6px;

              .sub-disable {
                svg {
                  path {
                    fill: #e8eaec;
                  }
                }
              }
            }

            .des {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              font-family: $regulerFont;
              margin-left: 6px;

              @media (max-width:799px) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }

        .sub-btn {
          padding: 12px 24px;
          border: 1px solid $color-Accent-1;
          border-radius: 8px;
          width: 100%;
          position: absolute;
          width: calc(100% - 36px);
          bottom: 21px;

          @media (max-width: 799px) {
            padding: 11px 12px;
            position: initial;
          }

          span {
            font-size: 16px;
            line-height: 20px;
            font-family: $regulerFont;
            color: $color-Accent-1;
            text-transform: initial;

            // @media (max-width: 799px) {
            //   font-size: 11.42px;
            //   line-height: 14px;
            // }
          }

          &:hover {
            background-color: $color-Accent-1;

            span {
              color: $color-White;
            }
          }
        }

        .blank-btn {
          padding: 0px;
          height: 38px;

          .MuiTouchRipple-root {
            display: none;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .subscription-plan :hover {
      .sub-btn {
        background-color: $color-Accent-1;

        span {
          color: $color-White;
        }
      }
    }

    .most-popular-plan {
      transform: scale(1.04);
      border: 2px solid $color-Secondary;
    }
  }

  .most-popular-plan-data {
    @media (max-width: 1023px) {
      order: 1;
    }
  }
}

.your-detalis-wrap {
  // background-color: $color-White;
  padding: 34px 21px 33px 19px;
  // box-shadow: 0px 1px 9px 0px #0000000d;

  @media (max-width: 799px) {
    padding: 18px 0px 21px;
  }

  .details-wrap {
    display: flex;
    justify-content: space-between;
    column-gap: 26px;

    @media (max-width: 999px) {
      flex-direction: column;
      row-gap: 21px;
    }

    .left-your-selection {
      width: 100%;

      .your-selection-wrap {
        // max-width: 528px;
        width: 100%;

        &:first-child {
          margin-bottom: 18px;
        }

        @media (max-width: 999px) {
          max-width: none;
        }

        .selection-header {
          background-color: $color-Primary;
          border-radius: 8px 8px 0px 0px;
          padding: 21px 15px 11px;

          .header {
            font-size: 22.4px;
            line-height: 28px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $color-White;
          }
        }

        .subscription-type-details {
          border: 1px solid #e8eaec;
          border-radius: 0px 0px 8px 8px;
          padding: 21px 15px 28px;
          background-color: $color-White;
          box-shadow: 0px 1px 9px 0px #0000002e;

          .subscription-text {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
          }

          .subscription-type {
            font-weight: 500;
            margin-bottom: 11px;
          }

          .plan-deatils {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .plan-deatils-mb-9 {
            margin-bottom: 9px;
          }

          .plan-deatils-mb-19 {
            margin-bottom: 19px;
          }

          .plan-select {
            padding-bottom: 15px;
            border-bottom: 1px solid #d4d6d8;

            .plan-radioGroup {
              .MuiFormControlLabel-root {
                &:not(:last-child) {
                  margin-bottom: 7px;
                }
              }

              .MuiIconButton-root {
                padding: 0px 12px;
              }

              .MuiTypography-root {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
              }
            }
          }

          .total-details-wrap {
            border-top: 1px solid $color-grey;
          }

          .coupon-code-details-wrap {
            padding-bottom: 15px;

            .coupon-code-details {
              padding: 9px 12px;
              background-color: $color-Light-grey;
              margin-top: 9px;
              display: flex;
              align-items: center;
              column-gap: 34px;

              .details-code {
                width: 70%;

                .amount-off {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 600;
                  letter-spacing: 0px;
                  font-family: $regulerFont;
                  margin-bottom: 7px;
                  color: $color-Secondary;
                }

                .card-text {
                  font-size: 11.42px;
                  line-height: 14px;
                  font-weight: 400;
                  letter-spacing: 0px;
                  font-family: $regulerFont;
                  color: $color-Black;

                }

                .mb-7 {
                  margin-bottom: 7px;
                }
              }

              .coupon-number {
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .coupon-card {
                padding: 16.9px 12.8px;
                background-color: $color-White;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                max-width: 100px;
                word-wrap: break-word;
              }

              .border-element {
                position: relative;
              }

              .border-element:before {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                background-color: #e7e9ec;
                border-radius: 50%;
                top: -6px;
                left: 75%;
                transform: translateX(-50%);
              }

              .border-element .circle-bottom {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                background-color: #e7e9ec;
                border-radius: 50%;
                bottom: -6px;
                left: 75%;
                transform: translateX(-50%);
              }

              .border-element:after {
                content: "";
                position: absolute;
                height: 100%;
                border-right: 7px dotted #e7e9ec;
              }

              .border-element:after {
                right: -2px;
                top: 0px;
              }
            }
          }

          .total-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 19px;

            .total-name {
              font-weight: 600;
            }

            .total-price {
              font-weight: 600;
            }

            .discount-amount {
              display: flex;
              align-items: center;
              color: $color-attention;

              span {
                width: 18px;
                height: 18px;
              }
            }
          }

          .coupon-code-wrap {
            display: flex;
            column-gap: 10px;

            .details-textfield {
              width: 75%;

              @media (max-width: 659px) {
                max-width: none;
              }

              .MuiInputBase-root {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                border-radius: 8px;

                .MuiInputBase-input {
                  padding: 13px 14px;
                }

                .MuiOutlinedInput-notchedOutline {
                  border-color: #d4d6d8;
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  display: none;
                }
              }

              .MuiInputLabel-outlined {
                transform: translate(14px, 15px) scale(1);
              }

              .MuiInputLabel-outlined.MuiInputLabel-shrink {
                transform: translate(14px, -6px) scale(0.75);
                color: $color-Accent-1;
              }

              .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border-color: $color-Accent-1;
                  border-width: 1px;
                  color: $color-Accent-1;
                }
              }
            }

            .apply-btn {
              width: 25%;
              padding: 14px 0px 12px;
              background-color: $color-Accent-1;
              color: $color-White;
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              border-radius: 8px;
              text-transform: capitalize;
              box-shadow: none;

              .MuiTouchRipple-root {
                display: none;
              }
            }
          }
        }

        .update-subscription {
          text-align: end;
          margin-top: 16px;

          .update-subscription-type {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Accent-1;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .fw-400 {
          font-weight: 400 !important;
        }

        .fw-500 {
          font-weight: 500 !important;
        }

        .fw-600 {
          font-weight: 600 !important;
        }

        .fw-700 {
          font-weight: 700 !important;
        }
      }
    }

    .your-details-wrap {
      // max-width: 528px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e8eaec;
      height: max-content;
      background-color: $color-White;
      box-shadow: 0px 1px 9px 0px #0000002e;

      @media (max-width: 999px) {
        max-width: none;
      }

      .details {
        padding: 21px 15px 11px;
        border-bottom: 1px solid #d4d6d8;
        font-size: 22.4px;
        line-height: 28px;
        font-weight: 400;
        font-family: $primaryFont;
      }

      .personal-details {
        padding: 16px 15px 21px;

        .your-account {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          margin-bottom: 18px;

          .existing-account {
            color: $color-Accent-1;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .personal-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 9px;
          column-gap: 15px;

          @media (max-width: 659px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .textfield-text {
            max-width: 141px;
            width: 100%;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            letter-spacing: 0px;

            .star {
              color: #d84727;
            }
          }

          .details-textfield {
            max-width: 342px;
            width: 100%;

            @media (max-width: 659px) {
              max-width: none;
            }

            .MuiInputBase-root {
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-Black;
              border-radius: 8px;

              .MuiInputBase-input {
                padding: 13px 14px;
              }

              .MuiOutlinedInput-notchedOutline {
                border-color: #d4d6d8;
              }

              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                display: none;
              }
            }

            .Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: $color-Accent-1;
                border-width: 1px;
              }
            }
          }

          .date-picker-wrap {
            max-width: 342px;
            width: 100%;

            @media (max-width: 659px) {
              max-width: none;
            }

            .details-search-picker {
              width: 100%;
              margin: 0px;

              .MuiInputBase-root {
                border-radius: 8px;
                min-height: 45px;
                background-color: $color-White;
              }

              .Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                  border-color: $color-Accent-1;
                  border-width: 1px;
                }
              }
            }
          }

          .select-box-wrap {
            max-width: 342px;
            width: 100%;

            @media (max-width: 659px) {
              max-width: none;
            }

            .select__control {
              box-shadow: none;
              // border: 1px solid red;
              padding: 0px;
              margin: 0px;
            }

            .select__control,
            .react-select__control {
              // &.select__control--is-disabled {
              //   // border-color: $border-color;
              //   // .select__indicator-separator {
              //   //   //   background-color: $border-color;
              //   // }
              // }

              &.select__control--is-focused,
              &.react-select__control--is-focused {
                border-color: $color-Accent-1;
              }

              &.select__control--menu-is-open {
                box-shadow: none;
              }

              .select__indicator svg {
                cursor: pointer;
              }

              .select__indicator-separator {
                display: none;
              }

              .select__single-value {
                border: none;
                font-size: 16px;
                line-height: 20px;
                padding-left: 10px;
                font-family: $regulerFont;
                outline: none;
                cursor: pointer;
                margin: 0px;
              }

              .select__value-container {
                border: none;
                padding: 0px;
                margin: 0px;

                .select__input-container {
                  padding-left: 10px;
                  font-size: 16px;
                  line-height: 20px;
                  font-family: $regulerFont;
                }
              }

              .select__placeholder {
                font-size: 16px;
                line-height: 20px;
                padding-left: 10px;
                cursor: pointer;
                font-family: $regulerFont;
              }
            }

            .select__menu {
              margin: 0px;
              border-radius: 0px;
              padding: 0px;
              z-index: 999;
            }

            .select__menu-list {
              padding: 0px;
            }

            // Select Menu
            .select__menu,
            .react-select__menu {

              .select__menu-list,
              .react-select__menu-list {

                .select__option,
                .react-select__option {
                  cursor: pointer;
                  font-size: 16px;
                  color: $color-Black;
                  font-family: $regulerFont;
                  line-height: 19px;
                  padding: 11px;

                  &.select__option--is-focused {
                    background-color: #d4d6d8;
                    color: #000;
                  }

                  &.select__option--is-selected {
                    background-color: #d4d6d8;
                    color: $color-Black;
                  }
                }
              }
            }

            .select {
              .select__control {
                min-height: 45px;
                border-radius: 8px;
                width: 100%;
              }
            }
          }
        }

        .checkBox-wrap {
          margin-top: 27px;
          display: flex;
          align-items: flex-start;

          .documentsRead-check {
            align-items: flex-start;
            margin-right: 0px;

            .MuiButtonBase-root {
              margin-right: 12px;
              padding: 0px 0px 0px 10px;

              &:hover {
                background-color: transparent;
              }

              .MuiSvgIcon-root {
                path {
                  fill: #d4d6d8;
                }
              }

              .MuiTouchRipple-root {
                display: none;
              }
            }

            .Mui-checked {
              .MuiSvgIcon-root {
                path {
                  fill: $color-Primary;
                }
              }
            }
          }

          .policy-text {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            letter-spacing: 0px;

            span {
              color: $color-Accent-1;
              text-decoration: underline;
              cursor: pointer;

              a {
                color: $color-Accent-1;
                text-decoration: none;
              }
            }
          }
        }

        .payment-btn-wrap {
          margin-top: 32px;

          .payment-btn {
            width: 100%;
            padding: 14px 0px 12px;
            background-color: $color-Accent-1;
            color: $color-White;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            border-radius: 8px;
            text-transform: capitalize;
            box-shadow: none;

            .MuiTouchRipple-root {
              display: none;
            }
          }
        }
      }
    }
  }
}

.my-subscription-otp-modal {
  .MuiDialog-paper {
    padding: 45px 28px;

    .login-title {
      font-family: $regulerFont;
      font-size: 22.4px;
      line-height: 31.36px;
      font-weight: 600;
      color: $color-Black;
    }

    .otp-content {
      font-family: $regulerFont;
      font-size: 18px;
      line-height: 31.36px;
      font-weight: 500;
      color: $color-Black;
      padding: 12px 0px;

      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .textfield-sec {
      text-align: left;
      padding-top: 18px;

      .text-field {
        width: 100%;

        .MuiInputBase-input {
          padding: 10px 10px;
          max-width: 514px;
          width: 100%;
          min-height: 25px;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 20px;
        }

        .MuiInputBase-root {
          background-color: $color-White !important;
          border-radius: 8px;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          display: none;
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #4455c7;
            border-width: 1;
          }
        }

        .MuiOutlinedInput-adornedEnd {
          justify-content: space-between;
        }
      }

      .email-text-field {
        .MuiInputBase-input {
          max-width: none;
        }
      }

      .textfield-text {
        font-family: $regulerFont;
        font-size: 16px;
        line-height: 21px;
        color: $color-Black;
        text-align: left;
        font-weight: 600;
      }

      svg {
        cursor: pointer;
      }
    }

    .forgot-password-link {
      padding-top: 9px;
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 21px;
      color: $color-gray-dark;
      text-decoration: underline;
      text-align: left;
      cursor: pointer;
    }
  }

  .otp-msg-wrap {
    text-align: center;

    .submit-btn {
      margin-top: 18px;
      cursor: pointer;
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      border: 1px solid $color-Accent-1;
      border-radius: 8px;
      letter-spacing: 0px;
      background: $color-Accent-1;
      color: $color-White;
      width: 100%;
      padding: 15px 0;
      text-transform: none;
    }
  }
}