@import "../../../../../assets/scss/variable.scss";

.filter-modal-wrap {
  .filter-header {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 12px 0px;
  }

  .modal-header-title {
    font-size: 22.4px;
    line-height: 31.36px;
    font-weight: 600;
    font-family: $regulerFont;
    letter-spacing: 0px;
    color: $color-Primary;
    margin-left: 5px;

    @media (max-width: 799px) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .modal-header-sub-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    font-family: $regulerFont;
    letter-spacing: 0px;
    color: $color-Black;
    padding: 0px 12px 0px 33px;

    @media (max-width: 799px) {
      font-size: 14px;
      line-height: 19px;
      padding: 0px 12px 0px 12px;
    }
  }

  .customise-filter-dropdown-wrap {
    padding: 9px 86px 27px 33px;
    // border-bottom: 1px solid #edeef0;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 39px;
    row-gap: 9px;

    @media (max-width: 1024px) {
      padding: 9px 33px 27px 33px;
      gap: 9px;
    }

    @media (max-width: 799px) {
      grid-template-columns: auto;
      padding: 12px 12px;
      gap: 5px;
    }
  }

  .filter-modal-btn-wrap {
    // padding-top: 25px;
    text-align: center;
    position: sticky;
    bottom: -20px;
    background: $color-White;
    padding: 25px;
    z-index: 11;

    .filter-save-btn {
      max-width: 154px;
      padding: 13px 0px 12px;
      text-align: center;
      border-radius: 8px;
      background-color: $color-Accent-1;
      color: $color-White;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      letter-spacing: 0px;
      text-transform: capitalize;
      width: 100%;
      margin-bottom: 8px;

      @media (max-width: 799px) {
        max-width: none;
      }
    }

    .filter-reset-all {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      column-gap: 5px;

      .reset-all {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Accent-1;
        text-transform: capitalize;
        text-decoration: underline;
      }
    }
  }

  .customise-other-filter-container {
    padding: 10px 86px 18px 33px;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    column-gap: 39px;
    row-gap: 9px;
    border-bottom: 1px solid #edeef0;

    @media (max-width: 799px) {
      grid-template-columns: auto auto;
      padding: 18px 12px;
    }
  }

  .racing-customise-other-filter-container {
    grid-template-columns: 25% 25% 25% 25%;
    column-gap: 0px;
    row-gap: 28px;

    @media (max-width: 940px) {
      grid-template-columns: auto auto;
      row-gap: 15px;
    }
  }

  .customise-other-filter-list {
    .other-filter-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      font-family: $regulerFont;
      letter-spacing: 0px;
      color: $color-Black;

      @media (max-width: 799px) {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 12px;
      }
    }

    .other-filter-checkbox {
      margin-left: 6px;
      width: 100%;

      @media (max-width: 799px) {
        margin-left: 5px;

        &:not(:last-child) {
          margin-bottom: 9px;
        }
      }

      .MuiButtonBase-root {
        @media (max-width: 799px) {
          padding: 0px;
          margin-right: 6px;
        }
      }

      .MuiFormControlLabel-label {
        font-size: 16px;
        line-height: 20px;

        @media (max-width: 799px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  .comp-podcast-filter-container {
    border-top: 1px solid #edeef0;

    .modal-header-title {
      @media (max-width: 799px) {
        margin-bottom: 12px;
      }
    }
  }
}

//drag and drop modal
.drag-drop-wrap {
  .scroll-header {
    padding: 26px 0px 12px;

    @media (max-width: 799px) {
      padding: 11px 0px 9px;
    }
  }

  .scrollable-section {
    margin-top: 26px;
    height: 645px;
    overflow-y: auto;

    @media (max-width: 799px) {
      margin-top: 0px;
      height: 370px;
      overflow-x: hidden;
    }

    .drag-drop-section {
      padding: 0px;

      .MuiList-padding {
        padding: 0px;

        .MuiListItem-root {
          padding: 0px;

          .two-item-wrap {
            display: flex;
            justify-content: space-between;
            width: 100%;
            column-gap: 25px;

            @media (max-width: 799px) {
              column-gap: 6px;
            }

            .drag-item-wrap {
              width: calc(50% - 12.5px);
            }

            .empty-box {
              width: calc(50% - 12.5px);
              padding: 9px 18px;

              @media (max-width: 799px) {
                padding: 9px;
              }
            }
          }
        }
      }

      .drag-item-wrap {
        width: 96%;
        padding: 9px 18px;
        border-radius: 8px;
        box-shadow: 0px 1px 6px #00000026;
        margin: 0px auto 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 799px) {
          padding: 9px;
          margin: 0px auto 9px;
          align-items: flex-start;

          .name-icon-container {
            display: flex;
            align-items: flex-start;
            column-gap: 4px;

            .responsive-icon {
              min-width: 16px;

              rect {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        @media (max-width: 479px) {
          width: 94%;
        }
      }
    }

    .add-more-section {
      .drag-item-wrap {
        width: 96%;
      }
    }
  }

  .filter-modal-btn-wrap {
    padding-top: 25px;
    text-align: center;

    .filter-save-btn {
      max-width: 154px;
      padding: 13px 0px 12px;
      text-align: center;
      border-radius: 8px;
      background-color: $color-Accent-1;
      color: $color-White;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      letter-spacing: 0px;
      text-transform: capitalize;
      width: 100%;
      margin-bottom: 8px;

      @media (max-width: 799px) {
        max-width: none;
      }
    }

    .filter-reset-all {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      column-gap: 5px;

      .reset-all {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Accent-1;
        text-transform: capitalize;
        text-decoration: underline;
      }
    }
  }

  .regular-font {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: $regulerFont;
  }

  .regular-small-font {
    font-size: 11.42px;
    line-height: 20px;
    font-weight: 400;
    font-family: $regulerFont;
  }

  .semibold-font {
    font-weight: 600;
  }

  .responsive-regular {
    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      font-family: $regulerFont;
    }
  }

  .drag-drop-btn {
    padding: 12px 24px;
    border-radius: 8px;
    text-transform: capitalize;

    .icon {
      margin-right: 5px;
    }
  }

  .remove-btn {
    color: $color-Accent-1;
    border: 1px solid $color-Accent-1;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  .add-btn {
    color: $color-White;
    border: none;
    background-color: $color-Accent-1;
    align-items: baseline;

    &:hover {
      background-color: $color-Accent-1;
    }
  }
}

// customise home modal

.customise-home-modal {
  .MuiDialog-paper {
    max-width: 1122px;
    width: 100%;
    margin: 12px;
    border-radius: 8px;

    .modal-title {
      background-color: #e8eaec;
      padding: 21px 18px 9px;
      position: relative;

      @media (max-width: 799px) {
        padding: 12px 12px 12px;
      }

      h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 31.36px;
          line-height: 31.36px;
          font-weight: 400;
          font-family: $primaryFont;
          color: $color-Black;
          margin-right: 45px;

          @media (max-width: 799px) {
            font-size: 22.4px;
            line-height: 28px;
          }
        }

        .close-icon {
          padding: 0px;
          position: absolute;
          top: 18px;
          right: 18px;

          svg {
            path {
              fill: #989898;
            }
          }

          @media (max-width: 799px) {
            top: 12px;
            right: 12px;
          }
        }
      }

      .modal-wrap {
        display: flex;
        justify-content: left;
        align-items: center;
      }
    }

    .customise-home-modal-details {
      padding: 0px 18px 18px 18px;

      @media (max-width: 799px) {
        padding: 10px 12px 15px;
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 45px;
        max-width: 400px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__control,
      .react-select__control {
        &.select__control--menu-is-open {
          box-shadow: none;
        }

        .select__indicator svg {
          cursor: pointer;
        }

        .select__indicator-separator {
          display: none;
        }

        .select__single-value {
          font-size: 16px;
          line-height: 20px;
          padding-left: 10px;
          outline: none;
          cursor: pointer;
          margin: 0px;
          font-family: "Inter", sans-serif;
        }

        .select__value-container {
          padding: 0px;
          margin: 0px;

          .select__input-container {
            font-size: 16px;
            font-family: "Inter", sans-serif;
          }
        }

        .select__placeholder {
          font-size: 16px;
          line-height: 20px;
          padding-left: 10px;
          cursor: pointer;
          font-family: $regulerFont;
        }
      }

      .select__menu {
        margin: 0px;
        border-radius: 0px;
        padding: 0px;
        z-index: 999;
      }

      .select__menu-list {
        padding: 0px;
      }

      // Select Menu
      .select__menu,
      .react-select__menu {

        .select__menu-list,
        .react-select__menu-list {

          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: $color-grey;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: $color-grey;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }
    }
  }

  .customise-tab-section {
    // border-bottom: 1px solid $color-Accent-1;

    .customise-tab-details {
      // position: absolute;
      // top: 17px;
      // left: 25%;
      // padding: 13px 18px 12px;
      min-height: 38px;

      @media (max-width: 799px) {
        padding: 9px 18px;
        min-height: 32px;
      }

      @media (max-width: 768px) {
        padding: 0px 0px 10px 0px;
        border-bottom: 1px solid $color-Primary;
      }

      .MuiTab-textColorInherit {
        color: $color-Primary;
        border: 2px solid $color-Primary;
        margin: 0px 12px;
        border-radius: 8px;
        opacity: 1;
        min-height: 38px;

        @media (max-width: 799px) {
          margin: 0 9px;
          min-height: 32px;
        }
      }

      .active {
        color: $color-White;
        background: $color-Primary;
      }

      .MuiTabs-indicator {
        background: none;
      }

      .MuiTab-textColorInherit:first-child {
        margin-left: 0px;
      }

      .MuiTab-root {
        min-width: initial;
        width: fit-content;
      }

      .MuiTab-wrapper {
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }

      .Mui-disabled {
        opacity: 1;
      }

      .MuiTabs-scroller {
        .MuiTabs-flexContainer {
          .MuiBox-root:last-child {
            button {
              margin: 0px;
            }
          }
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}

// home custom dropdown
.home-custom-dropdown {
  position: relative;

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px 7px;
    background-color: #dfe0ea;
    cursor: pointer;

    .header-details {
      display: flex;
      align-items: center;
      column-gap: 9px;

      .country-name {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;
        text-transform: capitalize;
      }
    }

    .MuiFormControlLabel-root {
      margin: 0px;

      .MuiButtonBase-root {
        padding: 0px;
      }
    }
  }

  .dropdown-options {
    position: absolute;
    z-index: 9;
    background: #e7e9ec;
    width: calc(100% - 60px);
    top: 36px;
    right: 0px;
    padding: 10px 12px;
    border-radius: 8px;
    height: 216px;
    overflow-y: scroll;

    .options-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 9px;
      }

      .MuiFormControlLabel-root {
        margin: 0px;

        .MuiButtonBase-root {
          padding: 0px;
        }
      }

      .state-name {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: $color-Black;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;
      }
    }
  }
}