// @import "../scss/variable.scss";
@import "../../../assets/scss/variable.scss";

.profile-contanier {
  // padding: 29px 31px;

  // @media (max-width: 600px) {
  //   padding: 29px 12px;
  // }

  .profile-details {
    position: relative;

    h3 {
      font-size: 31.6px;
      margin: 0px;
      font-weight: 400;
    }

    .loader_center {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .details-profile {
      .error-message {
        color: $color-Secondary;
        font-size: 16px;
        font-weight: 600;
        font-family: $regulerFont;
        padding: 10px 0px;

        @media (max-width: 440px) {
          font-size: 14px;
        }
      }

      th {
        min-width: 285px;
        text-align: left;
        padding: 9.5px 0px;
        font-size: 16px;
        font-weight: 600;
        // font-family: $regulerFont;
        font-family: "Inter", sans-serif;

        @media (max-width: 600px) {
          min-width: 190px;
          font-size: 15px;
        }

        @media (max-width: 390px) {
          min-width: 90px;
        }

        .profile-edit {
          color: #4455c7;
          font-size: 11.42px;
          font-weight: 400;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .user-details {
        padding: 0px;
      }

      .profile-image-name-wrap {
        display: flex;
        align-items: center;
        column-gap: 9px;
        margin: 0px;
        cursor: pointer;

        @media (max-width: 599px) {
          margin: 7px 0px;
        }

        .profile-image-wrap {
          height: 70px;
          width: 70px;
          border-radius: 50%;
          position: relative;

          .profile-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 50%;
          }

          .add-button {
            position: absolute;
            bottom: 0px;
            right: 0px;
          }
        }

        .profile-image-text-wrap {
          margin-top: 28px;

          .image-text {
            font-size: 16px;
            line-height: 20px;
            color: $color-Accent-1;
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }

      td {
        padding: 9.5px 0px;
        font-size: 16px;
        font-weight: 400;
        // font-family: $regulerFont;
        font-family: "Inter", sans-serif;

        @media (max-width: 600px) {
          font-size: 11.42px;
        }

        span {
          font-weight: 600;
        }
      }

      .ml-10 {
        margin-left: 10px;

        @media (max-width: 659px) {
          margin-left: 0px;
        }
      }

      .blackbook {
        font-size: 16px;
        font-weight: normal;
        line-height: 21px;
        border: 1px solid $color-Accent-1;
        border-radius: 8px;
        letter-spacing: 0px;
        background: $color-White;
        color: $color-Accent-1;
        padding: 12px 18px;
        margin-top: 26px;

        @media (max-width: 659px) {
          margin-top: 22px;
        }

        @media (max-width: 635px) {
          font-size: 14px;
          padding: 12px 16px;
          // margin-top: 36px;
        }
      }

      .logout {
        font-size: 18px;
        font-weight: normal;
        line-height: 21px;
        border: 1px solid $color-Accent-1;
        border-radius: 8px;
        letter-spacing: 0px;
        background: $color-Accent-1;
        color: $color-White;
        padding: 16px 34px;
        margin-top: 44px;

        @media (max-width: 659px) {
          margin-top: 22px;
        }

        @media (max-width: 635px) {
          font-size: 14px;
          padding: 12px 46px;
          // margin-top: 36px;
        }
      }

      .delete {
        margin-left: 10px;

        @media (max-width: 659px) {
          margin-left: 0px;
        }
      }
    }
  }

  .useredit-details {
    display: flex;
    align-items: center;
    cursor: pointer;

    .back-arrow {
      transform: rotate(90deg);
      margin-right: 10px;
      cursor: pointer;
    }

    h4 {
      // text-decoration: underline;
      font-size: 22.4px;
      line-height: 27px;
      margin: 0px 0px 23px;
      font-weight: 600;

      @media (max-width: 799px) {
        font-size: 14px;
        line-height: 18px;
        margin: 0px 0px 12px;
      }
    }
  }

  .details {
    display: block;
    width: 70%;
    margin-bottom: 18px;

    @media (max-width: 799px) {
      width: 100%;
    }

    @media (max-width: 490px) {
      display: block;
    }

    .textfield-text {
      font-size: 16px;
      font-weight: 400;
      // font-family: $regulerFont;
      font-family: "Inter", sans-serif;
      width: 235px;
    }

    .wp-password {
      // max-width: 269px;
      max-width: 100%;
      // @media (max-width: 490px) {
      //   max-width: 100%;
      // }
    }

    .title-select-wrap {
      display: flex;
      flex-direction: column;
    }

    .select-title {
      min-width: 134px;

      @media (max-width: 490px) {
        min-width: none;
        width: 100%;
      }

      .css-1s2u09g-control {
        padding: 5px 0;
        border-radius: 8px;
        background-color: #fbfbfb;
      }

      .css-1okebmr-indicatorSeparator {
        width: 0px;
      }
    }

    .select-country-state {
      min-width: auto;

      @media (max-width: 490px) {
        min-width: none;
        width: 100%;
      }

      .css-1s2u09g-control {
        padding: 5px 0;
        border-radius: 8px;
        background-color: #fbfbfb;
      }

      .css-1okebmr-indicatorSeparator {
        width: 0px;
      }
    }

    .select__control {
      box-shadow: none;

      padding: 5px 0px;
      margin: 0px;
      border-radius: 8px;

      @media (max-width: 490px) {
        margin-top: 5px;
      }
    }

    .select__control,
    .react-select__control {
      &.select__control--is-disabled {
        // border-color: $border-color;
        .select__indicator-separator {
          //   background-color: $border-color;
        }
      }

      // &.select__control--is-focused,
      // &.react-select__control--is-focused {

      // }

      &.select__control--menu-is-open {
        box-shadow: none;
        // border: none;
      }

      .select__indicator svg {
        cursor: pointer;
      }

      .select__indicator-separator {
        display: none;
      }

      .select__single-value {
        // border: none;
        font-size: 16px;
        line-height: 20px;
        padding-left: 10px;
        outline: none;
        cursor: pointer;
        margin: 0px;
        font-family: "Inter", sans-serif;
      }

      .select__value-container {
        // border: none;
        padding: 0px;
        margin: 0px;

        .select__input-container {
          font-size: 16px;
          font-family: "Inter", sans-serif;
          margin-left: 10px;
        }
      }

      .select__placeholder {
        font-size: 16px;
        line-height: 20px;
        padding-left: 10px;
        cursor: pointer;
        font-family: $regulerFont;
      }
    }

    .select__menu {
      margin: 0px;
      border-radius: 0px;
      padding: 0px;
      z-index: 999;
    }

    .select__menu-list {
      padding: 0px;
    }

    // Select Menu
    .select__menu,
    .react-select__menu {
      .select__menu-list,
      .react-select__menu-list {
        .select__option,
        .react-select__option {
          cursor: pointer;
          font-size: 16px;
          color: $color-Black;
          font-family: $regulerFont;
          line-height: 19px;
          padding: 11px;

          &.select__option--is-focused {
            background-color: #d4d6d8;
            color: #000;
          }

          &.select__option--is-selected {
            background-color: #d4d6d8;
            color: $color-Black;
          }
        }
      }

      .select__menu-list,
      .react-select__menu-list {
        .select__group {
          .select__group-heading {
            margin-bottom: 0.5rem;
            color: green;
            font-weight: bolder;
            font-size: inherit;
          }
        }
      }
    }

    .details-textfield {
      min-width: 269px;
      width: 100%;

      @media (max-width: 490px) {
        min-width: none;
        width: 100%;
        margin-top: 5px;
      }

      .MuiOutlinedInput-root {
        border-radius: 8px;
        font-family: "Inter", sans-serif;

        .MuiOutlinedInput-input {
          padding: 13px 11px;
        }
      }

      .MuiInputLabel-outlined {
        transform: translate(14px, 14px) scale(1);
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
        // color: $color-Accent-1;
      }

      .MuiInputBase-root {
        background-color: $color-White !important;
        border-radius: 8px;
      }

      .MuiInputLabel-shrink.Mui-focused {
        color: $color-Accent-1;
      }

      .Mui-focused {
        // color: $color-Accent-1;
        .MuiOutlinedInput-notchedOutline {
          border-color: #4455c7;
          border-width: 1;
        }
      }

      svg {
        cursor: pointer;
      }
    }

    .datebirth-picker {
      width: 100%;
      min-width: auto;
      margin: 0px;
      background-color: $color-White;

      @media (max-width: 490px) {
        min-width: none;
        width: 100%;
        margin-top: 5px;
      }

      .MuiOutlinedInput-root {
        border-radius: 8px;
        padding-right: 0px;

        .MuiOutlinedInput-input {
          padding: 13px 11px;
        }
      }

      .MuiInputLabel-shrink.Mui-focused {
        color: $color-Accent-1;
      }

      .Mui-focused {
        // color: $color-Accent-1;
        .MuiOutlinedInput-notchedOutline {
          border-color: $color-Accent-1;
          border-width: 1;
        }
      }
    }
  }

  .two-element-grid {
    display: grid;
    grid-template-columns: calc(50% - 9px) calc(50% - 9px);
    column-gap: 18px;

    @media (max-width: 1140px) {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
    }

    .mobile-number-wrap {
      display: flex;
      align-items: baseline;
      column-gap: 2px;
    }

    .phone-code {
      width: 110px;

      // .select__control {
      //   max-width: 105px;
      //   width: 105px;
      // }
      .select__indicator {
        padding: 6px 3px;
      }

      .select__menu {
        width: 307px;
      }
    }

    .mobile-textfield {
      width: calc(100% - 110px);
      min-width: auto;
    }
  }

  .hear-about-details {
    align-items: baseline;

    @media (max-width: 620px) {
      flex-direction: column;
      row-gap: 10px;
    }

    .checkbox-list {
      max-width: 269px;

      @media (max-width: 620px) {
        max-width: none;
      }
    }

    .checkbox-list-1 {
      // grid-template-columns: 70% 70% !important;
      column-gap: 18px;

      @media (max-width: 960px) {
        grid-template-columns: auto auto !important;
      }
    }

    .other-filed {
      // grid-template-columns: 70% 60% !important;
      column-gap: 18px;

      @media (max-width: 960px) {
        grid-template-columns: 55% 55% !important;
      }

      @media (max-width: 560px) {
        grid-template-columns: 225px 225px !important;
      }

      @media (min-width: 380px) and (max-width: 420px) {
        margin-right: 0px !important;
      }
    }
  }

  .subscription-wrap {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 44px;
    }

    .subscription-text {
      @media (max-width: 439px) {
        width: 185px;
      }
    }

    .subscription-details {
      font-size: 16px;
      line-height: 19px;
      font-family: $regulerFont;
      font-weight: 600;
      color: $color-Black;

      @media (max-width: 799px) {
        font-size: 15px;
        line-height: 16px;
      }

      .active {
        color: $color-Affirmative;
      }

      .deactive {
        color: $color-Secondary;
      }
    }
  }

  .cancel-subscription-wrap {
    margin-top: 15px;
    width: 50%;
    text-align: center;

    @media (max-width: 799px) {
      width: 100%;
    }

    .cancel-subscription {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Accent-1;
      border-bottom: 1px solid $color-Accent-1;
      display: inline-block;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }

    .disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .profile-button {
    display: flex;
    margin-top: 26px;
    width: 100%;

    @media (max-width: 799px) {
      width: 100%;
    }

    .profile-btn {
      display: flex;
      width: 100%;
      column-gap: 18px;

      @media (max-width: 600px) {
        display: block;
      }

      .btn-cancel {
        min-width: auto;
        width: 100%;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 12px 0px;
        max-height: 45px;
        border-color: #4455c7;
        color: #4455c7;
        text-transform: capitalize;
        font-size: 18px;

        @media (max-width: 600px) {
          margin-bottom: 15px;
          font-size: 16px;
        }
      }

      .btn-save {
        min-width: auto;
        width: 100%;
        background-color: #4455c7;
        color: #ffffff;
        border-radius: 8px;
        padding: 12px 0px;
        max-height: 45px;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .password-btn {
    padding-top: 280px;

    @media (max-width: 799px) {
      padding-top: 27px;
    }
  }

  .checkbox-list {
    padding-bottom: 210px;

    @media (max-width: 799px) {
      padding-bottom: 27px;
    }

    .checkbox-list-1 {
      display: grid;
      grid-template-columns: 225px 225px;

      @media (max-width: 420px) {
        grid-template-columns: auto auto;
      }
    }

    .checkbox-list-2 {
      // display: grid;
      // grid-template-columns: 215px 215px 260px;
      text-align: left;
      display: flex;
      column-gap: 9px;
      row-gap: 15px;
      flex-wrap: wrap;
      cursor: pointer;
      width: 70%;

      @media (max-width: 1439px) {
        width: 100%;
      }

      .bookmakers-check {
        background-color: #4a65ad;
        color: #97abbf;
        padding: 8px 16px;
        border-radius: 18px;
      }

      .active-label {
        background-color: $color-Primary;
        color: $color-White;
      }

      .text-field {
        input {
          width: 150px;
          padding: 8px;
        }
      }

      // @media (max-width: "960px") {
      //   grid-template-columns: auto auto;
      // }
    }

    .checkbox-list-3 {
      display: grid;
      grid-template-columns: auto;
    }

    .bookmakers-check {
      .PrivateSwitchBase-root-5 {
        padding: 9.5px;
      }

      .Mui-checked {
        color: #003764;
      }

      .bookmakers-checkbox {
        .MuiSvgIcon-root {
          width: 16px;
          height: 16px;
        }
      }

      @media (max-width: 520px) {
        .MuiTypography-body1 {
          font-size: 11.42px;
        }
      }
    }
  }

  .profile-bookmaker-list {
    display: flex;
    padding-bottom: 27px;

    .checkbox-list-1 {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 38px;
      row-gap: 15px;
      height: 600px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-left: 4px;

      @media (max-width: 1139px) {
        grid-template-columns: 50% 50%;
        column-gap: 5px;
        row-gap: 9px;
        padding-left: 0px;
      }

      @media (max-width: 599px) {
        grid-template-columns: auto;
      }

      .bookmakers-check {
        margin: 0px;

        .Mui-checked {
          color: #003764;
        }

        .bookmakers-checkbox {
          padding: 6px;

          .MuiSvgIcon-root {
            width: 16px;
            height: 16px;
          }
        }

        .checkbox-label-wrap {
          display: flex;
          align-items: center;
          column-gap: 6px;

          .bookeeper-image-wrap {
            width: 90px;
            height: 27px;
            border-radius: 6px;
            display: block;

            @media (max-width: 799px) {
              width: 60px;
              height: 25px;
            }

            .bookeeper-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
          }

          .bookeeper-name {
            font-size: 16px;
            line-height: 19px;

            @media (max-width: 799px) {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }

    .checkbox-list-1:not(:last-child) {
      margin-right: 144px;
    }
  }

  .other-filed {
    display: grid;
    grid-template-columns: 225px 225px;

    .text-field {
      @media (max-width: 380px) {
        // grid-template-columns: 170px 170px;
        width: 185px;
      }

      @media (max-width: 360px) {
        // grid-template-columns: 170px 170px;
        width: 150px;
      }

      .MuiOutlinedInput-root {
        border-radius: 8px;

        @media (max-width: 520px) {
          font-size: 11.42px;
        }

        .MuiOutlinedInput-input {
          padding: 13.5px 14px;
        }
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #4455c7;
          border-width: 1;
        }
      }
    }

    .sport-filed {
      @media (min-width: 960px) {
        display: none;
      }
    }
  }

  .notification-wrap {
    display: grid;
    grid-template-columns: calc(50% - 9px) calc(50% - 9px);
    row-gap: 18px;
    width: 100%;
    column-gap: 10px;

    @media (max-width: 1023px) {
      grid-template-columns: auto auto;
    }

    @media (max-width: 799px) {
      width: 100%;
    }

    @media (max-width: 599px) {
      grid-template-columns: auto;
    }

    .parent-checkbox {
      text-align: left;
      padding: 0px 12px 17px 12px;
      width: 346px;
      background-color: $color-Light-grey-4;

      @media (max-width: 799px) {
        padding: 0px 12px 9px 12px;
      }

      .MuiFormControlLabel-root {
        margin-left: 0px;
        padding-bottom: 9px;

        @media (max-width: 799px) {
          padding-bottom: 2px;
        }
      }

      .p-25 {
        padding-left: 25px;
      }

      .sport-icon-wrap {
        display: flex;
        align-items: center;

        .sports-name {
          color: $color-Primary;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          @media (max-width: 479px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .sport-icon {
          display: flex;
          padding-left: 3px;

          .storke-svg {
            path {
              stroke: $color-Primary;
            }
          }
        }
      }

      // .child-checkbox {
      //   .MuiFormControlLabel-label {
      //     font-size: 16px;
      //     line-height: 20px;
      //     font-weight: 400;
      //     color: $color-Black;

      //     @media (max-width: 479px) {
      //       font-size: 11.42px;
      //       line-height: 14px;
      //     }
      //   }
      // }
      .child-wrap {
        display: flex;
        flex-wrap: wrap;
        column-gap: 9px;
        row-gap: 6px;
      }

      .child-checkbox {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        background-color: #4a65ad;
        color: #97abbf;
        padding: 8px 16px;
        border-radius: 18px;
        cursor: pointer;

        @media (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .active-label {
        background-color: $color-Primary;
        color: $color-White;
      }
    }
  }

  .filed-1 {
    @media (max-width: 460px) {
      // grid-template-columns: 170px 170px;
      display: flex;
      // justify-content: end;
      justify-content: space-between;
    }

    @media (min-width: 380px) and(max-width:420px) {
      margin-right: 25px;
    }

    @media (max-width: 400px) {
      justify-content: space-between;
    }
  }

  .filed-2 {
    @media (max-width: 960px) {
      display: flex;
      justify-content: space-evenly;
    }

    @media (max-width: 445px) {
      justify-content: space-evenly;
    }

    @media (min-width: 320px) and(max-width:460px) {
      margin-left: 110px;
    }
  }
}

.profile-logout-delete {
  display: flex;
  column-gap: 10px;

  @media (max-width: 659px) {
    flex-direction: column;
  }
}

.plan-amount {
  margin-left: 5px;
  font-weight: 400 !important;
}

.delete-dialogbox {
  text-align: center;
  // padding: 45px 42px;

  .delete-title {
    font-size: 31.36px;
    font-weight: 400;
    font-family: $regulerFont;
    line-height: 43.9px;
    color: $color-Black;
    margin-bottom: 47px;
  }

  .delete-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-Close-Modal {
      min-width: 100%;
      width: 100%;
      margin-right: 0px;
    }

    .btn-cancel {
      max-width: 276px;
      width: 100%;
      margin-right: 7px;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 12px 0px;
      max-height: 45px;
      border-color: #4455c7;
      color: #4455c7;
      text-transform: capitalize;
      font-size: 18px;
      border: 1px solid;

      @media (max-width: 600px) {
        margin-bottom: 15px;
        font-size: 16px;
      }
    }

    .blackbook-delete {
      @media (max-width: 600px) {
        margin-bottom: 0px;
      }
    }

    .btn-save {
      max-width: 276px;
      width: 100%;
      background-color: #4455c7;
      color: #ffffff;
      border-radius: 8px;
      padding: 12px 0px;
      max-height: 45px;
      text-transform: capitalize;
      font-size: 18px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
}

.customise-wrapper {
  height: 100%;
  position: relative;

  .customise-container {
    font-family: $regulerFont;
    background: $color-Light-grey;
    border-radius: 8px;
    padding: 12px 30px 12px 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 9px;
      padding: 12px;
    }

    .customise-screen-name-container {
      .customise-screen-name {
        font-size: 16px;
        color: $color-Primary;
        font-weight: 600;
        line-height: 22.4px;

        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 22.4px;
        }

        .customise-screen-name-default {
          font-size: 11.42px;
          color: $color-Black;
          margin-left: 9px;
          font-weight: 400;
        }
      }
    }

    .customise-screen-button-container {
      @media (max-width: 1023px) {
        display: flex;
        gap: 9px;
        width: 100%;
      }

      .customise-button {
        font-size: 16px;
        padding: 11px 24px;
        line-height: 22px;
        text-transform: inherit;
        letter-spacing: 0px;
        border-radius: 8px;

        @media (max-width: 1023px) {
          width: 50%;
        }

        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 22px;
        }

        @media (max-width: 425px) {
          font-size: 12px;
          line-height: 22px;
        }
      }

      .customise-set-as-btn {
        background: $color-Accent-1;
        color: $color-White;
      }

      .customise-selected-btn {
        background: #78c2a7;
        color: $color-White;

        .right-icon {
          margin-right: 6px;
          height: 16px;
          width: 16px;

          path {
            stroke: $color-White;
          }
        }
      }

      .customise-btn {
        border: 1px solid $color-Accent-1;
        color: $color-Accent-1;
        margin-left: 12px;
        padding: 10px 23px;

        @media (max-width: 1023px) {
          margin-left: 0px;
        }
      }

      .MuiButton-root.Mui-disabled {
        color: $color-Accent-1;
        background-color: transparent;
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: initial;
      }
    }
  }

  .customise-save-btn-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;

    @media (max-width: 799px) {
      position: initial;
    }

    .save-btn {
      font-size: 16px;
      padding: 11px 24px;
      line-height: 22px;
      text-transform: inherit;
      letter-spacing: 0px;
      border-radius: 8px;
      background: $color-Accent-1;
      color: $color-White;
      width: 100%;
    }
  }
}
