@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import "../scss/variable.scss";

@font-face {
  font-family: "VeneerClean-Soft";
  src: url("../fonts/VeneerClean-Soft.eot");
  /* IE9*/
  src: url("../fonts/VeneerClean-Soft.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../fonts/VeneerClean-Soft.woff2") format("woff2"),
    /* chrome、firefox */
    url("../fonts/VeneerClean-Soft.woff") format("woff"),
    /* chrome、firefox */
    url("../fonts/VeneerClean-Soft.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/VeneerClean-Soft.svg#VeneerClean-Soft") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

@font-face {
  font-family: "Veneer";
  src: url("../fonts/veneer_italic-webfont.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "SF-PRO-Regular";
  src: url("../fonts/sf-pro-text-regular.eot");
  /* IE9*/
  src: url("../fonts/sf-pro-text-regular.eot?#iefix") format("embedded-opentype"),
    /* chrome、firefox */
    url("../fonts/sf-pro-text-regular.woff") format("woff"),
    /* chrome、firefox */
    url("../fonts/sf-pro-text-regular.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/sf-pro-text-regular.svg#sf_pro_textregular") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

@font-face {
  font-family: "SF-PRO-Semibold";
  src: url("../fonts/sf-pro-text-semibold.eot");
  /* IE9*/
  src: url("../fonts/sf-pro-text-semibold.eot?#iefix") format("embedded-opentype"),
    /* chrome、firefox */
    url("../fonts/sf-pro-text-semibold.woff") format("woff"),
    /* chrome、firefox */
    url("../fonts/sf-pro-text-semibold.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/sf-pro-text-semibold.svg#sf_pro_textsemibold") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

@font-face {
  font-family: "Arial";
  src: url("../fonts/arial-webfont.woff2");
  /* IE9*/
  src: url("../fonts/arial-webfont.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "PeckhamPress-Trial";
  src: url("../fonts/PeckhamPressTrial.otf");
  font-display: swap;
}

@font-face {
  font-family: "TheStamshons-Demo";
  src: url("../fonts/TheStamshonsDemo.otf");
  font-display: swap;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  outline: none;

  // overflow: hidden;
  span,
  h4,
  h5,
  h6,
  a,
  p,
  li {
    font-family: $regulerFont !important;
  }

  h1,
  h2,
  h3 {
    font-family: $primaryFont;
  }

  a {
    text-decoration: none;
  }

  iframe {
    position: inherit !important;
  }

  .mobileMenuDrawer {
    z-index: 99999 !important;
  }

  .allsport-loader-center {
    text-align: center;
  }

  .app-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .homepage-loader {
    height: calc(100vh - 206px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  // * {
  //   scrollbar-width: thin;
  //   scrollbar-color: #4455c7 #e7e9ec;
  // }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    border-radius: 0px;
    height: 12px;
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #e7e9ec;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4455c7;
    border-radius: 0px;
    border: 0px solid #ffffff;
  }

  .MuiPickersDay-daySelected {
    background-color: #3847a7;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-default {
    cursor: default;
  }

  .keyboard-icon {
    width: 25px;
    height: 25px;
  }

  .keyboard-icon-bg {
    width: 20px;
    height: 20px;
  }
}

/* Firefox styles go here */
@-moz-document url-prefix() {
  body {
    scrollbar-width: thin;
    scrollbar-color: #4455c7 #e7e9ec;
  }
}

.arrow-up {
  position: fixed;
  z-index: 99;
  bottom: 35px;
  right: 10%;
  background-color: $color-Primary;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 3px solid $color-Accent-1;
  -webkit-tap-highlight-color: transparent;

  svg {
    path {
      fill: $color-White;
    }
  }
}

.calendar-row {
  .fc-day {
    padding: 0px 5px;

    @media (max-width: 799px) {
      padding: 0px;
    }
  }

  .fc-daygrid-day-bottom {
    @media (max-width: 799px) {
      display: none;
    }
  }
}

.no-scroll {
  @media (max-width: 799px) {
    overflow: hidden !important;
  }
}

.content-wrap {
  // padding-top: 113px;
  padding-top: 0px;
}

.sport-wrap {
  padding: 0px 18px;

  h3 {
    font-size: 43.9px;
    font-family: $primaryFont;
    line-height: 56px;
    // margin: 33px 0px 3px 0px;
    margin: 0px 0px 3px 0px;
    font-weight: normal;
    text-align: left;
  }

  // h3:not(:first-child) {
  //   padding-top: 42px;
  // }

  .sport-grid {
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // column-gap: 18px;
    // row-gap: 27px;
    grid-template-columns: auto;
    grid-row-gap: 18px;
    row-gap: 18px;
  }

  .home-page-sport-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 18px;
    grid-row-gap: 27px;
    row-gap: 27px;
  }

  .sport-grid:not(:last-child) {
    margin-bottom: 27px;
  }

  .sport-shadow {
    box-shadow: 10px 10px 5px 0px #0000000f;
    -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
    -moz-box-shadow: 10px 10px 5px 0px #0000000f;
    border: 1px solid #e8eaec;
    background: #fff;
    border-radius: 8px;
    padding: 13px 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 50px);
  }

  .sport-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;

    span {
      font-size: 11.42px;
      line-height: 14px;
      color: #191919;
      font-weight: 600;
    }

    .sport-title-name {
      font-size: 16px;
      line-height: 20px;
    }

    a {
      color: #4455c7;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}

.text-danger {
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  font-family: $regulerFont;
}

.container {
  margin: 0 auto;
  position: relative;
  // V3 header
  // max-width: 1350px;
  max-width: 1920px;
}

.side-space {
  padding: 0px 18px;
}

@media only screen and (max-width: 1200px) {
  .sport-wrap {
    .sport-grid {
      grid-template-columns: auto;
      row-gap: 40px;

      .sport-shadow {
        min-height: auto;
        height: auto;
      }
    }
  }
}

// @media only screen and (max-width: 991px) {
//   .content-wrap {
//     padding-top: 104px;
//   }
// }

// @media only screen and (max-width: 800px) {
//   .content-wrap {
//     padding-top: 0px;
//   }
// }
@media only screen and (max-width: 959px) {
  body {
    .hide-sm {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 799px) {
  .sport-wrap {
    h3:not(:first-child) {
      padding-top: 0px;
    }

    .sport-title {
      margin-bottom: 9px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sport-wrap {
    h3 {
      font-size: 35px;
    }
  }
}

// @media only screen and (max-width: 599px) {
//   .content-wrap {
//     padding-top: 108px;
//   }
// }

@media only screen and (max-width: 479px) {
  .side-space {
    padding: 0px 12px;
  }

  .sport-wrap {
    padding: 0px 12px;

    h3 {
      font-size: 31.36px;
      line-height: 43.9px;
      margin: 27px 0px 6px 0px;
    }

    .sport-grid {
      row-gap: 27px;
    }
  }
}

.banner-img-fix {
  // max-width: 894px;
  margin: 0 auto;
}

.advt-wrap {
  width: 100%;
  display: block;
  text-align: center;

  div {
    max-width: 100% !important;

    img {
      max-width: 100% !important;
    }
  }

  iframe {
    max-width: 100% !important;

    body>div {
      margin: 0px auto !important;
    }
  }

  svg {
    max-width: 100% !important;
    height: auto !important;

    img {
      max-width: 100% !important;
      height: auto !important;
    }
  }

  path {
    max-width: 100% !important;
    height: auto !important;
  }

  img {
    width: 100% !important;
    height: auto !important;
  }
}

.MuiTooltip-tooltipPlacementTop {
  margin: 0px !important;
}